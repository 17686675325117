import { IonActionSheet, IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRouterOutlet, IonSelect, IonSelectOption, IonTabBar, IonTabButton, IonTabs, IonText, IonTitle, IonToast, IonToolbar, isPlatform, useIonAlert } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, RouteComponentProps, useHistory } from 'react-router-dom';
import './Home.css';
import ContaAlt from './pages/ContaAlt/ContaAlt';
import TermosPolitica from './pages/TermosPolitica/TermosPolitica';

import { apps, appsOutline, arrowBack, calendar, calendarOutline, clipboardOutline, close, desktopOutline, flag, flagOutline, homeOutline, logoUsd, trendingUp, trendingUpOutline } from 'ionicons/icons';
import "./Home.css";
import Acompanhar from './pages/Acompanhar/Acompanhar';
import Operacoes from './pages/Operacoes/Operacoes';
import ProjetosDetalhes from './pages/ProjetosDetalhes/ProjetosDetalhes';
import ProjetosWorkflow from './pages/ProjetosWorkflow/ProjetosWorkflow';

import AgendamentosDetalhes from './pages/AgendamentosDetalhes/AgendamentosDetalhes';
import AgendamentosEventos from './pages/AgendamentosEventos/AgendamentosEventos';
import Equipe from './pages/Equipe/Equipe';


import { MessageContext } from '../../../contexts/MessageContext';
import Chat from './pages/Chat/Chat';
import Clientes from './pages/Clientes/Clientes';
import Convidados from './pages/Convidados/Convidados';
import Prestadores from './pages/Prestadores/Prestadores';
import Projetos from './pages/Projetos/Projetos';

import Prospects from './pages/Prospects/Prospects';

import { Toast } from '@capacitor/toast';
import { Buffer } from 'buffer';
import { apiIfoodDisputeAccept, apiIfoodDisputeReject, apiIfoodEvents, apiMercadoPagoCancelamentoPagamento, apiMercadoPagoConfirmacaoPagamento, consultaPagamentoMensalidade, empresaCarregar, requestService } from '../../../Utils/Services';
import AgendamentosEventosComMenu from './pages/AgendamentosEventosComMenu/AgendamentosEventosComMenu';
import Caixa from './pages/Caixa/Caixa';
import CaixaHistorico from './pages/CaixaHistorico/CaixaHistorico';
import CaixaProdutosMemory from './pages/CaixaProdutosMemory/CaixaProdutosMemory';
import Comandas from './pages/Comandas/Comandas';
import ComandasHistorico from './pages/ComandasHistorico/ComandasHistorico';
import ComandasProdutosMemory from './pages/ComandasProdutosMemory/ComandasProdutosMemory';
import EmpresaWorkflowCadastro from './pages/EmpresaWorkflowCadastro/EmpresaWorkflowCadastro';
import EmpresaWorkflowVendaCadastro from './pages/EmpresaWorkflowVendaCadastro/EmpresaWorkflowVendaCadastro';
import Financeiro from './pages/Financeiro/Financeiro';
import FinanceiroCategorias from './pages/FinanceiroCategorias/FinanceiroCategorias';
import Formulario from './pages/Formulario/Formulario';
import FormularioCadastro from './pages/FormularioCadastro/FormularioCadastro';
import FormularioCategorias from './pages/FormularioCategorias/FormularioCategorias';
import Inicio from './pages/Inicio/Inicio';
// import Lembretes from './pages/Lembretes/Lembretes';
import { BleClient } from '@capacitor-community/bluetooth-le';
import useDeepLinkHandler from '../../../components/useDeepLinkHandler';
import CaixaGeral from './pages/CaixaGeral/CaixaGeral';
import Desempenho from './pages/Desempenho/Desempenho';
import FormaPagamento from './pages/FormaPagamento/FormaPagamento';
import Integracoes from './pages/Integracoes/Integracoes';
import Mensagem from './pages/Mensagem/Mensagem';
import MenuCadastros from './pages/MenuCadastros/MenuCadastros';
import MenuConfiguracoes from './pages/MenuConfiguracoes/MenuConfiguracoes';
import MenuRelatorios from './pages/MenuRelatorios/MenuRelatorios';
import Notificacoes from './pages/Notificacoes/Notificacoes';
import OperacoesAlt from './pages/OperacoesAlt/OperacoesAlt';
import Painel from './pages/Painel/Painel';
import ProdutosCatalogo from './pages/ProdutosCatalogo/ProdutosCatalogo';
import ProdutosCategorias from './pages/ProdutosCategorias/ProdutosCategorias';
import ProdutosCategoriasNovo from './pages/ProdutosCategoriasNovo/ProdutosCategoriasNovo';
import ProjetosCupomHtml from './pages/ProjetosCupomHtml/ProjetosCupomHtml';
import ProjetosHistorico from './pages/ProjetosHistorico/ProjetosHistorico';
import ProjetosNovoPedidoVenda from './pages/ProjetosNovoPedidoVenda/ProjetosNovoPedidoVenda';
import ProjetosPainel from './pages/ProjetosPainel/ProjetosPainel';
import ProjetosProdutosMemory from './pages/ProjetosProdutosMemory/ProjetosProdutosMemory';
import ProjetosServicos from './pages/ProjetosServicos/ProjetosServicos';
import RelatorioAgendamentos from './pages/RelatorioAgendamentos/RelatorioAgendamentos';
import RelatorioCaixa from './pages/RelatorioCaixa/RelatorioCaixa';
import RelatorioComandas from './pages/RelatorioComandas/RelatorioComandas';
import RelatorioFinanceiro from './pages/RelatorioFinanceiro/RelatorioFinanceiro';
import RelatorioOperacoes from './pages/RelatorioOperacoes/RelatorioOperacoes';
import RelatorioProjetos from './pages/RelatorioProjetos/RelatorioProjetos';
import RelatorioUsuarios from './pages/RelatorioUsuarios/RelatorioUsuarios';
import RelatorioVendas from './pages/RelatorioVendas/RelatorioVendas';
import ServicosCatalogo from './pages/ServicosCatalogo/ServicosCatalogo';
import ServicosCategorias from './pages/ServicosCategorias/ServicosCategorias';
import UsuariosCaixa from './pages/UsuariosCaixa/UsuariosCaixa';
import UsuariosComandas from './pages/UsuariosComandas/UsuariosComandas';
import Vendas from './pages/Vendas/Vendas';
import VendasCad from './pages/VendasCad/VendasCad';
import VendasDetalhes from './pages/VendasDetalhes/VendasDetalhes';
import VendasPainel from './pages/VendasPainel/VendasPainel';
import VendasProdutos from './pages/VendasProdutos/VendasProdutos';
import VendasServicos from './pages/VendasServicos/VendasServicos';

const Home: React.FC<RouteComponentProps> = ({ match }) => {
  const history = useHistory();
  // const { mensagens, setMensagens } = useContext(MessageContext);
  const { loja, setLoja } = useContext(MessageContext);
  const { pkUsuario, setPkUsuario } = useContext(MessageContext);

  const acessos = JSON.parse(window.localStorage.getItem("acessos") || '{}');
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  const [showActionSheet, setShowActionSheet] = useState(false);

  const { idPagamento, setIdPagamento } = useContext(MessageContext);
  const { isPaymentCompleted, setIsPaymentCompleted } = useContext(MessageContext);

  const { showPagamentoMaquininha, setShowPagamentoMaquininha } = useContext(MessageContext);
  const { idPagamentoMaquininha, setIdPagamentoMaquininha } = useContext(MessageContext);
  // const { paymentMaquininhaCancel, setPaymentMaquininhaCancel } = useContext(MessageContext);
  // const { qrCodePagamentoMaquininha, setQrCodePagamentoMaquininha } = useContext(MessageContext);
  // const [qrCodeDataMaquininha, setQrCodeDataMaquininha] = useState<string | null>(null);

  const [timeLeftPagamentoMaquininha, setTimeLeftPagamentoMaquininha] = useState(50); // 50 segundos
  // Converte o tempo restante em minutos e segundos
  const minutes = Math.floor(timeLeftPagamentoMaquininha / 60);
  const seconds = timeLeftPagamentoMaquininha % 60;

  const { recarregarProjeto, setRecarregarProjeto } = useContext(MessageContext);
  const [alertCancelamentoIfood, setAlertCancelamentoIfood] = useState(false);
  const [alertDisputaIfood, setAlertDisputaIfood] = useState(false);
  const [alertDisputaEncerradaIfood, setAlertDisputaEncerradaIfood] = useState(false);

  const [mensagemAlerta, setMensagemAlerta] = useState<any>();
  const [mensagemDisputaEncerradaAlerta, setMensagemDisputaEncerradaAlerta] = useState<any>();

  const [pedidosEmDisputa, setPedidosEmDisputa] = useState<any>([]);
  const [modalDisputaIfood, setModalDisputaIfood] = useState(false);
  const [pedidoSelecionado, setPedidoSelecionado] = useState<any>();

  const [mostartAlerta] = useIonAlert();

  const [showModalMotivo, setShowModalMotivo] = useState(false);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);

  // const [integradoIfood, setIntegradoIfood] = useState(window.localStorage.getItem("integradoIfood") === "true");

  const [timeLeft, setTimeLeft] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [showJson, setShowJson] = useState(false);

  const menuController = React.useRef<any>(null);

  const openMenu = () => {
    menuController.current?.open('financeiroMenu');
  };
  const { idPaymentMaquininhaSuccess, setIdPaymentMaquininhaSuccess } = useContext(MessageContext);


  const listaMotivosCancelamento: { value: string; label: string }[] = [
    { value: "HIGH_STORE_DEMAND", label: "Alta demanda na loja" },
    { value: "STORE_SYSTEM_ISSUES", label: "Problemas de sistema na loja" },
    { value: "LACK_OF_DRIVERS", label: "Falta de entregadores" },
    { value: "OPERATIONAL_ISSUES", label: "Problemas operacionais" },
    { value: "ORDER_OUT_FOR_DELIVERY", label: "Pedido saiu para entrega" },
    { value: "DRIVER_IS_ALREADY_AT_THE_ADDRESS", label: "Entregador já está no endereço" },
    { value: "OTHER_REASONS", label: "Outros motivos" },
  ];

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  useDeepLinkHandler();

  useEffect(
    () => {

      // verifica se tem acesso somente a uma determinada loja que não seja a principal.
      let lojasUsuario = JSON.parse(window.localStorage.getItem("lojas") || "[]");

      let lojaSelecionada = window.localStorage.getItem("empresa");

      if (lojasUsuario.length == 1) {
        lojaSelecionada = lojasUsuario[0].empresaLojaId;
      }
      else if (lojasUsuario.length > 1) {
        lojaSelecionada = window.localStorage.getItem("loja") || lojasUsuario[0].empresaLojaId || "";
      }
      else {
        lojaSelecionada = window.localStorage.getItem("loja") || window.localStorage.getItem("empresa") || "";
      }

      console.log("lojaSelecionada", lojaSelecionada);
      setLoja(lojaSelecionada);
      carregarEmpresa(lojaSelecionada);
    },
    []
  );



  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (showPagamentoMaquininha) {
      // Reseta o contador para 120 segundos sempre que o modal é aberto
      setTimeLeftPagamentoMaquininha(120); // 120 segundos

      // Inicia o intervalo de 1 segundo para o countdown
      timer = setInterval(() => {
        setTimeLeftPagamentoMaquininha(prevTime => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer); // Limpa o intervalo quando o tempo chega a 0
            // Quando o tempo acaba, redireciona o usuário
            handlePagamentoNaoRealizado();
            return 0;
          }
        });
      }, 1000);
    }

    // Limpa o timer quando o modal fecha ou o componente é desmontado
    return () => clearInterval(timer);

  }, [showPagamentoMaquininha]);

  // Função que será chamada quando o QR Code expira
  const handlePagamentoNaoRealizado = () => {
    setTextToast("Pagamento não foi realizado.");
    setShowToast(true);

    cancelarPagamentoMercadoPago();
  };


  useEffect(() => {
    let intervalId: any;
    let timeoutId: any;

    // Função para verificar o status do pagamento
    const verificarPagamento = async () => {
      try {
        console.log("entrou verificaPagamento", idPagamento);

        requestService(consultaPagamentoMensalidade.url.replace("{id}", idPagamento), {
          method: consultaPagamentoMensalidade.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
          history,
          (response: any) => {
            console.log("response.message ", response.message);

            if (response.message === "pagou") {
              setIsPaymentCompleted(true);  // Define o pagamento como completo
              clearInterval(intervalId);    // Para o polling quando o pagamento for efetuado
              clearTimeout(timeoutId);      // Cancela o timeout de 5 minutos
              carregarEmpresa(loja);        // Chama o endpoint carregarEmpresa

              Toast.show({
                text: "Pagamento realizado com sucesso.",
                position: "center",
                duration: "long"
              });
            }
          },
          (error: any) => {
            console.log(error);
          });

      } catch (error) {
        console.error('Erro ao verificar pagamento:', error);
      }
    };

    console.log("polling");

    if (idPagamento && !isPaymentCompleted) {
      console.log("polling iniciado");
      // Inicia o polling a cada 10 segundos (10000ms)
      intervalId = setInterval(verificarPagamento, 10000);

      // Define o timeout para parar o polling após 6 minutos (360000ms)
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        console.log("Polling parou após 6 minutos");
      }, 360000); // 360000ms = 6 minutos
    }

    // Limpa o intervalo e o timeout quando o componente é desmontado ou quando o pagamento é completo
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };

  }, [idPagamento, isPaymentCompleted]);




  useEffect(() => {
    let intervalId: any;
    let timeoutId: any;

    // Função para verificar o status do pagamento
    const verificarPagamento = async () => {
      try {
        console.log("entrou verificaPagamento maquininha", idPagamentoMaquininha);

        requestService(apiMercadoPagoConfirmacaoPagamento.url.replace("{id}", idPagamentoMaquininha), {
          method: apiMercadoPagoConfirmacaoPagamento.method,
          headers: [
            ["token", window.localStorage.getItem("token")]
          ],
          credentials: 'include'
        },
          history,
          (response: any) => {
            console.log("response.message ", response.message);
            console.log("response.orderId ", response.orderId);
            if (response.message === "pagou") {
              // todo: nao pode atualizar pelo front, tem q fazer pelo back no callback
              // setQrCodePagamentoMaquininha("");
              setIdPaymentMaquininhaSuccess(response.orderId); // atualiza tela colocando o pagamento como pago esse external_referente é em relação ao id criado dentro dos pagamentos em tela
              setIdPagamentoMaquininha("");
              setShowPagamentoMaquininha(false);
              clearInterval(intervalId);    // Para o polling quando o pagamento for efetuado
              clearTimeout(timeoutId);      // Cancela o timeout de 5 minutos

              Toast.show({
                text: "Pagamento realizado com sucesso.",
                position: "center",
                duration: "long"
              });
            }
          },
          (error: any) => {
            console.log(error);
          });

      } catch (error) {
        console.error('Erro ao verificar pagamento:', error);
      }
    };

    console.log("polling");

    if (idPagamentoMaquininha && !idPaymentMaquininhaSuccess) {
      console.log("polling iniciado");
      // Inicia o polling a cada 5 segundos (5000ms)
      intervalId = setInterval(verificarPagamento, 5000);

      // Define o timeout para parar o polling após 1 minuto (60000ms)
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        console.log("Polling parou após 2 minutos");
      }, 120000); // 60000ms = 60 segundos // 10 segundos a mais como contingencia, porem como o popup nao esta mais aberto ele nao faz mais nenhum polling
    }

    // Limpa o intervalo e o timeout quando o componente é desmontado ou quando o pagamento é completo
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };

  }, [idPagamentoMaquininha, idPaymentMaquininhaSuccess]);

  useEffect(() => {
    document.getElementsByTagName("ion-tab-bar")[0].style.display = esconderMenu ? "none" : "flex";
  }, [esconderMenu]);

  useEffect(() => {
    let pk = "usuarios#" + (window.localStorage.getItem("cpf") || "");
    setPkUsuario(pk);
  }, []);

  useEffect(() => {
    const tryAutoConnect = async (storedDeviceId: any) => {

      if (!storedDeviceId) {
        console.log("Nenhum dispositivo salvo para conexão automática.");
        return;
      }

      try {
        // Inicialize o cliente BLE
        await BleClient.initialize();
        console.log("Cliente Bluetooth inicializado.");

        let deviceFound = false;

        // Realize uma varredura para encontrar o dispositivo
        await BleClient.requestLEScan({}, (result) => {
          if (result.device.deviceId === storedDeviceId) {
            console.log("Dispositivo encontrado durante a varredura:", result.device);
            deviceFound = true;
          }
        });

        // Aguarde alguns segundos para garantir a varredura
        await new Promise((resolve) => setTimeout(resolve, 5000));

        // Pare a varredura
        await BleClient.stopLEScan();
        console.log("Varredura encerrada.");

        if (!deviceFound) {
          throw new Error("Dispositivo salvo não encontrado durante a varredura.");
        }

        // Tente conectar ao dispositivo encontrado
        await BleClient.connect(storedDeviceId);
        console.log("Conectado automaticamente ao dispositivo:", storedDeviceId);
      } catch (error) {
        console.error("Erro ao tentar conexão automática:", error);
        window.localStorage.removeItem("impressoraBluetoothDeviceId"); // Limpe o armazenamento caso falhe
      }
    };

    // Tentar conectar automaticamente quando o app iniciar
    if (impressoraBluetoothHabilitar() && (!isPlatform('mobileweb') && isPlatform('mobile'))) {
      const storedDeviceId = window.localStorage.getItem("impressoraBluetoothDeviceId");
      tryAutoConnect(storedDeviceId);
    }
  }, []);


  function carregarEmpresa(loja: any) {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replace(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setDadosEmpresa(response.Item);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }


  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     setIntegradoIfood(window.localStorage.getItem("integradoIfood") === "true");
  //   };

  //   window.addEventListener("storage", handleStorageChange);
  //   return () => {
  //     window.removeEventListener("storage", handleStorageChange);
  //   };
  // }, []);

  useEffect(() => {
    let intervalId: any;

    // Função para verificar o status do pagamento
    const eventosIfood = async () => {

      if (window.localStorage.getItem("integradoIfood") === "true") {
        console.log("**** ifood Ativado");

        try {
          console.log("getEvents loja", loja);
          const id = loja.replace(/#/g, '%23'); // Escapa o ID da loja

          requestService(
            apiIfoodEvents.url.replace("{id}", id),
            {
              method: apiIfoodEvents.method,
              headers: [["token", window.localStorage.getItem("token")]],
              credentials: 'include',
            },
            history,
            (response: any) => {
              console.log(response);

              if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                setRecarregarProjeto(true);

                const itensPedidosNovos = response.data.filter((item: any) => item.code === "PLC");

                if (itensPedidosNovos.length > 0) {
                  itensPedidosNovos.map((item: any) => {
                    console.log("********entrou imprimir automatico");
                    imprimir(item.pedido);
                  })
                }

                const itensCancelados = response.data
                  .filter((item: any) => item.code === "CAN")
                  .map((item: any) => item.displayId);

                if (itensCancelados.length > 0) {
                  setMensagemAlerta(
                    `Os seguintes pedidos foram cancelados: <strong>${itensCancelados.join(", ")}</strong>. Verifique a plataforma do iFood para mais obter mais detalhes.`
                  );
                  setAlertCancelamentoIfood(true);
                }

                const itensEmDisputa = response.data.filter((item: any) => item.code === "HSD");

                if (itensEmDisputa.length > 0) {
                  setPedidosEmDisputa((prevItens: any[]) => {
                    // Filtra os itens que ainda não estão presentes para evitar duplicados
                    const novosItens = itensEmDisputa.filter(
                      (novoItem: any) => !prevItens.some((itemExistente) => itemExistente.displayId === novoItem.displayId)
                    );

                    // Retorna a lista atualizada com os novos itens adicionados
                    return [...prevItens, ...novosItens];
                  });

                  setModalDisputaIfood(true);
                }

                const itensDisputaEncerrada = response.data
                  .filter((item: any) => item.code === "HSS");

                if (itensDisputaEncerrada.length > 0) {
                  const mensagens = itensDisputaEncerrada.map((disputa: any) => {
                    const status = disputa.element?.metadata?.status;
                    const displayId = disputa.displayId || "Não disponível";

                    switch (status) {
                      case "ACCEPTED":
                        return `Disputa do pedido #${displayId} foi aceita.`;
                      case "REJECTED":
                        return `Disputa do pedido #${displayId} foi rejeitada.`;
                      case "EXPIRED":
                        return `Disputa do pedido #${displayId} expirou devido ao tempo limite para resposta.`;
                      case "ALTERNATIVE_REPLIED":
                        return `Disputa do pedido #${displayId} foi respondida com uma contraproposta.`;
                      default:
                        return `Disputa do pedido #${displayId} foi encerrada. Verifique os detalhes na plataforma iFood.`;
                    }
                  });

                  // Atualiza o estado com a mensagem formatada
                  setMensagemDisputaEncerradaAlerta(
                    `Disputas encerradas:<br /><strong>${mensagens.join("<br />")}</strong>`
                  );
                  setAlertDisputaEncerradaIfood(true);
                }


                Toast.show({
                  text: "Chegou atualização de pedido do iFood.",
                  position: "center",
                  duration: "long"
                });

              }
            },
            (error: any) => {
              console.log(error);
              if (error.message) {
                Toast.show({
                  text: error.message,
                  position: "center",
                  duration: "long"
                });
              }
            }
          );
        } catch (error) {
          console.error("Erro ao verificar pedidos ifood:", error);
        }
      }
      else {
        console.log("**** ifood Destivado");
      }
    };

    // if (window.localStorage.getItem("integradoIfood") === "true") {
    console.log("Polling ifood iniciado");
    // Inicia o polling a cada 30 segundos (30000ms)
    intervalId = setInterval(eventosIfood, 30000);
    // } else {
    //   console.log("Sem Polling, sem ifood");
    // }

    // Limpa o intervalo quando o componente é desmontado
    return () => {
      clearInterval(intervalId);
    };
  }, [loja, dadosEmpresa]);

  useEffect(() => {
    if (!pedidoSelecionado?.element?.metadata?.expiresAt) return;

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const expiresAt = new Date(pedidoSelecionado.element.metadata.expiresAt).getTime();
      const diff = expiresAt - now;

      setIsExpired(false);

      if (diff <= 0) {
        setIsExpired(true);
        setTimeLeft("Tempo expirado");
        clearInterval(interval);
        return;
      }

      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      setTimeLeft(`${minutes}m ${seconds}s`);
    }, 1000);

    return () => clearInterval(interval);
  }, [pedidoSelecionado]);

  function carregarConfigWorkflowEmpresa(loja?: any) {
    console.log("entrou configs")
    const consultar = async () => {

      let id = loja;

      requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          if (response.Item.configWorkflow) {
            window.localStorage.setItem("configWorkflow", JSON.stringify(response.Item.configWorkflow));
          } else {
            window.localStorage.removeItem("configWorkflow");
          }
          if (response.Item.servicoPrincipal) { window.localStorage.setItem("servicoPrincipal", response.Item.servicoPrincipal); } else { window.localStorage.removeItem("servicoPrincipal"); }
          if (response.Item.identificacaoPorNumero) { window.localStorage.setItem("identificacaoPorNumero", response.Item.identificacaoPorNumero); } else { window.localStorage.removeItem("identificacaoPorNumero"); }
          if (response.Item.servicoTipo) { window.localStorage.setItem("servicoTipo", response.Item.servicoTipo); } else { window.localStorage.removeItem("servicoTipo"); }
          if (response.Item.servicoTipoPlural) { window.localStorage.setItem("servicoTipoPlural", response.Item.servicoTipoPlural); } else { window.localStorage.removeItem("servicoTipoPlural"); }
          if (response.Item.nomeLinhaDoTempo) { window.localStorage.setItem("nomeLinhaDoTempo", response.Item.nomeLinhaDoTempo); } else { window.localStorage.removeItem("nomeLinhaDoTempo"); }
          if (response.Item.polos) { window.localStorage.setItem("polos", response.Item.polos); } else { window.localStorage.removeItem("polos"); }
          if (response.Item.naoAgrupar) { window.localStorage.setItem("naoAgrupar", response.Item.naoAgrupar); } else { window.localStorage.removeItem("naoAgrupar"); }
          if (response.Item.naoOperacao) { window.localStorage.setItem("naoOperacao", response.Item.naoOperacao); } else { window.localStorage.removeItem("naoOperacao"); }
          if (response.Item.esconderValoresProjeto) { window.localStorage.setItem("esconderValoresProjeto", response.Item.esconderValoresProjeto); } else { window.localStorage.removeItem("esconderValoresProjeto"); }

          if (response.Item.configWorkflowVenda) {
            window.localStorage.setItem("configWorkflowVenda", JSON.stringify(response.Item.configWorkflowVenda));
          } else {
            window.localStorage.removeItem("configWorkflowVenda");
          }

          if (response.Item.empresaMarca) { window.localStorage.setItem("empresaMarca", response.Item.empresaMarca); } else { window.localStorage.removeItem("empresaMarca"); }
          if (response.Item.parceria) { window.localStorage.setItem("parceria", response.Item.parceria); } else { window.localStorage.removeItem("parceria"); }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    }
    consultar();
  }

  function getButtons() {
    let resultado: any = [
      {
        text: 'Cancelar',
        icon: close,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      },

    ];





    if (acessos?.vendas == 'leitura' || acessos?.vendas == 'edicao') {

      if (nomeMenu != "Vendas") {
        resultado.push(
          {
            text: "Vendas",
            icon: trendingUp,
            handler: () => {
              history.push("./vendas");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Vendas",
            icon: trendingUp,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }


    if (acessos?.projetos == 'leitura' || acessos?.projetos == 'edicao') {

      if (nomeMenu != (window.localStorage.getItem("servicoTipoPlural") || "Projetos")) {
        resultado.push(
          {
            text: window.localStorage.getItem("servicoTipoPlural") || "Projetos",
            icon: apps,
            handler: () => {
              history.push("./projetos");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: window.localStorage.getItem("servicoTipoPlural") || "Projetos",
            icon: apps,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }

    }


    if (acessos?.agenda == 'leitura' || acessos?.agenda == 'edicao') {

      if (nomeMenu != "Agenda") {

        resultado.push(
          {
            text: "Agenda",
            icon: calendar,
            handler: () => {
              history.push("./agendamentoseventoscommenu");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Agenda",
            icon: calendar,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }

    if (acessos?.operacoes == 'leitura' || acessos?.operacoes == 'edicao') {

      if (nomeMenu != "Operações") {
        resultado.push(
          {
            text: "Operações",
            icon: flag,
            handler: () => {
              history.push("./operacoes");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Operações",
            icon: flag,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }

    if (acessos?.financeiro == 'leitura' || acessos?.financeiro == 'edicao') {

      if (nomeMenu != "Financeiro") {
        resultado.push(
          {
            text: "Financeiro",
            icon: logoUsd,
            handler: () => {
              history.push("./financeiro");
            }
          }
        )
      }
      else {
        resultado.push(
          {
            text: "Financeiro",
            icon: logoUsd,
            cssClass: 'disable-action-sheet-btns'
          }
        )
      }
    }




    return resultado;
  }


  function disputeAccept(disputeId: any, reason: any) {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      console.log("disputeId", disputeId);

      let params = {
        reason: reason
      };
      console.log("params", params);

      requestService(apiIfoodDisputeAccept.url.replace("{id}", id.replace(/#/g, "%23")).replace("{disputeId}", disputeId.replace(/#/g, "%23")), {
        method: apiIfoodDisputeAccept.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response", response);
          if (response.statusCode == 200 || response.statusCode == 201) {

            // Atualizar a lista removendo o item com o displayId correspondente
            setPedidosEmDisputa((prevList: any) =>
              prevList.filter((pedido: any) => pedido.displayId !== pedidoSelecionado.displayId)
            );

            Toast.show({
              text: "iFood: Disputa Enviada.",
              position: "center",
              duration: "long"
            });

            setAlertDisputaIfood(false);
          }
          else {

            Toast.show({
              text: "iFood: Ocorreu um erro ao enviar a disputa. Utilize o iFood como contingência.",
              position: "center",
              duration: "long"
            });
          }

        },
        (error: any) => {
          console.log(error);
          Toast.show({
            text: "iFood: Ocorreu um erro ao enviar a disputa.",
            position: "center",
            duration: "long"
          });
        });

    }

    consultar();
  }

  function disputeReject(disputeId: any, reason: any) {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      console.log("disputeId", disputeId);

      let params = {
        reason: reason
      };
      console.log("params", params);

      requestService(apiIfoodDisputeReject.url.replace("{id}", id.replace(/#/g, "%23")).replace("{disputeId}", disputeId.replace(/#/g, "%23")), {
        method: apiIfoodDisputeReject.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response", response);
          if (response.statusCode == 200 || response.statusCode == 201) {

            // Atualizar a lista removendo o item com o displayId correspondente
            setPedidosEmDisputa((prevList: any) =>
              prevList.filter((pedido: any) => pedido.displayId !== pedidoSelecionado.displayId)
            );

            Toast.show({
              text: "iFood: Disputa Enviada.",
              position: "center",
              duration: "long"
            });

            setAlertDisputaIfood(false);
          }
          else {

            Toast.show({
              text: "iFood: Ocorreu um erro ao enviar a disputa. Utilize o iFood como contingência.",
              position: "center",
              duration: "long"
            });
          }

        },
        (error: any) => {
          console.log(error);
          Toast.show({
            text: "iFood: Ocorreu um erro ao enviar a disputa.",
            position: "center",
            duration: "long"
          });
        });

    }

    consultar();
  }

  // const traduzirMotivo = (reason: string): string => {
  //   const motivosTraduzidos: Record<string, string> = {
  //     HIGH_STORE_DEMAND: "Alta demanda na loja",
  //     STORE_SYSTEM_ISSUES: "Problemas no sistema da loja",
  //     STORE_INTERNAL_DIFFICULTIES: "Dificuldades internas na loja",
  //     LACK_OF_DRIVERS: "Falta de entregadores",
  //     OTHER_REASONS: "Outros motivos",
  //     OPERATIONAL_ISSUES: "Problemas operacionais",
  //     ORDER_OUT_FOR_DELIVERY: "Pedido já saiu para entrega",
  //   };
  //   return motivosTraduzidos[reason] || reason; // Retorna a tradução ou o texto original
  // };

  const handleSend = () => {
    if (selectedReason) {

      const refundAlternative = pedidoSelecionado?.element?.metadata;

      console.log("selectedReason", selectedReason);

      disputeAccept(refundAlternative.disputeId, selectedReason);
      setShowModalMotivo(false);
    } else {
      Toast.show({
        text: "Motivo é obrigatório",
        position: "center",
        duration: "long",
      });
    }
  };

  const impressoraBluetoothHabilitar = () => {
    const storedValue = window.localStorage.getItem("impressoraBluetoothHabilitar");
    return storedValue === "true";
  };

  const impressoraLocalHabilitar = () => {
    const storedValue = window.localStorage.getItem("impressoraLocalHabilitar");
    return storedValue === "true";
  };

  async function imprimir(dadosProjeto: any) {
    if (dadosProjeto.dataCupom) {

      // gerarEscPos(dadosProjeto);
      // return;



      console.log("dadosEmpresa.impressoraRedeHabilitar ", dadosEmpresa.impressoraRedeHabilitar);
      console.log("impressoraLocalHabilitar", impressoraLocalHabilitar());
      console.log("impressoraBluetoothHabilitar", impressoraBluetoothHabilitar());

      if (dadosEmpresa.impressaoAutomaticaIfood) {
        if (dadosEmpresa.impressoraRedeHabilitar && (!isPlatform('mobileweb') && isPlatform('mobile'))) {
          const ipImpressora = dadosEmpresa.impressoraRedeIp;
          const portaImpressora = dadosEmpresa.impressoraRedePorta;

          const conectarEEnviar = () => {
            // Cria o socket TCP
            window.chrome.sockets.tcp.create({}, (createInfo) => {
              const socketId = createInfo.socketId;

              // Define o timeout para mostrar a mensagem de erro após 3 segundos
              const timeoutId = setTimeout(() => {
                setTextToast("Erro ao conectar.");
                setShowToast(true);
                window.chrome.sockets.tcp.close(socketId);
              }, 3000); // 3 segundos

              // Tenta conectar ao IP e porta especificados
              window.chrome.sockets.tcp.connect(socketId, ipImpressora, portaImpressora, (result) => {
                if (result === 0) {
                  // Conexão bem-sucedida, cancela o timeout
                  clearTimeout(timeoutId);

                  console.log('Conexão bem-sucedida! Enviando dados...');

                  const comandosEscPos = gerarEscPos(dadosProjeto, (dadosEmpresa.impressoraLargura == "58" ? true : false));
                  if (comandosEscPos) {
                    window.chrome.sockets.tcp.send(socketId, comandosEscPos.buffer, (sendInfo) => {
                      console.log('Comando enviado com sucesso:', sendInfo);
                      window.chrome.sockets.tcp.close(socketId);
                    });
                  } else {
                    console.error('Erro ao gerar ESC/POS');
                  }
                } else {
                  // Conexão falhou, cancela o timeout e mostra mensagem de erro
                  clearTimeout(timeoutId);
                  console.error(`Erro ao conectar à impressora. Verifique a conexão.`);
                  setTextToast("Erro ao conectar.");
                  setShowToast(true);
                  window.chrome.sockets.tcp.close(socketId);
                }
              });
            });
          };


          // Chame a função com o URL do HTML que você deseja imprimir
          conectarEEnviar();
        }
        else if (impressoraLocalHabilitar() && !(!isPlatform('mobileweb') && isPlatform('mobile'))) {

          console.log("*********************impressao browser pela rede");

          const conectarEEnviar = () => {
            // Cria o socket TCP


            console.log('Conexão bem-sucedida! Enviando dados...');

            const comandosEscPos: any = gerarEscPos(dadosProjeto, (dadosEmpresa.impressoraLargura == "58" ? true : false));

            if (comandosEscPos) {
              // Exemplo de uso
              const resultadoString = converterParaString(comandosEscPos);
              // const resultadoString = arrayToEscapedString(comandosEscPos);
              //console.log(resultadoString);




              const printerName = window.localStorage.getItem("impressoraLocalName") || "";

              enviarImpressao(printerName, resultadoString);



            } else {
              console.error('Erro ao gerar ESC/POS');
            }

          };


          // Chame a função com o URL do HTML que você deseja imprimir
          conectarEEnviar();


        }
        else if (impressoraBluetoothHabilitar() && (!isPlatform('mobileweb') && isPlatform('mobile'))) {
          const comandosEscPos = gerarEscPos(dadosProjeto, (dadosEmpresa.impressoraLargura == "58" ? true : false));
          if (comandosEscPos) {
            const PRINT_SERVICE_UUID = "000018f0-0000-1000-8000-00805f9b34fb";
            const PRINT_CHARACTERISTIC_UUID = "00002af1-0000-1000-8000-00805f9b34fb";
            // Fragmentar os dados em pacotes menores
            const chunkSize = 20; // Tamanho máximo permitido por pacote BLE
            const buffer = comandosEscPos.buffer;

            const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

            try {
              // Loop para enviar os dados em pacotes
              for (let i = 0; i < buffer.byteLength; i += chunkSize) {
                const chunk = new DataView(buffer.slice(i, Math.min(i + chunkSize, buffer.byteLength)));
                await BleClient.write(
                  window.localStorage.getItem("impressoraBluetoothDeviceId") || "",
                  PRINT_SERVICE_UUID,
                  PRINT_CHARACTERISTIC_UUID,
                  chunk
                );
                await delay(50); // Atraso de 50ms entre os envios
              }

              setTextToast("Impressão realizada com sucesso!");
              setShowToast(true);

            } catch (error) {
              console.error("Erro durante a impressão:", error);
              setTextToast("Erro ao realizar a impressão.");
              setShowToast(true);
            }
          } else {
            console.error('Erro ao gerar ESC/POS');
          }
        }

        // else {
        //   gerarCupom(response.message.pk);
        // }
      }
    }
  }

  function gerarEscPos(projeto: any, imprimir58mm: any = false) {
    // Função para remover acentos e cedilhas
    function removerAcentosECedilha(texto: string): string {
      return texto
        .normalize('NFD')  // Normaliza o texto para decompô-lo em caracteres base e acentos
        .replace(/[\u0300-\u036f]/g, '')  // Remove os acentos
        .replace(/ç/g, 'c')  // Substitui 'ç' por 'c'
        .replace(/Ç/g, 'C');  // Substitui 'Ç' por 'C'
    }

    // Função para aplicar a máscara de CPF/CNPJ
    function aplicarMascaraCPFouCNPJ(texto: any) {
      if (!texto) return '';
      texto = texto.replace(/\D/g, '');
      if (texto.length === 11) {
        return texto.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      } else if (texto.length === 14) {
        return texto.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      }
      return texto;
    }

    // Função para formatar telefone
    function formataTelefone(numero: any) {
      if (numero?.length == 13) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{5})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else if (numero?.length == 12) {
        let BRNumber = numero.replace("55", "").match(/(\d{2})(\d{4})(\d{4})/);
        BRNumber = "(" + BRNumber[1] + ") " + BRNumber[2] + "-" + BRNumber[3];
        return BRNumber;
      }
      else { return "" };
    }

    function quebrarTextoEmLinhas(texto: any, tamanhoMaximo: any) {
      const palavras = texto.split(' ');
      let linhas = [];
      let linhaAtual = '';

      palavras.forEach((palavra: any) => {
        if ((linhaAtual + palavra).length <= tamanhoMaximo) {
          linhaAtual += palavra + ' ';
        } else {
          linhas.push(linhaAtual.trim());
          linhaAtual = palavra + ' ';
        }
      });

      if (linhaAtual.trim()) {
        linhas.push(linhaAtual.trim());
      }

      return linhas;
    }

    // Função para formatar valores monetários
    const formatarValor = (valor: any) => {
      if (!valor) return '0,00';

      // Remove pontos de milhares e substitui a vírgula decimal por ponto
      const valorNumerico = parseFloat(valor.toString().replace(/\./g, '').replace(',', '.'));

      // Formata o número para o padrão brasileiro
      return valorNumerico.toFixed(2).replace('.', ',');
    };

    // Função para gerar o cabeçalho e as informações do cupom
    function gerarCabecalho(projeto: any) {

      let cabecalhoText = "";

      // Informações da empresa
      cabecalhoText += `${projeto.empresa?.empresaNome}\n`;
      cabecalhoText += `CNPJ: ${aplicarMascaraCPFouCNPJ(projeto.empresa?.empresaCnpj)}\n`;

      // Endereço da empresa
      quebrarTextoEmLinhas(`Endereço: ${projeto.empresa?.empresaEndereco}`, 35).forEach((linha: any) => {
        cabecalhoText += `${linha}\n`;
      });

      cabecalhoText += `Tel: ${formataTelefone(projeto.empresa?.empresaTelefone)}\n`;

      // Data e hora do cupom, se disponível
      if (projeto.dataCupom) {
        cabecalhoText += `Data: ${new Date(projeto.dataCupom).toLocaleDateString('pt-BR')} Hora: ${new Date(projeto.dataCupom).toLocaleTimeString('pt-BR')}\n`;
      }

      cabecalhoText += imprimir58mm ? `********************************\n` : `**************************************\n`;

      // Informações do cliente
      cabecalhoText += `${projeto.cliente?.nome}\n`;

      // Se o projeto não for interno, exibe informações adicionais do cliente
      if (!projeto.interno) {
        cabecalhoText += `Tel: ${formataTelefone(projeto.cliente?.telefone)}\n`;

        // Endereço do cliente, se disponível
        quebrarTextoEmLinhas(`Endereço: ${[projeto.cliente?.endereco, projeto.cliente?.complemento].filter(Boolean).join(', ') || ""}`, 35).forEach((linha: any) => {
          cabecalhoText += `${linha}\n`;
        });
      }

      cabecalhoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
      cabecalhoText += "CUPOM NÃO FISCAL\n";

      // Se o projeto não for interno, exibe o nome do projeto
      if (!projeto.interno) {
        cabecalhoText += imprimir58mm ? `================================\n` : `======================================\n`;
        cabecalhoText += `${projeto.empresa?.servicoTipo || "Projeto"}: ${projeto.nomeProjeto}\n`;
        cabecalhoText += imprimir58mm ? `================================\n` : `======================================\n`;
      }

      return cabecalhoText;
    }

    // Função para gerar o corpo do cupom (produtos)
    function gerarProdutos(projeto: any) {
      let produtosText = "";

      produtosText += "QTDE | DESCRIÇÃO\n";
      produtosText += imprimir58mm ? `--------------------------------\n` : `--------------------------------------\n`;

      if (projeto.produtos && Array.isArray(projeto.produtos) && projeto.produtos.length > 0) {
        projeto.produtos.forEach((produto: any) => {
          // Verifica se o produto possui uma descrição complexa
          if (Array.isArray(produto.descricao)) {
            produto.descricao.forEach((itemDesc: any, indexLinha: number) => {
              // Exibe a linha principal do produto apenas uma vez
              if (indexLinha === 0) {
                produtosText += `${produto.quantidade} | ${produto.tipo === "pizzas" ? `${produto.tamanho} ${produto.quantidadeSabores} SABORES (R$ ${formatarValor(produto.valor)})` : `${produto.categoria} (R$ ${formatarValor(produto.valor)})`}\n`;
              }

              // Exibe as descrições adicionais, se houver
              if (itemDesc.descricao) {
                produtosText += `  - ${itemDesc.descricao} (R$ ${formatarValor(itemDesc.valor)})\n`;
              }
            });
          } else {
            // Para produtos com uma única descrição simples
            produtosText += `${produto.quantidade} | ${produto.descricao} (R$ ${formatarValor(produto.valor)})\n`;
          }

          // Adiciona o subtotal de cada produto
          produtosText += `VALOR: R$ ${formatarValor(produto.subtotal)}\n`;
          produtosText += imprimir58mm ? `--------------------------------\n` : `--------------------------------------\n`;
        });

        // Adiciona o total de todos os produtos
        if (projeto.valorTotalProdutos) {
          produtosText += `TOTAL: R$ ${formatarValor(projeto.valorTotalProdutos)}\n`;
        }
      }

      return produtosText;
    }

    // Função para gerar o footer com as informações de pagamento
    function gerarFooter(projeto: any) {
      let pagamentoText = "";

      if (projeto.pedidoIfood) {
        pagamentoText += imprimir58mm ? `*************iFood**************\n` : `****************iFood*****************\n`;

        if (projeto.taxas) {
          pagamentoText += `TAXAS: +R$ ${formatarValor(projeto.taxas)}\n`;
        }

        if (projeto.localizadorIfood) {
          pagamentoText += `LOCALIZADOR IFOOD: ${projeto.localizadorIfood}\n`;
        }

        if (projeto.tipoEntrega) {
          pagamentoText += `TIPO DE ENTREGA: ${projeto.tipoEntrega}\n`;
        }

        if (projeto.horarioEntrega) {
          pagamentoText += `HORÁRIO DE ENTREGA: ${projeto.horarioEntrega}\n`;
        }

        if (projeto.extraInfo) {
          pagamentoText += `INFORMAÇÕES EXTRAS: ${projeto.extraInfo}\n`;
        }
      }

      // Forma de pagamento
      if (projeto.formaPagamento) {
        if (!Array.isArray(projeto.formaPagamento)) {
          pagamentoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
          pagamentoText += `FORMA PAGAMENTO: ${projeto.formaPagamento?.toUpperCase()}\n`;
        }
      }

      // Troco, se houver
      if (projeto.troco && projeto.troco !== "0,00") {
        pagamentoText += `TROCO: R$ ${formatarValor(projeto.troco)}\n`;
      }

      // Taxa de entrega, se houver
      if (projeto.taxaEntrega) {
        pagamentoText += `TAXA DE ENTREGA: +R$ ${formatarValor(projeto.taxaEntrega)}\n`;
      }

      // Desconto, se houver
      if (projeto.desconto) {
        pagamentoText += `DESCONTO: -R$ ${formatarValor(projeto.desconto)}\n`;
      }

      // Valor total, se houver
      if (projeto.valorTotal) {
        pagamentoText += imprimir58mm ? `********************************\n` : `**************************************\n`;
        pagamentoText += `TOTAL ${projeto.empresa?.servicoTipo?.toUpperCase() || "PROJETO"}: R$ ${formatarValor(projeto.valorTotal)}\n`;
      }

      return pagamentoText;
    }

    function gerarQrCode(pk: string): string {
      let qrCodeText = "";

      // Comando de inicialização do QR Code
      qrCodeText += "\x1D\x28\x6B"; // Comando inicial
      qrCodeText += "\x04\x00\x31\x41\x32\x00"; // Configuração do modelo do QR (modelo 2)

      qrCodeText += "\x1D\x28\x6B"; // Tamanho dos módulos
      qrCodeText += "\x03\x00\x31\x43\x07"; // Define tamanho como 7

      // Comando para armazenar os dados
      qrCodeText += "\x1D\x28\x6B" +
        String.fromCharCode(pk.length + 3) + "\x00\x31\x50\x30" + pk;

      // Comando para imprimir o QR Code
      qrCodeText += "\x1D\x28\x6B\x03\x00\x31\x51\x30";

      return qrCodeText;
    }

    try {


      console.log("dadosImpressao1");
      // Montando o cupom com os dados no formato ESC/POS
      const cabecalho = gerarCabecalho(projeto);

      console.log("cabecalho", cabecalho);
      const produtos = gerarProdutos(projeto);
      console.log("produtos", produtos);
      const rodape = gerarFooter(projeto);
      console.log("rodape", rodape);
      const qrCodeGerado = gerarQrCode(projeto.pk);
      console.log("qrCodeGerado", qrCodeGerado);
      // Comandos ESC/POS básicos

      const ESC = 0x1B;  // ESC
      const LF = 0x0A;   // Line Feed
      const CORTA_PAPEL = [ESC, 0x69];   // Corta Papel
      const NEGRITO_ON = [ESC, 0x45, 0x01];  // Ativar negrito
      const NEGRITO_OFF = [ESC, 0x45, 0x00]; // Desativar negrito
      const ALIMENTAR_PAPEL = [ESC, 0x64, 0x10]; // Alimentação de papel


      const dadosImpressao: any = [
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        LF,
        ...NEGRITO_ON,
        ...removerAcentosECedilha(cabecalho).split("").map(c => c.charCodeAt(0)),
        ...NEGRITO_OFF, // Desativar negrito
        LF, // Pular linha
        ...removerAcentosECedilha(produtos).split("").map(c => c.charCodeAt(0)),
        LF, // Pular linha
        ...removerAcentosECedilha(rodape).split("").map(c => c.charCodeAt(0)),
        LF, // Pular linha
        ...qrCodeGerado.split("").map(c => c.charCodeAt(0)),
        LF, LF, LF, LF, LF,
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        ...CORTA_PAPEL

      ];

      // Retorna os dados como Uint8Array
      return new Uint8Array(dadosImpressao);
    } catch (error) {
      console.error('Erro ao imprimir cupom:', error);
      return null; // Retorna null em caso de erro
    }
  }


  function converterParaString(dadosImpressao: any) {
    return String.fromCharCode(...dadosImpressao.filter((byte: any) => byte <= 0xFF)); // Filtrando valores válidos
  }

  async function enviarImpressao(printer: any, data: any) {

    const url = "http://localhost:9100/print";

    // Montando o corpo da requisição no formato necessário
    var raw = JSON.stringify({
      "printer": printer,
      "data": data,  // Dados no formato correto (com códigos ESC/POS)
    });

    try {
      // Configurações da requisição
      const requestOptions: any = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: 'follow',
      };

      // Envia a requisição utilizando fetch
      const resp = await fetch(url, requestOptions);

      // Aguardar a resposta como texto
      const result = await resp.text();

      if (result) {
        console.log("Impressão enviada com sucesso:", result);
      } else {
        console.error("Erro: Nenhuma resposta recebida.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para a impressora:", error);
    }
  }

  function cancelarPagamentoMercadoPago() {

    const consultar = async () => {
      let id = loja;
      console.log("loja", loja);
      ;
      requestService(apiMercadoPagoCancelamentoPagamento.url.replace("{id}", id.replace(/#/g, '%23')).replace("{device_id}", (window.localStorage.getItem("deviceIdMercadoPago") || "")).replace("{paymentIntent}", idPagamentoMaquininha), {
        method: apiMercadoPagoCancelamentoPagamento.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);
          // setQrCodePagamentoMaquininha("");
          setIdPagamentoMaquininha("");
          setShowPagamentoMaquininha(false);
          // setPaymentMaquininhaCancel(true);

          setTextToast("Cancelamento efetuado com sucesso.");
          setShowToast(true);

        },
        (error: any) => {
          console.log(error);
          if (error.status == 404) {
            setTextToast("Pagamento já foi cancelado.");
            setShowToast(true);

            // setQrCodePagamentoMaquininha("");
            setIdPagamentoMaquininha("");
            setShowPagamentoMaquininha(false);
            // setPaymentMaquininhaCancel(true);
          }
          else if (error.status == 409) {
            setTextToast(error.data.message);
            setShowToast(true);
          }
          else {
            setTextToast("Ocorreu um erro ao cancelar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        });

    }

    consultar();
  }

  // useEffect(() => {
  //   const generateQRCode = async (url: string) => {
  //     try {
  //       // Gera o QR Code como uma URL base64
  //       const qrData = await QRCode.toDataURL(url);
  //       setQrCodeDataMaquininha(qrData);
  //     } catch (err) {
  //       console.error('Erro ao gerar o QR Code', err);
  //     }
  //   };

  //   if (qrCodePagamentoMaquininha) {
  //     generateQRCode(qrCodePagamentoMaquininha);
  //   }
  // }, [qrCodePagamentoMaquininha]);

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route path={`${match.url}/inicio`} component={Inicio} exact />
          <Route path={`${match.url}/painel`} component={Painel} exact />
          <Route path={`${match.url}/desempenho`} component={Desempenho} exact />

          <Route path={`${match.url}/caixageral`} component={CaixaGeral} exact />
          <Route path={`${match.url}/financeiro`} component={Financeiro} exact />

          <Route path={`${match.url}/vendas`} component={Vendas} exact />
          <Route path={`${match.url}/vendas/vendasdetalhes`} component={VendasDetalhes} exact />
          <Route path={`${match.url}/vendas/vendaspainel`} component={VendasPainel} exact />
          <Route path={`${match.url}/vendas/vendascad`} component={VendasCad} exact />
          <Route path={`${match.url}/vendas/vendasservicos`} component={VendasServicos} exact />
          <Route path={`${match.url}/vendas/vendasprodutos`} component={VendasProdutos} exact />

          <Route path={`${match.url}/projetos`} component={Projetos} exact />
          <Route path={`${match.url}/projetoshistorico`} component={ProjetosHistorico} exact />
          <Route path={`${match.url}/chat`} component={Chat} exact />
          <Route path={`${match.url}/notificacoes`} component={Notificacoes} exact />
          <Route path={`${match.url}/projetos/mensagem`} component={Mensagem} exact />

          <Route path={`${match.url}/projetos/projetosdetalhes`} component={ProjetosDetalhes} exact />
          <Route path={`${match.url}/projetos/projetospainel`} component={ProjetosPainel} exact />
          <Route path={`${match.url}/projetos/projetosworkflow`} component={ProjetosWorkflow} exact />
          <Route path={`${match.url}/projetos/projetosservicos`} component={ProjetosServicos} exact />
          <Route path={`${match.url}/projetos/projetosprodutos`} component={ProjetosProdutosMemory} exact />
          <Route path={`${match.url}/projetos/projetosnovopedidovenda`} component={ProjetosNovoPedidoVenda} exact />

          <Route path={`${match.url}/projetos/projetoscupomhtml`} component={ProjetosCupomHtml} exact />

          {/* <Route path={`${match.url}/comandas/comandashistorico`} component={ComandasHistorico} exact /> */}

          <Route path={`${match.url}/caixa`} component={Caixa} exact />
          <Route path={`${match.url}/caixa/caixahistorico`} component={CaixaHistorico} exact />


          <Route path={`${match.url}/comandas`} component={Comandas} exact />
          <Route path={`${match.url}/comandas/comandashistorico`} component={ComandasHistorico} exact />

          {/* <Route path={`${match.url}/caixa`} component={Caixa} exact /> */}
          {/* <Route path={`${match.url}/caixa/caixafechar`} component={CaixaFechar} exact /> */}
          {/* <Route path={`${match.url}/caixa/caixafechar`} component={CaixaFechar} exact /> */}


          <Route path={`${match.url}/comandas/produtos`} component={ComandasProdutosMemory} exact />
          <Route path={`${match.url}/caixa/produtos`} component={CaixaProdutosMemory} exact />

          <Route path={`${match.url}/agendamentos/agendamentosdetalhes`} component={AgendamentosDetalhes} exact />
          <Route path={`${match.url}/agendamentoseventos`} component={AgendamentosEventos} exact />
          <Route path={`${match.url}/agendamentoseventoscommenu`} component={AgendamentosEventosComMenu} exact />



          <Route path={`${match.url}/operacoes`} component={Operacoes} exact />
          <Route path={`${match.url}/operacoes/acompanhar`} component={Acompanhar} exact />
          <Route path={`${match.url}/operacoesalt`} component={OperacoesAlt} exact />

          <Route path={`${match.url}/equipe`} component={Equipe} exact />

          <Route path={`${match.url}/clientes`} component={Clientes} exact />
          <Route path={`${match.url}/usuarioscomandas`} component={UsuariosComandas} exact />
          <Route path={`${match.url}/usuarioscaixa`} component={UsuariosCaixa} exact />

          {/* <Route path={`${match.url}/arquitetos`} component={Arquitetos} exact /> */}

          <Route path={`${match.url}/prestadores`} component={Prestadores} exact />

          <Route path={`${match.url}/prospects`} component={Prospects} exact />


          <Route path={`${match.url}/convidados`} component={Convidados} exact />

          <Route path={`${match.url}/relatoriocaixa`} component={RelatorioCaixa} exact />
          <Route path={`${match.url}/relatoriocomandas`} component={RelatorioComandas} exact />

          <Route path={`${match.url}/relatorioprojetos`} component={RelatorioProjetos} exact />
          <Route path={`${match.url}/relatoriovendas`} component={RelatorioVendas} exact />
          {/* <Route path={`${match.url}/relatorioprojetosarquiteto`} component={RelatorioProjetosArquiteto} exact /> */}
          <Route path={`${match.url}/relatorioagendamentos`} component={RelatorioAgendamentos} exact />
          <Route path={`${match.url}/relatoriooperacoes`} component={RelatorioOperacoes} exact />
          <Route path={`${match.url}/relatoriousuarios`} component={RelatorioUsuarios} exact />
          <Route path={`${match.url}/relatoriofinanceiro`} component={RelatorioFinanceiro} exact />

          <Route path={`${match.url}/contaalt`} component={ContaAlt} exact />
          <Route path={`${match.url}/formapagamento`} component={FormaPagamento} exact />
          <Route path={`${match.url}/integracoes`} component={Integracoes} exact />

          {/* <Route path={`${match.url}/empresaworkflow`} component={EmpresaWorkflow} exact /> */}
          <Route path={`${match.url}/empresaworkflowcadastro`} component={EmpresaWorkflowCadastro} exact />
          <Route path={`${match.url}/empresaworkflowvendacadastro`} component={EmpresaWorkflowVendaCadastro} exact />
          <Route path={`${match.url}/termospolitica`} component={TermosPolitica} exact />

          {/* <Route path={`${match.url}/portfolio`} component={Portfolio} exact />
          <Route path={`${match.url}/galeria`} component={Galeria} exact />
          <Route path={`${match.url}/galeriafoco`} component={GaleriaFoco} exact /> */}

          {/* <Route path={`${match.url}/enviarnotificacaoclientes`} component={EnviarNotificacaoClientes} exact />
          <Route path={`${match.url}/enviarnotificacaoarquitetos`} component={EnviarNotificacaoArquitetos} exact /> */}


          {/* <Route path={`${match.url}/portfoliovisualizar`} component={PortfolioVisualizar} exact /> */}

          <Route path={`${match.url}/servicoscatalogo`} component={ServicosCatalogo} exact />
          <Route path={`${match.url}/servicoscategorias`} component={ServicosCategorias} exact />

          <Route path={`${match.url}/produtoscatalogo`} component={ProdutosCatalogo} exact />
          <Route path={`${match.url}/produtoscategorias`} component={ProdutosCategorias} exact />
          <Route path={`${match.url}/produtoscategoriasnovo`} component={ProdutosCategoriasNovo} exact />

          <Route path={`${match.url}/formulario`} component={Formulario} exact />
          <Route path={`${match.url}/formulariocadastro`} component={FormularioCadastro} exact />
          <Route path={`${match.url}/formulariocategorias`} component={FormularioCategorias} exact />

          <Route path={`${match.url}/financeirocategorias`} component={FinanceiroCategorias} exact />

          <Route path={`${match.url}/menucadastros`} component={MenuCadastros} exact />
          {/* <Route path={`${match.url}/menucampanha`} component={MenuCampanha} exact /> */}
          <Route path={`${match.url}/menurelatorios`} component={MenuRelatorios} exact />
          <Route path={`${match.url}/menuconfiguracoes`} component={MenuConfiguracoes} exact />

          {/* <Route path={`${match.url}/lembretes`} component={Lembretes} exact /> */}

          <Route exact path={match.url} render={() => <Redirect to={`${match.url}/inicio`} />} />
        </IonRouterOutlet>


        {/* {(!isPlatform('mobileweb') && isPlatform('mobile')) ? (
          <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
            <IonTabButton href={`${match.url}/inicio`} tab="inicio">
              <IonIcon icon={homeOutline}></IonIcon>
              <IonLabel style={{ fontSize: "12px", fontWeight: 'bold' }}>Início</IonLabel>
            </IonTabButton>
            <IonTabButton disabled={true}>
            </IonTabButton>
            <IonTabButton href={`${match.url}/painel`} tab="painel">
              <IonIcon icon={gridOutline}></IonIcon>
              <IonLabel style={{ fontSize: "12px", fontWeight: 'bold' }}>Painel</IonLabel>
            </IonTabButton>
          </IonTabBar>

        ) : ( */}





        <IonTabBar slot="bottom" style={{ shadowBox: "0px 0px 5px black" }}>
          <IonTabButton href={`${match.url}/inicio`} tab="inicio">
            <IonIcon icon={homeOutline}></IonIcon>
            <IonLabel style={{ fontSize: "10px" }}>Início</IonLabel>
          </IonTabButton>
          {/* <IonTabButton href={`${match.url}/painel`} tab="painel">
              <IonIcon icon={gridOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Painel</IonLabel>
            </IonTabButton> */}
          {(acessos?.vendas && acessos?.vendas != 'sem acesso') && (window.localStorage.getItem("configWorkflowVenda")) ? (
            <IonTabButton href={`${match.url}/vendas`} tab="vendas" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={trendingUpOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Vendas</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.balcao && acessos?.balcao != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/caixa`} tab="caixa" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={desktopOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>{(window.localStorage.getItem("nomeInternonomeInternoBalcaoOuCaixa") || "Balcão")}</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.comandas && acessos?.comandas != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/comandas`} tab="comandas" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={clipboardOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>{(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.projetos && acessos?.projetos != 'sem acesso') && (window.localStorage.getItem("configWorkflow")) ? (
            <IonTabButton href={`${match.url}/projetos`} tab="projetos" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={appsOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>{window.localStorage.getItem("servicoTipoPlural") || "Projetos"}</IonLabel>
            </IonTabButton>) : <></>}

          {(acessos?.agenda && acessos?.agenda != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/agendamentoseventoscommenu`} tab="agenda" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={calendarOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Agenda</IonLabel>
            </IonTabButton>) : <></>}
          {(acessos?.operacoes && acessos?.operacoes != 'sem acesso') ? (
            <IonTabButton href={`${match.url}/operacoes`} tab="operacoes" disabled={dadosEmpresa.bloquearAcesso}>
              <IonIcon icon={flagOutline}></IonIcon>
              <IonLabel style={{ fontSize: "10px" }}>Operações</IonLabel>
            </IonTabButton>) : <></>}
        </IonTabBar>




        {/* )} */}



      </IonTabs>

      {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} hidden={!(!isPlatform('mobileweb') && isPlatform('mobile')) || esconderMenu}>
        <IonFab vertical="bottom" horizontal="center">
          <IonFabButton data-desc={nomeMenu} color="tertiary" onClick={() => { setShowActionSheet(true); }}>
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>          
        </IonFab>
      </div> */}

      {/* {
        <Menu id="main-content-inicio"></Menu>
      } */}

      <IonAlert isOpen={alertDisputaEncerradaIfood}
        onDidDismiss={() => setAlertDisputaEncerradaIfood(false)}
        header={'iFood'}
        message={mensagemDisputaEncerradaAlerta}
        buttons={[
          {
            text: 'OK',
            role: 'cancel',
          }

        ]}
      />

      <IonAlert
        isOpen={alertCancelamentoIfood}
        onDidDismiss={() => setAlertCancelamentoIfood(false)}
        header={'Cancelamento iFood'}
        message={mensagemAlerta}
        buttons={[
          {
            text: 'OK',
            role: 'cancel',
          }
        ]}
      />

      <IonModal isOpen={alertDisputaIfood} onDidDismiss={() => setAlertDisputaIfood(false)}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setAlertDisputaIfood(false)} />
            </IonButtons>
            <IonTitle>Detalhes da disputa</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
          <IonText>
            <h2>
              {pedidoSelecionado?.element?.metadata?.action === "PARTIAL_CANCELLATION"
                ? "CANCELAMENTO PARCIAL"
                : "CANCELAMENTO TOTAL"}
            </h2>
          </IonText>

          <IonItem lines="none" style={{ marginTop: "16px" }}>
            <IonLabel>
              Tempo restante para resposta: <strong>{timeLeft}</strong>
            </IonLabel>
          </IonItem>

          <IonItem lines="none" style={{ marginTop: "16px" }}>
            {/* Exibição dos itens no caso de cancelamento parcial */}
            {(pedidoSelecionado?.element?.metadata?.action === "PARTIAL_CANCELLATION") ? (

              <IonList>
                <IonLabel>
                  <h3>Cliente solicitou o reembolso dos itens</h3>
                </IonLabel>
                {pedidoSelecionado?.element?.metadata?.metadata?.items?.map((item: any, index: number) => {
                  const totalItem = (Number(item.amount.value) / 100) * item.quantity; // Calcula o total (dividido por 100 para considerar o formato em reais)
                  return (
                    <IonItem lines="none" key={item.id}>
                      <IonLabel>
                        {/* <p><strong>Código:</strong> {item.externalCode}</p> */}
                        <p><strong>Valor Unitário:</strong> R$ {(Number(item.amount.value) / 100).toFixed(2).replace('.', ',')}</p>
                        <p><strong>Quantidade:</strong> {item.quantity}</p>
                        <p><strong>Total:</strong> R$ {totalItem.toFixed(2).replace('.', ',')}</p>
                        <p><strong>Motivo:</strong> {item.reason}</p>
                      </IonLabel>
                    </IonItem>
                  );
                })}

                {pedidoSelecionado?.element?.metadata?.metadata?.garnishItems?.map((item: any, index: number) => {
                  const totalItem = (Number(item.amount.value) / 100) * item.quantity; // Calcula o total (dividido por 100 para considerar o formato em reais)
                  return (
                    <IonItem lines="none" key={item.id}>
                      <IonLabel>
                        {/* <p><strong>Código:</strong> {item.externalCode}</p> */}
                        <p><strong>Valor Unitário:</strong> R$ {(Number(item.amount.value) / 100).toFixed(2).replace('.', ',')}</p>
                        <p><strong>Quantidade:</strong> {item.quantity}</p>
                        <p><strong>Total:</strong> R$ {totalItem.toFixed(2).replace('.', ',')}</p>
                        <p><strong>Motivo:</strong> {item.reason}</p>
                      </IonLabel>
                    </IonItem>
                  );
                })}
              </IonList>
            ) :

              (<p><strong>Motivo:</strong> {pedidoSelecionado?.element?.metadata?.message}</p>)


            }
          </IonItem>

          {/* Evidências */}
          {/* {pedidoSelecionado?.element?.metadata?.metadata?.evidences?.length > 0 && (
            <IonItem lines="none" style={{ marginTop: "16px" }}>
              <IonLabel>Evidências:</IonLabel>
              {pedidoSelecionado.element.metadata.metadata.evidences.map((evidence: any, index: any) => (
                <img
                  key={index}
                  src={evidence.url}
                  alt={`Evidência ${index + 1}`}
                  style={{ width: '500px', marginTop: '10px', borderRadius: '8px' }}
                />
              ))}
            </IonItem>
          )} */}

          {/* <IonItem lines="none" style={{ marginTop: "16px" }}>
            <IonLabel>Detalhes do Pedido:</IonLabel>
            <IonButton
              slot="end"
              onClick={() => setShowJson((prevState) => !prevState)}
              expand="block"
            >
              {showJson ? "Ocultar" : "Mostrar"}
            </IonButton>
          </IonItem>

          {showJson && (
            <pre
              style={{
                textAlign: "left",
                backgroundColor: "#f4f4f4",
                padding: "10px",
                borderRadius: "5px",
                overflowX: "auto",
              }}
            >
              {JSON.stringify(pedidoSelecionado?.element, null, 2)}
            </pre>
          )} */}
        </IonContent>

        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {

                  if (isExpired) {
                    Toast.show({
                      text: "O tempo para resposta expirou.",
                      duration: "long",
                      position: "center",
                    });
                  } else {
                    const refundAlternative = pedidoSelecionado?.element?.metadata;

                    if (refundAlternative) {
                      console.log("Recusar reembolso", refundAlternative.disputeId);

                      mostartAlerta({
                        backdropDismiss: false,
                        header: "Motivo",
                        message: "Detalhe o motivo para rejeitar abaixo",
                        inputs: [
                          {
                            name: "mensagem",
                            type: "textarea",
                            id: "name2-id",
                            placeholder: "Digite aqui o motivo",
                          },
                        ],
                        buttons: [
                          "Cancel",
                          {
                            text: "Enviar",
                            handler: (d) => {
                              if (d.mensagem) {
                                disputeReject(refundAlternative.disputeId, d.mensagem);
                              } else {
                                Toast.show({
                                  text: "Motivo é obrigatório",
                                  position: "center",
                                  duration: "long",
                                });
                              }
                            },
                          },
                        ],
                        onDidDismiss: (e) => {
                          console.log("did dismiss");
                        },
                      });
                    } else {
                      console.error("Reembolso indisponível.");
                    }
                  }
                }}
              >
                {pedidoSelecionado?.element?.metadata?.action === "PARTIAL_CANCELLATION"
                  ? "Recusar reembolso parcial"
                  : "Recusar reembolso total"}
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  if (isExpired) {
                    Toast.show({
                      text: "O tempo para resposta expirou.",
                      duration: "long",
                      position: "center",
                    });
                  } else {
                    const refundAlternative = pedidoSelecionado?.element?.metadata;

                    if (refundAlternative) {
                      console.log("Aceitar reembolso", refundAlternative.disputeId);

                      setShowModalMotivo(true);
                    } else {
                      console.error("Reembolso indisponível.");
                    }
                  }
                }}
              >
                {pedidoSelecionado?.element?.metadata?.action === "PARTIAL_CANCELLATION"
                  ? "Aceitar reembolso parcial"
                  : "Aceitar reembolso total"}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal >



      <IonModal isOpen={modalDisputaIfood} onDidDismiss={() => setModalDisputaIfood(false)}>

        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Disputa iFood</IonTitle>
            <IonButtons slot="start" onClick={() => setModalDisputaIfood(false)}>
              <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">

          {pedidosEmDisputa.length > 0 ? (
            <IonList>
              {pedidosEmDisputa.map((pedido: any) => (
                <IonItem
                  key={pedido.displayId}
                  button
                  onClick={() => {
                    setPedidoSelecionado(pedido);
                    setAlertDisputaIfood(true);
                  }}
                >
                  <IonLabel>Pedido #{pedido.displayId}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          ) : (
            <p>Nenhum pedido em disputa.</p>
          )}
        </IonContent>
      </IonModal>




      <IonModal isOpen={showModalMotivo} onDidDismiss={() => setShowModalMotivo(false)}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons slot="start">
              <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowModalMotivo(false)} />
            </IonButtons>
            <IonTitle>Motivo do aceite</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonLabel style={{ padding: "15px", display: "block" }}>Selecione o motivo do aceite:</IonLabel>
          <IonSelect
            value={selectedReason}
            placeholder="Escolha um motivo"
            onIonChange={(e) => setSelectedReason(e.detail.value)}
            interfaceOptions={{
              cssClass: 'custom-select',
            }}

          >
            {/* {pedidoSelecionado?.element?.metadata?.metadata?.acceptCancellationReasons?.map((reason: string) => (
              <IonSelectOption key={reason} value={reason}>
                {traduzirMotivo(reason)}
              </IonSelectOption>
            ))} */}

            {listaMotivosCancelamento.map((motivo) => (
              <IonSelectOption key={motivo.value} value={motivo.value}>
                {motivo.label}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton expand="block" onClick={handleSend} disabled={!selectedReason}>
              Avançar
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>

      <IonModal
        backdropDismiss={false} // Impede fechar clicando fora
        // canDismiss={false} // Bloqueia qualquer tentativa de fechar o modal
        isOpen={showPagamentoMaquininha}
      //  onDidDismiss={() => {
      //   setShowPagamentoMaquininha(false);
      // }}
      >
        <IonHeader>
          <IonToolbar color="primary">
            {/* <IonButtons slot="start">
                <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowPagamentoMaquininha(false)} />
              </IonButtons> */}
            <IonTitle>Integração com Maquinha Point</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>




          <IonCard className="div-qrcode-pagamento">
            <IonCardContent>
              {/* {!qrCodePagamentoMaquininha ? ( */}
              <>

                <IonLabel className="qr-code-pagamento">
                  <h2><b>Aguardando pagamento na maquininha...</b></h2>
                </IonLabel>
              </>
              {/* ) : (
                <>
     
                  <IonLabel className="qr-code-pagamento">
                    <h2><b>Aguardando pagamento via pix...</b></h2>
                  </IonLabel>
              
                  <IonCardContent className="qr-code-pagamento">
                    <IonLabel>
                      <p>Código QR</p>
                    </IonLabel>
                    {qrCodeDataMaquininha && <img src={`${qrCodeDataMaquininha}`} alt="QR Code" />}
                  </IonCardContent>
                </>
              )} */}


              <IonLabel className="qr-code-pagamento">
                <h1>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h1>
              </IonLabel>
            </IonCardContent>
          </IonCard>



        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButton expand="block" size='large' onClick={() => {



              cancelarPagamentoMercadoPago();
            }}>Cancelar
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>


      <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />

      <IonActionSheet
        backdropDismiss={true}
        translucent={true}
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass='my-custom-class'
        buttons={getButtons()}

      />

    </>
  );

};

export default Home;
