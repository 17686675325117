import { IonButton, IonCol, IonGrid, IonImg, IonRow } from '@ionic/react';
import React from 'react';
import './PromotionBanner.css';
import imgBanner from './imgs/xeguei-promotion-banner.png';

interface PromotionProps {
  title: string;
  subTitle: string;
  subTitle2: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
}

const PromotionBanner: React.FC<PromotionProps> = ({
  title,
  subTitle,
  subTitle2,
  description,
  buttonText,
  buttonAction,
}) => {
  return (
    <IonGrid>
      <IonRow className="container-banner">
        {/* Imagem */}
        <IonCol size="12" sizeMd="6">
          <IonImg src={imgBanner} alt={title} className="image-banner" />
        </IonCol>

        {/* Texto */}
        <IonCol size="12" sizeMd="6">
          <div className="textContainer-banner">
            <h2 className="title-banner">{title}</h2>
            <h2 className="subTitle-banner">{subTitle}</h2>
            <p className="subTitle2-banner">{subTitle2}</p>
            <p className="description-banner">{description}</p>
            <IonButton size="large" onClick={buttonAction}>
              {buttonText}
            </IonButton>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default PromotionBanner;
