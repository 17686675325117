import { App } from '@capacitor/app';
import { Toast } from '@capacitor/toast';
import { useIonAlert } from '@ionic/react';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageContext } from '../contexts/MessageContext';

const useDeepLinkHandler = () => {
  const history = useHistory();
  const [mostrarAlerta] = useIonAlert();
  const { idPaymentSuccess, setIdPaymentSuccess } = useContext(MessageContext);

  useEffect(() => {
    const handleDeepLink = (event: any) => {
      if (event && event.url) {
        // Extrair o caminho do link profundo
        const url = new URL(event.url);
        const path = url.pathname;

        console.log(`Deep Link URL: ${event.url}`);


        console.log(`Deep Link PATH: ${path}`);

        // Verifica se o deeplink de retorno corresponde ao esperado
        if (event.url.startsWith('xeguei://infinitepay')) {
          const url = new URL(event.url);
          const orderId = url.searchParams.get('order_id');
          const nsu = url.searchParams.get('nsu');
          const aut = url.searchParams.get('aut');
          const failure = url.searchParams.get('failure');

          if (failure) {
            console.error(`Transação falhou. Motivo: ${failure}. Pedido: ${orderId}`);
            
            Toast.show({
              text: "Pagamento cancelado",
              position: "center",
              duration: "long"
            });
            
            // mostrarAlerta({
            //   backdropDismiss: false,
            //   header: 'Retorno Pagamento InfinitePay',
            //   message: `Transação falhou. Motivo: ${failure}. Pedido: ${orderId}`,
            //   buttons: [
            //     {
            //       text: 'Fechar',
            //       handler: async () => {
            //       }
            //     }],
            //   onDidDismiss: () => { console.log('did dismiss'); }

            // })
          } else {
            console.log(`Transação bem-sucedida! Pedido: ${orderId}, NSU: ${nsu}, AUT: ${aut}`);

            setIdPaymentSuccess(orderId);

            Toast.show({
              text: "Pagamento realizado com sucesso",
              position: "center",
              duration: "long"
            });

            // mostrarAlerta({
            //   backdropDismiss: false,
            //   header: 'Retorno Pagamento InfinitePay',
            //   message: `Transação bem-sucedida! Pedido: ${orderId}, NSU: ${nsu}, AUT: ${aut}`,
            //   buttons: [
            //     {
            //       text: 'Fechar',
            //       handler: async () => {
            //       }
            //     }],
            //   onDidDismiss: () => { console.log('did dismiss'); }

            // })
          }

          // Redirecionar para o caminho específico baseado no deep link
          // if (path) {
          //   history.push(path);
          // }
        }


      }
    };

    // Adicionar listener para capturar eventos de deep link
    App.addListener('appUrlOpen', handleDeepLink);

    // Cleanup do listener ao desmontar o componente
    return () => {
      App.removeAllListeners();
    };
  }, [history]);

  return null; // Nenhuma renderização é necessária
};

export default useDeepLinkHandler;