import { ScreenOrientation } from '@ionic-native/screen-orientation';
import { IonApp, IonContent, IonHeader, IonImg, IonItem, IonLabel, IonPage, IonRouterOutlet, IonTitle, IonToolbar, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import './App.css';
import logoTransparente from './theme/icons/Logo.png';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import { autenticado, requestService } from './Utils/Services';
import Home from './pages/Empresa/Home/Home';
import Cadastro from './pages/Inicio/Cadastro/Cadastro';
import Login from './pages/Inicio/Login/Login';
import Onboarding from './pages/Inicio/Onboarding/Onboarding';
import TipoConta from './pages/Inicio/TipoConta/TipoConta';
import ValidarOtp from './pages/Inicio/ValidarOtp/ValidarOtp';
import './theme/variables.css';

import { Network } from '@capacitor/network';
import { MessageContextProvider } from './contexts/MessageContext';

import { LocalNotificationActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import CadastroCnpj from './pages/Inicio/CadastroCnpj/CadastroCnpj';
import CadastroSucesso from './pages/Inicio/CadastroSucesso/CadastroSucesso';
import LoginConvite from './pages/Inicio/LoginConvite/LoginConvite';

class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasConnection: true,
      //hasGeoLocation: true,
      loaded: false,
      destino: "/"
    }


    // desebilita gesto de voltar passando de tela nos dispositivos que tenham ios
    if (isPlatform('ios')) {
      setupIonicReact({
        mode: 'md',
        swipeBackEnabled: false,
      });
    }
    else {
      setupIonicReact({
        mode: 'md'
      });
    }

    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      this.addListeners();
    }

    // Push notification para celulares
    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      console.log("entrou chaveContato");
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {

            }
            else {
              console.log("entrou chaveContato 2");
              PushNotifications.register();
            }
          });
        }
        else {
          console.log("entrou chaveContato 3");
          PushNotifications.register();
        }
      });
    }
    else {
      console.log("n entrou chaveContato");
      if (window.localStorage.getItem("chaveContato") != '0')
        window.localStorage.setItem("chaveContato", "0");//atualizarChaveContato('0');
    }

    // Local notification para celulares
    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      LocalNotifications.checkPermissions().then(
        callback => {
          if (callback.display == 'denied') {
            //setShowToastNotification(true);
          }
        });

      LocalNotifications.removeAllListeners();

      LocalNotifications.addListener("localNotificationActionPerformed",
        async (notification: LocalNotificationActionPerformed) => {
          await LocalNotifications.schedule({
            notifications: [
              {
                title: notification.notification.title || '',
                body: notification.notification.body || '',
                id: Math.floor(Math.random() * 10000) + 1,
                extra: {
                  notification: notification.notification
                }
              }
            ]
          });
        }
      );
    }

    const tempo = 2500;

    var token: string = "";
    var tkn = localStorage.getItem("token");
    var cpf = localStorage.getItem("cpf") || "";
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    Network.addListener('networkStatusChange', status => {
      this.setState({
        hasConnection: status.connected,
        //hasGeoLocation: this.state.hasGeoLocation,
        loaded: this.state.loaded,
        destino: this.state.destino
      });
    });

    const objectRequest = {
      chaveContato: window.localStorage.getItem("chaveContato") || "0"
    }

    //alert(window.localStorage.getItem("chaveContato") || "0");

    requestService(
      autenticado.url,
      {
        method: autenticado.method,
        headers: [
          ["token", token]
        ],
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
      this.props.history,
      (response: any) => {
        //console.log(response);
        console.log("objeto de retorno", response);
        if (response.Item && response.Item.lojas) window.localStorage.setItem("lojas", JSON.stringify(response.Item.lojas));
        if (response.acessos) {
          window.localStorage.setItem("acessos", JSON.stringify(response.acessos));
        }
        if (response.status === 200) {
          setTimeout(() => {
            this.setState({
              hasConnection: this.state.hasConnection,
              //hasGeoLocation: this.state.hasGeoLocation,
              loaded: true,
              //  destino: !(!isPlatform('mobileweb') && isPlatform('mobile')) ? "/empresa/painel" : "/empresa/inicio"
              destino: "/empresa/inicio"
            });
          },
            tempo);
        }

      },
      (error: any) => {
        //window.localStorage.setItem("logado", "false");
        if (error.status === 400) {
          console.log(error.fcmAtivo ?? false);
          window.localStorage.setItem("fcmAtivo", error.fcmAtivo ?? false);

          if (window.location.href.includes("convite")) {

            const urlParams = new URLSearchParams(window.location.search);
            const convite = urlParams.get('convite'); // Obtém o valor do parâmetro 'convite'
            console.log("Valor do convite:", convite);

            setTimeout(() => {
              this.setState({
                hasConnection: this.state.hasConnection,
                //hasGeoLocation: this.state.hasGeoLocation,
                loaded: true,
                destino: "/login-convite?convite=" + convite
              });
            },
              tempo);
          }
          else {
            setTimeout(() => {
              this.setState({
                hasConnection: this.state.hasConnection,
                //hasGeoLocation: this.state.hasGeoLocation,
                loaded: true,
                destino: "/login"
              });
            },
              tempo);
          }
        } else
          if (error.status === 401 || error.status === 403) {
            setTimeout(() => {
              this.setState({
                hasConnection: this.state.hasConnection,
                //hasGeoLocation: this.state.hasGeoLocation,
                loaded: true,
                destino: "/login"
              });
            },
              tempo);
          }
      },
      undefined,
      true
    );
    this.getSplash = this.getSplash.bind(this);
    if (!isPlatform('mobileweb') && isPlatform('mobile')) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }
  }

  addListeners = () => {

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {

        console.log('tokenchavecontato:' + token.value.toString());
        window.localStorage.setItem("chaveContato", token.value.toString());
        // if (window.localStorage.getItem("chaveContato") != token.value.toString())
        //   atualizarChaveContato(token.value.toString());

        //chamar endpoint para incluir a chave do fcm no usuario

        const objectRequest = {
          chaveContato: token.value.toString() || "0"
        }

        requestService(
          autenticado.url,
          {
            method: autenticado.method,
            headers: [
              ["token", localStorage.getItem("token") || ""]
            ],
            body: JSON.stringify(objectRequest),
            credentials: 'include'
          }, null, () => { }, () => { }
        );
      }
    );

    console.log("entrou chaveContato 5");
    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        //this.showToast("erro ao registrar token");
        //alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    console.log("entrou chaveContato 6");

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        //setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
        const randomId = Math.floor(Math.random() * 10000) + 1;
        //setShowToast2(true);
        await LocalNotifications.schedule({
          notifications: [
            {
              title: notification.title || '',
              body: notification.body || '',
              id: randomId,
              extra: {
                notification: notification
              }
            }
          ]
        });

      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        //setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
      }
    );
  }

  getSplash() {
    if (!this.state.loaded) {
      document.documentElement.style.setProperty('--ion-background-color', '#ff7000');
      //console.log("loading")
      return (<IonPage>
        <IonContent className="Intro">
          <div className={"background-intro"}></div>
          <IonImg src={logoTransparente}></IonImg>
        </IonContent>
      </IonPage>);
    } else {
      document.documentElement.style.setProperty('--ion-background-color', '#fff');
      //console.log("passou aqui")
      return (<Redirect to={this.state.destino} />);
    }
  }

  render() {
    return (
      <IonApp>
        <MessageContextProvider>
          <IonReactRouter>
            <IonRouterOutlet >
              <Route path="/empresa" component={Home} />
              <Route path="/onboarding" component={Onboarding} exact={true} />
              <Route path="/login" component={Login} exact={true} />
              <Route path="/login-convite" component={LoginConvite} exact={true} />
              <Route path="/tipoconta" component={TipoConta} exact={true} />
              <Route path="/cadastro" component={Cadastro} exact={true} />
              <Route path="/cadastro-cnpj" component={CadastroCnpj} exact={true} />
              <Route path="/cadastro-sucesso" component={CadastroSucesso} exact={true} />
              <Route path="/validar-otp" component={ValidarOtp} exact={true} />
            </IonRouterOutlet>


            {this.getSplash()}

            <IonPage hidden={this.state.hasConnection} color="primary">
              <IonHeader>
                <IonToolbar color="primary">
                  <IonTitle>OPS...</IonTitle>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                <IonItem style={{ "paddingTop": "10px", "paddingBottom": "10px" }} lines='full' hidden={this.state.hasConnection}>
                  <IonLabel className="ion-text-wrap" >Parece que você está sem conexão com a internet.</IonLabel>
                </IonItem>

              </IonContent>

            </IonPage>

          </IonReactRouter>
        </MessageContextProvider>
      </IonApp>
    );
  }
}

export default App;
