import { IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonTitle, IonToast, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { arrowBack } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { apiProjetosCaixaGeralLancamentosGET, apiProjetosCaixasGET, apiProjetosCaixasPUT, requestService } from '../../../../../Utils/Services';
import CaixaGeralAbrir from '../CaixaGeralAbrir/CaixaGeralAbrir';
import './CaixaGeral.css';

const CaixaGeral: React.FC = () => {

  const history = useHistory();
  const [stateResultadoMesas, setStateResultadoMesas] = useState<any>([]);
  const [stateResultadoBalcao, setStateResultadoBalcao] = useState<any>([]);
  const [stateResultadoProjetos, setStateResultadoProjetos] = useState<any>([]);
  const [stateTotal, setStateTotal] = useState<number>(0);
  const [stateTotalBalcao, setStateTotalBalcao] = useState<number>(0);
  const [stateTotalMesa, setStateTotalMesa] = useState<number>(0);
  const [stateTotalProjetos, setStateTotalProjetos] = useState<number>(0);
  const [stateDinheiro, setStateDinheiro] = useState<number>(0);
  // const [stateCartaoCredito, setStateCartaoCredito] = useState<number>(0);
  // const [stateCartaoDebito, setStateCartaoDebito] = useState<number>(0);
  // const [statePix, setStatePix] = useState<number>(0);
  // const [stateAlelo, setStateAlelo] = useState<number>(0);
  // const [stateSodexo, setStateSodexo] = useState<number>(0);
  // const [stateValeAlimentacao, setStateValeAlimentacao] = useState<number>(0);
  // const [stateBoleto, setStateBoleto] = useState<number>(0);
  // const [stateSemDefinicao, setStateSemDefinicao] = useState<number>(0);
  const [stateCaixas, setStateCaixas] = useState<any>([]);
  const [stateCaixaSelecionado, setStateCaixaSelecionado] = useState<any>({});

  const [statePagamentos, setStatePagamentos] = useState<{ [key: string]: number }>({});

  const [mostartAlerta] = useIonAlert();
  const [textToast, setTextToast] = useState("");
  const [showToast, setShowToast] = useState(false);

  const [caixaAberto, setCaixaAberto] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);

  const [showModal, setShowModal] = useState(false);

  // Loading State
  const [showLoading, setShowLoading] = useState(false);

  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));


  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarCaixas();
  });

  function carregarCaixas() {
    return requestService(
      apiProjetosCaixasGET.url.replace("{id}", loja.replaceAll("#", "%23")),
      {
        method: apiProjetosCaixasGET.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        if (response) {
          setStateCaixas(response);
          //console.log('getCaixas', response);
          if (response.Items[0]) {
            setStateCaixaSelecionado(response.Items[0]);
            carregarCaixaGeral(response.Items[0].sk);
          }
        }
      },
      (error: any) => {
        console.log(error);
      }
    )
  };

  function carregarCaixaGeral(filtro: any = null) {
    return requestService(
      apiProjetosCaixaGeralLancamentosGET.url.replace("{id}", loja.replaceAll("#", "%23")).concat(filtro ? "?caixa=".concat(Buffer.from(filtro).toString('base64')) : ""),
      {
        method: apiProjetosCaixaGeralLancamentosGET.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        if (response) {
          setStateResultadoMesas(response.retComanda);
          setStateResultadoBalcao(response.retBalcao);
          setStateResultadoProjetos(response.retProjetos);
          setStateTotal(response.total);
          setStateTotalBalcao(response.totalBalcao);
          setStateTotalMesa(response.totalMesa);
          setStateTotalProjetos(response.totalProjetos);


          // setStateDinheiro(response.dinheiro);
          // setStateCartaoCredito(response.cartaoCredito);
          // setStateCartaoDebito(response.cartaoDebito);
          // setStatePix(response.pix);
          // setStateAlelo(response.alelo);
          // setStateSodexo(response.sodexo);
          // setStateValeAlimentacao(response.valeAlimentacao);
          // setStateBoleto(response.boleto);

          // console.log("boleto", response.boleto);
          // setStateSemDefinicao(response.semDefinicao);

          const pagamentos = response.totaisPorFormaDePagamento || {};

          console.log("pagamentos", pagamentos);

          const novoEstadoPagamentos: { [key: string]: number } = {};

          // Itera sobre as entradas do objeto e constrói o estado
          Object.entries(pagamentos).forEach(([nome, valor]) => {
            const valorNumerico = Number(valor);
            novoEstadoPagamentos[nome] = valorNumerico;

            // Verifica se o nome (em lowercase) é "dinheiro"
            if (nome.toLowerCase() === "dinheiro") {
              setStateDinheiro(valorNumerico);
            }
          });

          setStatePagamentos(novoEstadoPagamentos);

        }
      },
      (error: any) => {
        console.log(error);
      }
    )
  };

  const mensagemFecharCaixa = () => {
    mostartAlerta({
      backdropDismiss: false,
      header: "Atenção!",
      message: "Você tem certeza que deseja fechar o caixa?",
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {

          }
        },
        {
          text: 'Confirmar',
          handler: async () => {


            fecharCaixa();

          }
        }
      ],
      onDidDismiss: () => { console.log('did dismiss'); }
    });
  };

  const fecharCaixa = () => {
    setShowLoading(true);

    let objRequest = {
      loja: loja,
      pk: stateCaixaSelecionado.pk,
      sk: stateCaixaSelecionado.sk,
    }

    requestService(
      apiProjetosCaixasPUT.url.replace("{id}", loja.replaceAll("#", "%23")),
      {
        method: apiProjetosCaixasPUT.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
      history,
      (response: any) => {
        if (response.message && response.message.Attributes) {
          carregarCaixas();
        }
      },
      (error: any) => {
        console.log(error);
        setTextToast(error.message);
        setShowToast(true);
      }
    ).finally(() => {
      setShowLoading(false);
    });
  };


  async function closeModal() {
    setShowModal(false);
  }

  async function closeModalSuccess() {
    carregarCaixas();
    setShowModal(false);
  }


  return (
    <IonPage>
      <IonHeader>

        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Caixa</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol size='12' sizeMd='4'>
              <IonItem lines='full' color='light'>
                <IonLabel>
                  <h1>Histórico</h1>
                  <p>Registro de caixas da empresa</p>
                </IonLabel>
              </IonItem>
              <div style={{ paddingTop: "10px" }}>
                <IonButton hidden={stateCaixas?.Items?.some((item: any) => item.status === 'aberto') || false}
                  disabled={acessos?.caixa != 'edicao'}
                  color='success'
                  expand='block'
                  size='large'
                  onClick={() => {
                    setShowModal(true);
                  }}>Quero abrir meu Caixa</IonButton>
              </div>
              <div style={{ paddingTop: "10px" }}>
                {
                  stateCaixas.Items && stateCaixas.Items.map((item: any) => {
                    return (
                      <IonItem detail button lines='none'
                        color={item.sk === stateCaixaSelecionado.sk ? 'selected' : ''}
                        onClick={() => {
                          console.log("item", item);
                          carregarCaixaGeral(item.sk);
                          setStateCaixaSelecionado(item);
                        }}
                      >
                        <IonLabel>{new Date(item.dataAbertura).toLocaleString("en-GB")}</IonLabel><IonChip slot='end' color={item.status === 'fechado' ? 'danger' : 'success'}>{item.status}</IonChip></IonItem>
                    );
                  })
                }
              </div>
            </IonCol>
            <IonCol size='12' sizeMd='8'>
              <IonItem lines='full' color='light'>
                <IonLabel>
                  <h1>Resumo do Caixa</h1>
                  <p>Acompanhamento do caixa da empresa</p>
                </IonLabel>
              </IonItem>
              <div className='caixaGeralTabelaCaixa'>
                {/* <IonItem lines='none' hidden={true}>
                  <IonLabel><b>Ações no caixa</b></IonLabel>
                  <IonChip color='success'>
                    <IonLabel>Suprimento</IonLabel>
                    <IonIcon icon={addCircle}></IonIcon>
                  </IonChip>
                  <IonChip color='danger'>
                    <IonLabel>Sangria</IonLabel>
                    <IonIcon src={removeCircle}></IonIcon>
                  </IonChip>
                </IonItem> */}

                <div style={{ paddingTop: "10px" }}>
                  <IonGrid className='data'>
                    <IonRow>
                      <IonCol>Status</IonCol>
                      <IonCol size='8'><b>{stateCaixaSelecionado.status}</b></IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>Abertura</IonCol>
                      <IonCol size='8'>{stateCaixaSelecionado.dataAbertura && new Date(stateCaixaSelecionado.dataAbertura).toLocaleString("en-GB")}</IonCol>
                    </IonRow>
                    <IonRow hidden={stateCaixaSelecionado.dataFechamento ? false : true}>
                      <IonCol>Fechamento</IonCol>
                      <IonCol size='8'>{stateCaixaSelecionado.dataFechamento && new Date(stateCaixaSelecionado.dataFechamento).toLocaleString("en-GB")}</IonCol>
                    </IonRow>
                  </IonGrid>




                  <IonGrid>
                    <IonRow>


                      <IonCol size="12" sizeMd="4">
                        <IonCard style={{ margin: "2px" }}

                        >

                          <IonCardContent>


                            <div style={{ fontSize: "16px" }}>
                              <IonLabel className="ion-text-wrap">
                                <b>Vendas</b>
                              </IonLabel>
                            </div>


                            <div style={{ fontSize: "20px" }}>
                              <IonLabel className="ion-text-wrap">
                                {"R$ " + Number(stateTotal).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </IonLabel>
                            </div>


                          </IonCardContent>
                        </IonCard>
                      </IonCol>

                      <IonCol size="12" sizeMd="4">
                        <IonCard style={{ margin: "2px" }}

                        >

                          <IonCardContent>

                            <div style={{ fontSize: "16px" }}>
                              <IonLabel className="ion-text-wrap">
                                <b>Valor de abertura</b>
                              </IonLabel>
                            </div>


                            <div style={{ fontSize: "20px" }}>
                              <IonLabel className="ion-text-wrap">
                                {"R$ " + Number(stateCaixaSelecionado.abertura || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </IonLabel>
                            </div>


                          </IonCardContent>
                        </IonCard>
                      </IonCol>

                      <IonCol size="12" sizeMd="4">
                        <IonCard style={{ margin: "2px" }}

                        >

                          <IonCardContent>

                            <div style={{ fontSize: "16px" }}>
                              <IonLabel className="ion-text-wrap">
                                <b>Dinheiro em caixa</b>
                              </IonLabel>
                            </div>


                            <div style={{ fontSize: "20px" }}>
                              <IonLabel className="ion-text-wrap">
                                {"R$ " + Number((stateDinheiro + stateCaixaSelecionado.abertura) || 0).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </IonLabel>
                            </div>


                          </IonCardContent>
                        </IonCard>
                      </IonCol>




                    </IonRow>
                  </IonGrid>

                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonButton hidden={!(stateCaixaSelecionado.status == 'aberto')}
                          color='danger'
                          expand='block'
                          disabled={acessos?.caixa != 'edicao'}
                          size='large'
                          onClick={() => {
                            mensagemFecharCaixa();
                          }}
                        >Quero fechar meu Caixa</IonButton>
                      </IonCol>
                    </IonRow>
                  </IonGrid>


                  <IonItem lines='full' color='light'>
                    <IonLabel style={{ fontSize: "20px" }}>
                      Total por tipo de venda
                    </IonLabel>
                  </IonItem>
                  <div className='caixaGeralTabelaCaixa'>


                    <IonGrid className='lancamentos'>

                      <IonRow hidden={stateTotalBalcao == 0}>
                        <IonCol>Balcão</IonCol>
                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateTotalBalcao).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateTotalMesa == 0}>
                        <IonCol>Mesa</IonCol>
                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateTotalMesa).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateTotalProjetos == 0}>
                        <IonCol>{(window.localStorage.getItem("servicoTipoPlural") || "Projetos")}</IonCol>
                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateTotalProjetos).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                    </IonGrid>

                  </div>

                  <IonItem lines='full' color='light'>
                    <IonLabel style={{ fontSize: "20px" }}>
                      Total por forma de pagamento
                    </IonLabel>
                  </IonItem>
                  <div className='caixaGeralTabelaCaixa'>
                    {/* <IonGrid className='lancamentos'>


                      <IonRow hidden={stateDinheiro == 0}>
                        <IonCol>Dinheiro</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateDinheiro).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateCartaoCredito == 0}>
                        <IonCol>Cartão de Crédito</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateCartaoCredito).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateCartaoDebito == 0}>
                        <IonCol>Cartão de Débito</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateCartaoDebito).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={statePix == 0}>
                        <IonCol>Pix</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(statePix).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateAlelo == 0}>
                        <IonCol>Alelo</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateAlelo).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateSodexo == 0}>
                        <IonCol>Sodexo</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateSodexo).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateValeAlimentacao == 0}>
                        <IonCol>Vale Alimentação</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateValeAlimentacao).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateBoleto == 0}>
                        <IonCol>Boleto</IonCol>

                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateBoleto).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                      <IonRow hidden={stateSemDefinicao == 0}>
                        <IonCol>Sem Definição</IonCol>
                        <IonCol style={{ textAlign: "right" }}>{"R$ " + Number(stateSemDefinicao).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</IonCol>
                      </IonRow>

                    </IonGrid> */}

                    <IonGrid className="lancamentos">
                      {Object.entries(statePagamentos).map(([nome, valor]) => (
                        valor > 0 && (
                          <IonRow key={nome}>
                            <IonCol>{nome}</IonCol>
                            <IonCol style={{ textAlign: "right" }}>
                              {"R$ " + Number(valor).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </IonCol>
                          </IonRow>
                        )
                      ))}
                    </IonGrid>

                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal className="small-modal" isOpen={showModal} onDidDismiss={() => { setShowModal(false); }}>
          <CaixaGeralAbrir closeAction={closeModalSuccess} closeActionSimple={closeModal}></CaixaGeralAbrir>
        </IonModal>
      </IonContent>

      <IonToast
        isOpen={showToast}
        position="middle" //top, bottom, middle
        onDidDismiss={() => setShowToast(false)}
        message={textToast}
        duration={3000}
      />


      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => { setShowLoading(false); }}
        spinner='circles'
      />
    </IonPage>
  );
};

export default CaixaGeral;
