// import '@capacitor-community/http';
import { BleClient } from '@capacitor-community/bluetooth-le';
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonAlert, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar, isPlatform, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import imageCompression from 'browser-image-compression';
import { Buffer } from 'buffer';
import { addCircle, arrowBack } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { buscarEndereco, empresaAlterar, empresaConfiguracoesCarregar, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import Bluetooth from '../Bluetooth/Bluetooth';
import './ContaAlt.css';

type Props = {
  abrirMenu: boolean;
};

const ContaAlt: React.FC<Props> = (props: Props) => {

  const history = useHistory();

  const [showToast2, setShowToast2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [showLoading, setShowLoading] = useState(true);
  const [showSucesso, setShowSucesso] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [empresaNome, setEmpresaNome] = useState('');
  const [empresaEndereco, setEmpresaEndereco] = useState('');
  const [empresaTelefone, setEmpresaTelefone] = useState('');
  const [empresaLatitude, setEmpresaLatitude] = useState('');
  const [empresaLongitude, setEmpresaLongitude] = useState('');
  const [urlLogo, setUrlLogo] = useState('');

  const [impressoraLargura, setImpressoraLargura] = useState('');

  const [impressoraLocalHabilitar, setImpressoraLocalHabilitar] = useState<boolean>(false);
  const [impressoraLocalName, setImpressoraLocalName] = useState('');
  const [impressorasLocal, setImpressorasLocal] = useState<string[]>([]);

  const [impressoraRedeHabilitar, setImpressoraRedeHabilitar] = useState<boolean>(false);
  const [impressoraRedeIp, setImpressoraRedeIp] = useState('');
  const [impressoraRedePorta, setImpressoraRedePorta] = useState('');

  const [impressoraBluetoothHabilitar, setImpressoraBluetoothHabilitar] = useState<boolean>(false);
  const [impressoraBluetoothDeviceId, setImpressoraBluetoothDeviceId] = useState('');

  const [impressaoAutomaticaIfood, setImpressaoAutomaticaIfood] = useState<boolean>(false);

  const [condicoesGerais, setCondicoesGerais] = useState('');


  const [nomeInternoMesaOuComanda, setNomeInternoMesaOuComanda] = useState('');
  const [nomeInternoMesasOuComandas, setNomeInternoMesasOuComandas] = useState('');
  const [imprimirMesaParcial, setImprimirMesaParcial] = useState<boolean>(false);


  const [servicoTipo, setServicoTipo] = useState('');
  const [servicoTipoPlural, setServicoTipoPlural] = useState('');
  const [nomeLinhaDoTempo, setNomeLinhaDoTempo] = useState('');
  const [taxaServico, setTaxaServico] = useState('');
  const [identificacaoPorNumero, setIdentificacaoPorNumero] = useState<boolean>(false);
  const [layoutSimplificado, setLayoutSimplificado] = useState<boolean>(false);
  const [zerarValoresVenda, setZerarValoresVenda] = useState<boolean>(false);
  const [esconderValoresProjeto, setEsconderValoresProjeto] = useState<boolean>(false);
  const [tipoAgendamentoPadrao, setTipoAgendamentoPadrao] = useState('');
  const [servicoPeriodoHoraEmpresa, setServicoPeriodoHoraEmpresa] = useState<boolean>(false);
  const [parametroMostrarHorario, setParametroMostrarHorario] = useState<boolean>(false);
  const [enderecoPrincipalEmpresa, setEnderecoPrincipalEmpresa] = useState<boolean>(false);
  const [enviarGoogleAgendaCliente, setEnviarGoogleAgendaCliente] = useState<boolean>(false);
  const [enviarGoogleAgendaResponsavel, setEnviarGoogleAgendaResponsavel] = useState<boolean>(false);
  const [instrucoesMotorista, setInstrucoesMotorista] = useState('');

  const [enviarMsgClienteCadastro, setEnviarMsgClienteCadastro] = useState({ S: "não" });
  const [enviarMsgClienteProjeto, setEnviarMsgClienteProjeto] = useState({ S: "não" });
  const [enviarMsgResposavelProjeto, setEnviarMsgResposavelProjeto] = useState({ S: "não" });
  const [enviarMsgClienteAgendamento, setEnviarMsgClienteAgendamento] = useState({ S: "não" });
  const [enviarMsgResposavelAgendamento, setEnviarMsgResposavelAgendamento] = useState({ S: "não" });
  const [enviarMsgResposavelOperacao, setEnviarMsgResposavelOperacao] = useState({ S: "não" });
  const [enviarMsgClienteOperacao, setEnviarMsgClienteOperacao] = useState({ S: "não" });

  const [mostartAlerta] = useIonAlert();
  const [localEnderecoSelecionado, setLocalEnderecoSelecionado] = useState<any>("");
  const [listaEnderecos, setListaEnderecos] = useState<any>([]);

  const [showLojasIfood, setShowLojasIfood] = useState<boolean>(false);
  const [listaLojasIfood, setListaLojasIfood] = useState<any>([]);

  const [showDetalheLojaIfood, setShowDetalheLojaIfood] = useState<boolean>(false);
  const [detalheLojaIfood, setDetalheLojaIfood] = useState<any>();

  const [showStatusLojaIfood, setShowStatusLojaIfood] = useState<boolean>(false);
  const [statusLojaIfood, setStatusLojaIfood] = useState<any>([]);

  const [showInterruptionsLojaIfood, setShowInterruptionsLojaIfood] = useState<boolean>(false);
  const [interruptionsLojaIfood, setInterruptionsLojaIfood] = useState<any>([]);

  const [showFecharLojaIfood, setShowFecharLojaIfood] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<string>("");

  const [showPausaProgramada, setShowPausaProgramada] = useState<boolean>(false);
  const [pauseTitle, setPauseTitle] = useState<any>(''); // Armazena o título da pausa programada
  const [startDate, setStartDate] = useState<any>(''); // Armazena a data de início
  const [startTime, setStartTime] = useState<any>(''); // Armazena a hora de início
  const [endDate, setEndDate] = useState<any>(''); // Armazena a data de término
  const [endTime, setEndTime] = useState<any>(''); // Armazena a hora de término

  const [showOpenHoursLojaIfood, setShowOpenHoursLojaIfood] = useState<boolean>(false);
  const [openHoursLojaIfood, setOpenHoursLojaIfood] = useState<any>([]);

  const [showAlterarHorarioLojaIfood, setShowAlterarHorarioLojaIfood] = useState<boolean>(false);




  const [showPopoverGrid, setShowPopoverGrid] = useState(false);
  const [popoverEventGrid, setPopoverEventGrid] = useState(null);
  const [itemSelecionado, setItemSelecionado] = useState<any>(null); // Variável para armazenar o item clicado

  const [showModalBlueTooth, setShowModalBlueTooth] = useState(false);


  const [idOpenHour, setIdOpenHour] = useState<any>();
  const [dayOfWeekOpenHour, setDayOfWeekOpenHour] = useState<any>();
  const [startOpenHour, setStartOpenHour] = useState<any>();
  const [durationOpenHour, setDurationOpenHour] = useState<any>();

  const openPopoverGrid = (e: any, item: any) => {
    e.persist();
    setPopoverEventGrid(e); // Seta o evento para abrir o popover
    setItemSelecionado(item); // Seta o item clicado
    setShowPopoverGrid(true); // Abre o popover
  };

  const closePopoverGrid = () => {
    setShowPopoverGrid(false); // Fecha o popover
    setPopoverEventGrid(null); // Reseta o evento
    setItemSelecionado(null); // Reseta o item selecionado
  };


  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    buscarImpressorasLocal();
    carregarConta();
    atualizarTamanhoImagem();
  });

  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaConfiguracoesCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaConfiguracoesCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setEmpresaNome(response.Item.empresaNome || '');
          setEmpresaEndereco(response.Item.empresaEndereco || '');
          setEmpresaTelefone(response.Item.empresaTelefone || '');
          setEmpresaLatitude(response.Item.empresaLatitude || '');
          setEmpresaLongitude(response.Item.empresaLongitude || '');
          setUrlLogo(response.Item.urlLogo || '');
          setCondicoesGerais(response.Item.condicoesGerais || '');

          setImpressoraLargura(response.Item.impressoraLargura || '');

          //grava no dynamo
          setImpressoraRedeHabilitar(response.Item.impressoraRedeHabilitar || false);
          setImpressoraRedeIp(response.Item.impressoraRedeIp || '');
          setImpressoraRedePorta(response.Item.impressoraRedePorta || '');

          //grava no localStorage
          const isLocalEnabled = window.localStorage.getItem("impressoraLocalHabilitar") === "true";
          setImpressoraLocalHabilitar(isLocalEnabled);
          setImpressoraLocalName(window.localStorage.getItem("impressoraLocalName") || "");

          //grava no localStorage
          const isBluetoothEnabled = window.localStorage.getItem("impressoraBluetoothHabilitar") === "true";
          setImpressoraBluetoothHabilitar(isBluetoothEnabled);
          setImpressoraBluetoothDeviceId(window.localStorage.getItem("impressoraBluetoothDeviceId") || "");

          setImpressaoAutomaticaIfood(response.Item.impressaoAutomaticaIfood || false);

          setNomeInternoMesaOuComanda(response.Item.nomeInternoMesaOuComanda || '');
          setNomeInternoMesasOuComandas(response.Item.nomeInternoMesasOuComandas || '');
          setImprimirMesaParcial(response.Item.imprimirMesaParcial || false);
          setServicoTipo(response.Item.servicoTipo || '');
          setServicoTipoPlural(response.Item.servicoTipoPlural || '');
          setNomeLinhaDoTempo(response.Item.nomeLinhaDoTempo || '');
          setTaxaServico(response.Item.taxaServico || '');
          setIdentificacaoPorNumero(response.Item.identificacaoPorNumero || false);
          setLayoutSimplificado(response.Item.layoutSimplificado || false);
          setZerarValoresVenda(response.Item.zerarValoresVenda || false);
          setEsconderValoresProjeto(response.Item.esconderValoresProjeto || false);
          setTipoAgendamentoPadrao(response.Item.tipoAgendamentoPadrao || '');
          setServicoPeriodoHoraEmpresa(response.Item.servicoPeriodoHoraEmpresa || false);
          setParametroMostrarHorario(response.Item.parametroMostrarHorario || false);
          setEnderecoPrincipalEmpresa(response.Item.enderecoPrincipalEmpresa || false);
          setEnviarGoogleAgendaCliente(response.Item.enviarGoogleAgendaCliente || false);
          setEnviarGoogleAgendaResponsavel(response.Item.enviarGoogleAgendaResponsavel || false);
          setInstrucoesMotorista(response.Item.instrucoesMotorista || '');

          setEnviarMsgClienteCadastro({ S: response.Item.enviarMsgClienteCadastro || "não" });
          setEnviarMsgClienteProjeto({ S: response.Item.enviarMsgClienteProjeto || "não" });
          setEnviarMsgResposavelProjeto({ S: response.Item.enviarMsgResposavelProjeto || "não" });
          setEnviarMsgClienteAgendamento({ S: response.Item.enviarMsgClienteAgendamento || "não" });
          setEnviarMsgResposavelAgendamento({ S: response.Item.enviarMsgResposavelAgendamento || "não" });
          setEnviarMsgResposavelOperacao({ S: response.Item.enviarMsgResposavelOperacao || "não" });
          setEnviarMsgClienteOperacao({ S: response.Item.enviarMsgClienteOperacao || "não" });

          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function atualizarTamanhoImagem() {
    var imagemLogo: any;
    imagemLogo = document.getElementById('imagemLogo');
    var img = new Image();
    img.onload = function () {
      var proporcao = img.width / img.height;
      var novaLargura = 250 * proporcao;
      imagemLogo.style.width = novaLargura + 'px';
    };
    img.src = imagemLogo.src;

  }


  const cadastrar = () => {
    setShowToast2(true);
  }


  const takePhoto = async (indice: string) => {

    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Prompt,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
    });

    var binary_string = window.atob(cameraPhoto.base64String || '');
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes.buffer)], {
      type: `image/${cameraPhoto.format}`,
    });

    const file = new File([blob], "Name", {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1102,
      useWebWorker: true,
      initialQuality: 0.5
    }
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB


      let base64 = await getBase64(compressedFile);
      await incluirFoto(indice, base64);

    } catch (error) {
      console.log(error);
    }

  };

  async function getBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }

  async function incluirFoto(indice: string, base64: any) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let parametros = {
        base64: base64
      }

      try {
        setShowLoading(true);

        const resp = await fetch(empresaAlterar.url.replace("{id}", loja.replaceAll("#", "%23")).concat("?tipo=logoempresa"),
          {
            method: empresaAlterar.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(parametros),
            credentials: 'include'
          });




        const response = await resp.json();

        console.log("response", response);
        if (response.Item) {
          setUrlLogo(response.Item);
          atualizarTamanhoImagem();

        }
        setShowLoading(false);


      } catch (error) {
        console.log("erro", error);
        setShowLoading(false);
      }
    }

    atualizar();

  };

  function buscarLocalizacao(endereco: any) {
    if (endereco.replace(/\s/g, "") == "") return;

    let enderecoTratado = endereco.toLowerCase().replace(/\s/g, "+");


    requestService(buscarEndereco.url.replace("{id}", enderecoTratado), {

      method: buscarEndereco.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response && response.Item) {
          try {
            setEmpresaLatitude(response.Item.enderecoLat);
            setEmpresaLongitude(response.Item.enderecoLng);

            setTextToast("Endereço validado com sucesso.");
            setShowToast(true);
          } catch (error) {
            console.log("error", error);
          }

        }
      },
      (error: any) => {
        console.log("e1", error);
        setTextToast(error.message);
        setShowToast(true);
        if (error.data) {
          console.log("entrou varios endereços");
          if (Array.isArray(error.data) && error.data.length > 0) {
            setListaEnderecos(error.data);
          }
        }
      }).finally(() => { setShowLoading(false); });
    ;
  }

  function readListaEnderecos() {
    return listaEnderecos.map((value: any, index: any) => (
      <IonItem
        key={"endereco" + value.place_id}
        button
        onClick={() => setLocalEnderecoSelecionado(value.formatted_address)}
        color={localEnderecoSelecionado === value.formatted_address ? 'tertiary' : ''}
      >
        <IonLabel>{value.formatted_address}</IonLabel>
      </IonItem>
    ));
  }

  const handlePortaInput = (e: any) => {
    const inputValue = e.target.value;

    // Remove qualquer caractere não numérico e limita a 4 dígitos
    const numericValue = inputValue.replace(/\D/g, '').slice(0, 4);

    setImpressoraRedePorta(numericValue);
  };

  function gerarEscPos(imprimir58mm: any = false) {
    // Função para remover acentos e cedilhas
    function removerAcentosECedilha(texto: string): string {
      return texto
        .normalize('NFD')  // Normaliza o texto para decompô-lo em caracteres base e acentos
        .replace(/[\u0300-\u036f]/g, '')  // Remove os acentos
        .replace(/ç/g, 'c')  // Substitui 'ç' por 'c'
        .replace(/Ç/g, 'C');  // Substitui 'Ç' por 'C'
    }

    function gerarTexto() {
      let texto = "";

      texto += imprimir58mm ? `********************************\n` : `**************************************\n`;
      texto += `Conexão realizada com sucesso!\n`;
      texto += imprimir58mm ? `********************************\n` : `**************************************\n`;

      return texto;
    }

    try {


      console.log("dadosImpressao1");
      // Montando o cupom com os dados no formato ESC/POS
      const texto = gerarTexto();

      // Comandos ESC/POS básicos

      const ESC = 0x1B;  // ESC
      const LF = 0x0A;   // Line Feed
      const CORTA_PAPEL = [ESC, 0x69];   // Corta Papel
      const NEGRITO_ON = [ESC, 0x45, 0x01];  // Ativar negrito
      const NEGRITO_OFF = [ESC, 0x45, 0x00]; // Desativar negrito
      const ALIMENTAR_PAPEL = [ESC, 0x64, 0x10]; // Alimentação de papel


      const dadosImpressao: any = [
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        LF,

        ...removerAcentosECedilha(texto).split("").map(c => c.charCodeAt(0)),

        LF, LF, LF, LF, LF,
        // ...ALIMENTAR_PAPEL, // Alimentação de papel
        ...CORTA_PAPEL

      ];

      // Retorna os dados como Uint8Array
      return new Uint8Array(dadosImpressao);
    } catch (error) {
      console.error('Erro ao imprimir cupom:', error);
      return null; // Retorna null em caso de erro
    }
  }

  async function closeModalModalBlueTooth() {

    setShowModalBlueTooth(false);
  }

  async function closeModalModalBlueToothSucess(deviceId: any) {

    setTextToast("Conectado ao dispositivo");
    setShowToast(true);

    // window.localStorage.setItem("impressoraBluetoothDeviceId", deviceId);

    setImpressoraBluetoothDeviceId(deviceId);
    setShowModalBlueTooth(false);
  }

  const generateTimeOptions2359 = () => {
    const times = [];
    let hour = 0;
    let minute = 0;

    // Gera opções de hora no formato HH:mm:ss, de 30 em 30 minutos
    for (let i = 0; i < 48; i++) {
      const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
      const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
      times.push(`${hourStr}:${minuteStr}:00`);

      // Incrementa os minutos em 30
      minute += 30;
      if (minute === 60) {
        minute = 0;
        hour += 1;
      }
    }

    // Adiciona a última opção de horário como "23:59"
    times.push("23:59:00");

    return times;
  };

  const generateDurationOptions = () => {
    const durations = [];
    let duration = 30; // Começando com 30 minutos
    while (duration <= 1439) { // Limite máximo de 1439 minutos (23h59)
      durations.push(duration);
      duration += 30; // Incrementa de 30 em 30 minutos
    }

    // Adiciona explicitamente 23:59:00
    durations.push(1439); // 1439 minutos correspondem a 23:59

    return durations;
  };


  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Se for 0 horas, retorna apenas os minutos
    if (hours === 0 && mins > 0) {
      return `${mins} minutos`;
    }

    // Se for 0 minutos, retorna apenas as horas
    if (mins === 0 && hours > 0) {
      return `${hours} horas`;
    }

    // Se ambos forem diferentes de 0, retorna as horas e minutos
    return `${hours} horas e ${mins} minutos`;
  };


  const handleDurationChange = (duration: number) => {
    console.log("duration", duration);
    setDurationOpenHour(duration);

    if (startOpenHour) {
      const [startHour, startMinute] = startOpenHour.split(':').map(Number);
      const startTotalMinutes = startHour * 60 + startMinute;

      const endTotalMinutes = startTotalMinutes + duration;

      // Verifica se o cálculo da duração gera um valor negativo
      console.log("startTotalMinutes", startTotalMinutes);
      console.log("endTotalMinutes", endTotalMinutes);
      // Verifica se o total de minutos ultrapassa 1440 minutos (24 horas)
      if (endTotalMinutes > 1439) {
        setDurationOpenHour(0);
        setTextToast("A duração não pode ultrapassar 23:59 horas. Verifique as informações e tente novamente.");
        setShowToast(true);
      }
    }
  };

  // Função para buscar impressoras
  const buscarImpressorasLocal = async () => {
    const url = "http://localhost:9100";

    try {
      const requestOptions: any = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      };

      const resp = await fetch(url, requestOptions);
      const result = await resp.json(); // Supondo que o retorno é um JSON com uma lista de impressoras

      console.log("result", result);
      if (result && Array.isArray(result)) {
        setImpressorasLocal(result); // Define as impressoras no estado
      } else {
        console.error("Erro: Nenhuma resposta válida recebida.");
      }
    } catch (error) {
      console.error("Erro ao obter dados:", error);
    }
  };


  function converterParaString(dadosImpressao: any) {
    return String.fromCharCode(...dadosImpressao.filter((byte: any) => byte <= 0xFF)); // Filtrando valores válidos
  }

  async function enviarImpressao(printer: any, data: any) {

    const url = "http://localhost:9100/print";

    // Montando o corpo da requisição no formato necessário
    var raw = JSON.stringify({
      "printer": printer,
      "data": data,  // Dados no formato correto (com códigos ESC/POS)
    });

    try {
      // Configurações da requisição
      const requestOptions: any = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: 'follow',
      };

      // Envia a requisição utilizando fetch
      const resp = await fetch(url, requestOptions);

      // Aguardar a resposta como texto
      const result = await resp.text();

      if (result) {
        console.log("Impressão enviada com sucesso:", result);
      } else {
        console.error("Erro: Nenhuma resposta recebida.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para a impressora:", error);
    }
  }


  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">


          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>


          <IonTitle slot="start">Configurações</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines="full">
            <IonLabel color="primary">Empresa</IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Nome</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaNome}
              maxlength={100}
              placeholder="Nome da empresa"
              onIonInput={(e: any) => setEmpresaNome(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Endereço</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={2}
              value={empresaEndereco}
              maxlength={200}
              placeholder="Endereço da empresa"
              onIonInput={(e: any) => setEmpresaEndereco(e.target.value)}
            ></IonTextarea>
            <div style={{ textAlign: 'right' }}>

            </div>
          </IonItem>
          <IonItem lines="none">
            <IonButton slot='end' size='large' onClick={() => { buscarLocalizacao(empresaEndereco); }}>Validar endereço</IonButton>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Celular</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaTelefone}
              maxlength={1300}
              placeholder="Telefone da empresa"
              onIonInput={(e: any) => setEmpresaTelefone(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            {/* Campos para localização */}
            <IonLabel position="stacked">Latitude</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaLatitude}
              maxlength={20}
              placeholder="Latitude"
              onIonInput={(e: any) => setEmpresaLatitude(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Longitude</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={empresaLongitude}
              maxlength={20}
              placeholder="Longitude"
              onIonInput={(e: any) => setEmpresaLongitude(e.target.value)}
            ></IonTextarea>
          </IonItem>

          <IonItem lines="none">

            <IonLabel position="stacked" style={{ marginBottom: '10px' }}>Logo</IonLabel>

            <IonButtons slot="end" onClick={async () => { await takePhoto(loja).catch((e) => { console.log("cancelado"); }); }}>
              <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
            </IonButtons>
            <img id="imagemLogo" src={urlLogo} style={{ height: '250px' }} alt="logo" />
          </IonItem>

          <IonItem lines="full">
            <IonLabel color="primary">Configuração impressora</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel position="stacked">Largura</IonLabel>
            <IonSelect
              value={impressoraLargura}
              placeholder="Selecione uma impressora"
              onIonChange={(e) => setImpressoraLargura(e.detail.value)}
            >
              <IonSelectOption key="largura80mm" value="80">
                80mm
              </IonSelectOption>
              <IonSelectOption key="largura58mm" value="58">
                58mm
              </IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItem lines="full" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel color="primary">Impressora local (Precisa do instalador de extensão da impressora)</IonLabel>
          </IonItem>

          <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel>Habilitar</IonLabel>

            <IonToggle slot="end"
              checked={impressoraLocalHabilitar}
              onIonChange={(e: any) => {
                const isChecked = e.detail.checked;
                setImpressoraLocalHabilitar(isChecked);
                // window.localStorage.setItem("impressoraLocalHabilitar", isChecked ? "true" : "false");
              }}
            />
          </IonItem>


          <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">Nome da impressora</IonLabel>
            <IonSelect
              value={impressoraLocalName}
              placeholder="Selecione uma impressora"
              onIonChange={(e) => setImpressoraLocalName(e.detail.value)}
            >
              {impressorasLocal?.map((impressora: any, index: any) => (
                <IonSelectOption key={index} value={impressora.Name}>
                  {impressora.Name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          {/* <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonButton size="large" slot="end" onClick={buscarImpressorasLocal}>Buscar impressoras</IonButton>
          </IonItem> */}


          <IonItem lines="none" hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} >

            <IonButton size="large" slot="end" onClick={async () => {
              if (!(!isPlatform('mobileweb') && isPlatform('mobile'))) {
                const comandosEscPos = gerarEscPos(impressoraLargura == "58" ? true : false);
                if (comandosEscPos) {
                  // Exemplo de uso
                  const resultadoString = converterParaString(comandosEscPos);
                  // const resultadoString = arrayToEscapedString(comandosEscPos);
                  //console.log(resultadoString);


                  enviarImpressao(impressoraLocalName, resultadoString);


                } else {
                  console.error('Erro ao gerar ESC/POS');
                }
              }
              else {
                setTextToast("Essa funcionalidade é somente para cmoputadores.");
                setShowToast(true);
              }
            }}>Testar conexão</IonButton>
          </IonItem>

          <IonItem lines="full" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel color="primary">Impressora Bluetooth</IonLabel>
          </IonItem>

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel>Habilitar</IonLabel>

            <IonToggle slot="end"
              checked={impressoraBluetoothHabilitar}
              onIonChange={(e: any) => {
                const isChecked = e.detail.checked;
                setImpressoraBluetoothHabilitar(isChecked);
                // window.localStorage.setItem("impressoraBluetoothHabilitar", isChecked ? "true" : "false");
              }}
            />
          </IonItem>

          {/* <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">Device Id</IonLabel>
            <div style={{ background: "#eeeeee", borderRadius: "5px", padding: "10px", marginTop: "10px", width: "100%" }}>
              <IonInput
                type="text"
                value={impressoraBluetoothDeviceId}
                maxlength={100}
                placeholder="Device Id"
                onIonInput={(e: any) => setImpressoraBluetoothDeviceId(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </IonItem> */}

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonButton size="large" slot="end" onClick={() => {
              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                setShowModalBlueTooth(true);
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }
            }}>Configurar</IonButton>

            {/* <IonButton size="large" slot="end" onClick={async () => {
              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                const services = await BleClient.getServices(impressoraBluetoothDeviceId);

                for (const service of services) {
                  console.log("Service UUID:", service.uuid);

                  for (const characteristic of service.characteristics) {
                    console.log("Characteristic UUID:", characteristic.uuid);
                  }
                }

                throw new Error("Nenhum UUID compatível encontrado");
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }
            }}>Serviços</IonButton> */}

            <IonButton size="large" slot="end" onClick={async () => {
              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                const comandosEscPos = gerarEscPos(impressoraLargura == "58" ? true : false);
                if (comandosEscPos) {

                  const PRINT_SERVICE_UUID = "000018f0-0000-1000-8000-00805f9b34fb";
                  const PRINT_CHARACTERISTIC_UUID = "00002af1-0000-1000-8000-00805f9b34fb";
                  // Fragmentar os dados em pacotes menores
                  const chunkSize = 20; // Tamanho máximo permitido por pacote BLE
                  const buffer = comandosEscPos.buffer;

                  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

                  try {
                    // Loop para enviar os dados em pacotes
                    for (let i = 0; i < buffer.byteLength; i += chunkSize) {
                      const chunk = new DataView(buffer.slice(i, Math.min(i + chunkSize, buffer.byteLength)));
                      await BleClient.write(
                        impressoraBluetoothDeviceId || '',
                        PRINT_SERVICE_UUID,
                        PRINT_CHARACTERISTIC_UUID,
                        chunk
                      );
                      await delay(50); // Atraso de 50ms entre os envios
                    }

                    setTextToast("Impressão realizada com sucesso!");
                    setShowToast(true);
                  } catch (error) {
                    console.error("Erro durante a impressão:", error);
                    setTextToast("Erro ao realizar a impressão.");
                    setShowToast(true);
                  }

                } else {
                  console.error('Erro ao gerar ESC/POS');
                }
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }
            }}>Testar conexão</IonButton>
          </IonItem>

          <IonItem lines="full" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel color="primary">Impressora de rede</IonLabel>
          </IonItem>

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel>Habilitar</IonLabel>

            <IonToggle slot="end"
              checked={impressoraRedeHabilitar}
              onIonChange={(e: any) => setImpressoraRedeHabilitar(e.detail.checked)}
            />
          </IonItem>


          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">IP</IonLabel>
            <div style={{ background: "#eeeeee", borderRadius: "5px", padding: "10px", marginTop: "10px", width: "100%" }}>
              <IonInput
                type="text"
                value={impressoraRedeIp}
                maxlength={15}
                placeholder="IP da impressora"
                onIonInput={(e: any) => setImpressoraRedeIp(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </IonItem>

          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonLabel position="stacked">Porta (9100)</IonLabel>
            <div style={{ background: "#eeeeee", borderRadius: "5px", padding: "10px", marginTop: "10px", width: "100%" }}>
              <IonInput
                type="text"
                value={impressoraRedePorta}
                maxlength={15}
                placeholder="Porta da impressora"
                onIonInput={(e: any) => setImpressoraRedePorta(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </IonItem>




          <IonItem lines="none" hidden={!(!isPlatform('mobileweb') && isPlatform('mobile'))} >
            <IonButton size="large" slot="end" onClick={() => {

              if (!isPlatform('mobileweb') && isPlatform('mobile')) {
                const ipImpressora = impressoraRedeIp;
                // const portaImpressora = impressoraRedePorta ? parseInt(impressoraRedePorta, 10) : 0impressoraRedePorta;

                const conectarEEnviar = () => {
                  // Cria o socket TCP
                  window.chrome.sockets.tcp.create({}, (createInfo) => {
                    const socketId = createInfo.socketId;

                    // Define o timeout para mostrar a mensagem de erro após 3 segundos
                    const timeoutId = setTimeout(() => {
                      setTextToast("Erro ao conectar.");
                      setShowToast(true);
                      window.chrome.sockets.tcp.close(socketId);
                    }, 3000); // 3 segundos

                    // Tenta conectar ao IP e porta especificados
                    window.chrome.sockets.tcp.connect(socketId, ipImpressora, parseInt(impressoraRedePorta, 10), (result) => {
                      if (result === 0) {
                        // Conexão bem-sucedida, cancela o timeout
                        clearTimeout(timeoutId);

                        console.log('Conexão bem-sucedida! Enviando dados...');

                        const comandosEscPos = gerarEscPos(impressoraLargura == "58" ? true : false);
                        if (comandosEscPos) {
                          window.chrome.sockets.tcp.send(socketId, comandosEscPos.buffer, (sendInfo) => {
                            console.log('Comando enviado com sucesso:', sendInfo);
                            window.chrome.sockets.tcp.close(socketId);
                          });
                        } else {
                          console.error('Erro ao gerar ESC/POS');
                        }
                      } else {
                        // Conexão falhou, cancela o timeout e mostra mensagem de erro
                        clearTimeout(timeoutId);
                        console.error(`Erro ao conectar à impressora. Verifique a conexão.`);
                        setTextToast("Erro ao conectar.");
                        setShowToast(true);
                        window.chrome.sockets.tcp.close(socketId);
                      }
                    });
                  });
                };


                // Chame a função com o URL do HTML que você deseja imprimir
                conectarEEnviar();
              }
              else {
                setTextToast("Essa funcionalidade é somente para celulares e tablets.");
                setShowToast(true);
              }


            }}>Testar conexão</IonButton>
          </IonItem>

          <IonItem lines="full">
            <IonLabel color="primary">iFood</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Impressão automática de pedidos</IonLabel>

            <IonToggle slot="end"
              checked={impressaoAutomaticaIfood}
              onIonChange={(e: any) => {
                const isChecked = e.detail.checked;
                setImpressaoAutomaticaIfood(isChecked);
                // window.localStorage.setItem("impressoraLocalHabilitar", isChecked ? "true" : "false");
              }}
            />
          </IonItem>

          {/* <IonItem lines="none">
            <IonButton size="large" slot="end" onClick={() => {
              getUserCode();
              setAtivarIfood1(true);
            }}>Ativar</IonButton>

            <IonButton size="large" slot="end" onClick={() => {
              getMerchants();
            }}>Detalhes</IonButton> */}
            {/* <IonButton size="large" slot="end" onClick={() => {
              try {
                console.log("getEvents loja", loja);
                const id = loja.replace(/#/g, '%23'); // Escapa o ID da loja

                requestService(
                  apiIfoodEvents.url.replace("{id}", id),
                  {
                    method: apiIfoodEvents.method,
                    headers: [["token", window.localStorage.getItem("token")]],
                    credentials: 'include',
                  },
                  history,
                  (response: any) => {
                    console.log(response);

                    if (response) {

                      Toast.show({
                        text: "Integrado com sucesso.",
                        position: "center",
                        duration: "long"
                      });

                    }
                  },
                  (error: any) => {
                    console.log(error);
                    if (error.message) {
                      Toast.show({
                        text: error.message,
                        position: "center",
                        duration: "long"
                      });
                    }
                  }
                );
              } catch (error) {
                console.error("Integração não foi realizada.", error);
              }
            }}>Validar integração</IonButton> */}
          {/* </IonItem> */}

          {/* <IonItem lines="full">
            <IonLabel color="primary">Cadastro</IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteCadastro.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteCadastro({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem> */}

{/* 
          <IonItem lines="full">
            <IonLabel color="primary">Integração Mercado Pago - Point</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonButton size="large" slot="end" onClick={() => {
              setMercadoPago1(true);
            }}>Ativar</IonButton>

            <IonButton size="large" slot="end" onClick={() => {
              getDevices();
            }}>Detalhes</IonButton>
          </IonItem> */}



          <IonItem lines="full">
            <IonLabel color="primary">Vendas</IonLabel>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Condições Gerais</IonLabel>
            <IonTextarea

              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={6}
              value={condicoesGerais}
              maxlength={1500}
              placeholder="Condições gerais para apresentar no rodapé do orçamento"
              onIonInput={(e: any) => setCondicoesGerais(e.target.value)}
            ></IonTextarea>
          </IonItem>


          <IonItem lines="full">
            <IonLabel color="primary">{(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Imprimir Parcial</IonLabel>

            <IonToggle slot="end"
              checked={imprimirMesaParcial}
              onIonChange={(e: any) => setImprimirMesaParcial(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">

            {/* Campos para serviços */}
            <IonLabel position="stacked">Nome Interno</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={nomeInternoMesaOuComanda}
              maxlength={50}
              placeholder="Nome Interno"
              onIonInput={(e: any) => setNomeInternoMesaOuComanda(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome Interno no Plural</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={nomeInternoMesasOuComandas}
              maxlength={50}
              placeholder="Nome Interno no Plural"
              onIonInput={(e: any) => setNomeInternoMesasOuComandas(e.target.value)}
            ></IonTextarea>
          </IonItem>

          <IonItem lines="full">
            <IonLabel color="primary">Processo</IonLabel>
          </IonItem>
          <IonItem lines="none">

            {/* Campos para serviços */}
            <IonLabel position="stacked">Nome</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={servicoTipo}
              maxlength={50}
              placeholder="Nome do processo"
              onIonInput={(e: any) => setServicoTipo(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome no Plural</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={servicoTipoPlural}
              maxlength={50}
              placeholder="Nome do processo no plural"
              onIonInput={(e: any) => setServicoTipoPlural(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome do Workflow</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={nomeLinhaDoTempo}
              maxlength={50}
              placeholder="Nome do Workflow"
              onIonInput={(e: any) => setNomeLinhaDoTempo(e.target.value)}
            ></IonTextarea>

          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Taxa</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "borderRadius": "5px", "padding": "10px" }}
              rows={1}
              value={taxaServico}
              maxlength={50}
              placeholder="% - Taxa de Serviço"
              onIonInput={(e: any) => setTaxaServico(e.target.value)}
            ></IonTextarea>

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Identificação por Número</IonLabel>

            <IonToggle slot="end"
              checked={identificacaoPorNumero}
              onIonChange={(e: any) => setIdentificacaoPorNumero(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Layout Simplificado (sem Workflow)</IonLabel>

            <IonToggle slot="end"
              checked={layoutSimplificado}
              onIonChange={(e: any) => setLayoutSimplificado(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">

            {/* Campos para os valores específicos */}
            <IonLabel>Zerar valores da venda ao criar o Processo</IonLabel>

            <IonToggle slot="end"
              checked={zerarValoresVenda}
              onIonChange={(e: any) => setZerarValoresVenda(e.detail.checked)}
            />

          </IonItem>

          <IonItem lines="none">
            <IonLabel>Esconder Valores</IonLabel>

            <IonToggle slot="end"
              checked={esconderValoresProjeto}
              onIonChange={(e: any) => setEsconderValoresProjeto(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteProjeto.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteProjeto({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>

          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelProjeto.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelProjeto({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


          <IonItem lines="full">
            <IonLabel color="primary">Agenda</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Tipo de Agendamento Padrão</IonLabel>
            <IonSelect placeholder="Selecione" slot="end" value={tipoAgendamentoPadrao} onIonChange={(e) => { setTipoAgendamentoPadrao(e.detail.value || "") }}>
              <IonSelectOption value="entrega">entrega</IonSelectOption>
              <IonSelectOption value="serviço">serviço</IonSelectOption>
              <IonSelectOption value="reunião">reunião</IonSelectOption>
              <IonSelectOption value="consulta">consulta</IonSelectOption>
              <IonSelectOption value="visita técnica">visita técnica</IonSelectOption>
              <IonSelectOption value="reserva">reserva</IonSelectOption>
              <IonSelectOption value="check in">check in</IonSelectOption>
              <IonSelectOption value="check out">check out</IonSelectOption>
            </IonSelect>

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Habilitar Período</IonLabel>

            <IonToggle slot="end"
              checked={servicoPeriodoHoraEmpresa}
              onIonChange={(e: any) => setServicoPeriodoHoraEmpresa(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Mostrar horário (sim) / período (não)</IonLabel>
            <IonToggle slot="end"
              checked={parametroMostrarHorario}
              onIonChange={(e: any) => setParametroMostrarHorario(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Endereço empresa (sim) / cliente (não)</IonLabel>
            <IonToggle slot="end"
              checked={enderecoPrincipalEmpresa}
              onIonChange={(e: any) => setEnderecoPrincipalEmpresa(e.detail.checked)}
            />

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Enviar Google Agenda Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarGoogleAgendaCliente}
              onIonChange={(e: any) => setEnviarGoogleAgendaCliente(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            <IonLabel>Enviar Google Agenda Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarGoogleAgendaResponsavel}
              onIonChange={(e: any) => setEnviarGoogleAgendaResponsavel(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteAgendamento.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteAgendamento({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>

          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelAgendamento.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelAgendamento({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>



          <IonItem lines="full">
            <IonLabel color="primary">Operação</IonLabel>
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteOperacao.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteOperacao({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelOperacao.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelOperacao({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


















        </IonList>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonAlert isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          header={'Alteração de cadastro'}
          message={'Você <strong>confirma</strong> a alteração?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                window.localStorage.setItem("impressoraLocalHabilitar", impressoraLocalHabilitar ? "true" : "false");
                window.localStorage.setItem("impressoraLocalName", impressoraLocalName);

                window.localStorage.setItem("impressoraBluetoothHabilitar", impressoraBluetoothHabilitar ? "true" : "false");
                window.localStorage.setItem("impressoraBluetoothDeviceId", impressoraBluetoothDeviceId);

                const objectRequest = {
                  empresaNome,
                  empresaEndereco,
                  empresaTelefone,
                  empresaLatitude,
                  empresaLongitude,
                  urlLogo,
                  condicoesGerais,
                  impressoraLargura,
                  impressoraRedeHabilitar,
                  impressoraRedeIp,
                  impressoraRedePorta: impressoraRedePorta ? parseInt(impressoraRedePorta, 10) : 0,
                  // impressoraBluetoothHabilitar,
                  // impressoraBluetoothDeviceId,
                  impressaoAutomaticaIfood,
                  nomeInternoMesaOuComanda,
                  nomeInternoMesasOuComandas,
                  imprimirMesaParcial,
                  servicoTipo,
                  servicoTipoPlural,
                  nomeLinhaDoTempo,
                  taxaServico,
                  identificacaoPorNumero,
                  layoutSimplificado,
                  zerarValoresVenda,
                  esconderValoresProjeto,
                  tipoAgendamentoPadrao,
                  servicoPeriodoHoraEmpresa,
                  parametroMostrarHorario,
                  enderecoPrincipalEmpresa,
                  enviarGoogleAgendaCliente,
                  enviarGoogleAgendaResponsavel,
                  instrucoesMotorista,
                  enviarMsgClienteCadastro: enviarMsgClienteCadastro.S,
                  enviarMsgClienteProjeto: enviarMsgClienteProjeto.S,
                  enviarMsgResposavelProjeto: enviarMsgResposavelProjeto.S,
                  enviarMsgClienteAgendamento: enviarMsgClienteAgendamento.S,
                  enviarMsgResposavelAgendamento: enviarMsgResposavelAgendamento.S,
                  enviarMsgResposavelOperacao: enviarMsgResposavelOperacao.S,
                  enviarMsgClienteOperacao: enviarMsgClienteOperacao.S
                };
                let id = loja;

                console.log("id", id)

                requestService(
                  empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?tipo=configuracoesempresa"),
                  {
                    method: empresaAlterar.method,
                    body: JSON.stringify(objectRequest),
                    headers: [
                      ["token", window.localStorage.getItem("token")]
                    ],
                    credentials: 'include'
                  },
                  history,
                  (response: any) => {
                    console.log(response);
                    if (response) {
                      setTextToast("Alteração realizado com sucesso.");
                      setShowSucesso(true);

                    } else if (response.status === 400) {
                      console.log(response);
                      setTextToast("Ocorreu um erro ao efetuar a alteração.");
                      setShowToast(true);

                    }
                    setShowLoading(false);
                  },
                  (error: any) => {
                    console.log(error);
                    setTextToast("Ocorreu um erro ao efetuar a alteração.");
                    setShowToast(true);
                    setShowLoading(false);
                  })
              }
            }
          ]}
        />

        <IonModal isOpen={(Array.isArray(listaEnderecos) && listaEnderecos.length > 0)} onDidDismiss={() => { setListaEnderecos([]); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Selecione um endereço</IonTitle>
              <IonButtons slot="start" onClick={() => setListaEnderecos([])}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListaEnderecos()}
            </IonList>
          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {

              mostartAlerta({
                backdropDismiss: false,
                header: 'Número e complemento',
                message: 'Adicione Número e complemento',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    placeholder: 'Digite aqui o número e complemento',
                  }],
                buttons: ['Cancel', {
                  text: 'OK', handler: (d) => {
                    setEmpresaEndereco(localEnderecoSelecionado + ", " + d.mensagem);
                    buscarLocalizacao(localEnderecoSelecionado + ", " + d.mensagem);
                  }
                }],
                onDidDismiss: (e) => { console.log('did dismiss'); }
              })

              setListaEnderecos([]);
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showModalBlueTooth} onDidDismiss={() => { setShowModalBlueTooth(false); }}>
          <Bluetooth closeAction={closeModalModalBlueToothSucess} closeActionSimple={closeModalModalBlueTooth}></Bluetooth>
        </IonModal>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e: any) => { setShowSucesso(false); history.goBack(); }}
          message={'Alterações salvas com sucesso!'}
          duration={3000}
          animated={false}
        />
      </IonContent >
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit" hidden={acessos?.configuracoes != 'edicao'}>Salvar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage >
  );
};

export default ContaAlt;
