import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonReorder, IonReorderGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { add, arrowBack, pencil, reorderTwo, trash } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { empresaAlterar, ordernarItemFormaPagamentoEmpresa, removerFormaPagamentoEmpresa, requestService } from '../../../../../Utils/Services';
import "./FormaPagamento.css";

type Props = {
  onDismiss: () => void;
};

const FormaPagamento: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [listaFormaPagamento, setListaFormaPagamento] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [index, setIndex] = useState("");
  const [nome, setNome] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");


  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [isDisabled, setIsDisabled] = useState(true);

  const inputRef = useRef<any>(null);
  const [tipo, setFormaPagamento] = useState('');

  const opcoesPagamento = [
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Pix',
    'Vale Refeição',
    'Vale Alimentação',
    'Mercado Pago',
    'InfinitePay'

  ];

  const [mostartAlerta] = useIonAlert();

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    setListaFormaPagamento(dadosEmpresa.formaPagamento || []);
  });

  function deletarFormaPagamento(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delFormaPagamento = async () => {
      const resp = await fetch(removerFormaPagamentoEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerFormaPagamentoEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'index': indice }),
          credentials: 'include'
        });
      const response = await resp.json();

      if (response) {
        setListaFormaPagamento(response.message.Attributes.formaPagamento || []);
        dadosEmpresa.tipo = response.message.Attributes.formaPagamento;
        setDadosEmpresa(dadosEmpresa);


        console.log("retorno", response);
        setTextToast("Forma de pagamento removida com sucesso.");
        setShowToast(true);
      }
      // carregarConta();
    }

    delFormaPagamento();

  }

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    //console.log('Before complete', listaItemCatalogoProdutos);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    setListaFormaPagamento(event.detail.complete(listaFormaPagamento));

    // After complete is called the items will be in the new order
    //console.log('After complete', listaItemCatalogoProdutos);


    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const ordItemFormaPagamento = async () => {
      const resp = await fetch(ordernarItemFormaPagamentoEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: ordernarItemFormaPagamentoEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'formaPagamento': listaFormaPagamento }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      console.log("retorno ordenar", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Ordenação alterada com sucesso.");
        setShowToast(true);
        setListaFormaPagamento(retorno.Item?.Attributes?.formaPagamento || []);
        dadosEmpresa.tipo = retorno.Item?.Attributes?.formaPagamento;
        setDadosEmpresa(dadosEmpresa);

      }
      else {
        setTextToast("Ocorreu um erro ao ordernar.");
        setShowToast(true);
      }
    }

    ordItemFormaPagamento();

  }


  const loadFormaPagamento = () => {

    console.log();
    if (listaFormaPagamento == undefined) return;

    if (listaFormaPagamento.length === 0) {
      return (

        <IonItem lines="full" key={"semAgenda"}>

          <IonLabel>
            <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhuma forma de pagamento cadastrada</strong></h2>
          </IonLabel>

        </IonItem>


      );
    }



    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonReorderGroup disabled={isDisabled} onIonItemReorder={handleReorder}>
          {listaFormaPagamento.map((value: any, index: any) => (
            <IonItem lines="full" key={index}>
              {/* <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}>
                <img id="imagem" style={{ objectFit: 'cover', width: "50px", height: "50px", borderRadius: '5px' }} src={value.urlImagem || imgDefaultImage}></img>
              </IonLabel> */}

              {/* <IonLabel style={{ fontSize: "12px" }}>{value.codigo}</IonLabel> */}
              <IonLabel style={{ fontSize: "12px" }}>{value.nome}</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>{value.tipo}</IonLabel>
              <IonLabel style={{ fontSize: "12px", maxWidth: "100px" }}></IonLabel>
              <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonReorder slot="end"></IonReorder>
              </div>

              <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                <IonButtons>
                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      setIndex(index);
                      setNome(value.nome);
                      setFormaPagamento(value.tipo);

                      console.log("value", value);

                      setShowDetalhes(true);

                    }}>
                    <IonIcon color="primary" icon={pencil} />
                  </IonButton>


                  <IonButton hidden={!isDisabled || acessos?.configuracoes != 'edicao'}
                    onClick={() => {
                      mostartAlerta({
                        backdropDismiss: false,
                        header: 'Remover item',
                        message: 'Deseja <strong>remover</strong>?',
                        buttons: [
                          {
                            text: 'Cancelar',
                            role: 'cancel',
                          },
                          {
                            text: 'Confirmar',
                            cssClass: 'primary',
                            handler: () => {
                              deletarFormaPagamento(index);
                            }
                          }
                        ],
                        onDidDismiss: (e) => console.log('did dismiss'),
                      })
                    }}>
                    <IonIcon color="primary" icon={trash} />
                  </IonButton>

                </IonButtons>


              </div>

            </IonItem>
          ))}
        </IonReorderGroup>
      </>
    );
  }


  const validate = () => {
    let isValid = true;
    let errors: Array<{ message: string }> = [];

    if (!tipo) {
      isValid = false;
      errors.push({ message: `Tipo não pode estar vazio.` });
    }

    if (!nome) {
      isValid = false;
      errors.push({ message: `Nome não pode estar vazio.` });
    }



    return { isValid, errors };
  };


  async function salvar() {

    const { isValid, errors } = validate();

    if (!isValid && errors.length > 0) {
      console.log('Erros de validação:', errors);

      // Mapeia o array de erros e retorna um array de strings para o IonToast
      const errorMessages: any = errors.map((error) => `${error.message}`);
      setTextToast(errorMessages.join('<br>'));
      setShowToast(true);
      return;
    }

    const objectRequest = {
      index: index,
      formaPagamento: {
        nome: nome,
        tipo: tipo
      }
    }
    console.log("objectRequest", objectRequest);

    let id = loja;

    console.log("id", id);

    await requestService(
      empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?formapagamento=sim"),
      {
        method: empresaAlterar.method,
        body: JSON.stringify(objectRequest),
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
      history,
      (response: any) => {
        console.log(response);
        if (response) {
          setTextToast("Cadastro realizado com sucesso.");
          setShowToast(true);

          console.log("response", response);

          setListaFormaPagamento(response.message.Attributes.formaPagamento || []);
          dadosEmpresa.formaPagamento = response.message.Attributes.formaPagamento;
          setDadosEmpresa(dadosEmpresa);

          //carregarConta();
          setShowDetalhes(false);

        } else if (response.status === 400) {
          console.log(response);
          setTextToast("Ocorreu um erro ao efetuar o cadastro.");
          setShowToast(true);

        }
        setShowLoading(false);
      },
      (error: any) => {
        console.log(error);
        setTextToast("Ocorreu um erro ao efetuar o cadastro.");
        setShowToast(true);
        setShowLoading(false);
      })


  }

  return (

    <IonPage className="Tipo">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Forma de pagamento</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonList>
            <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>
              {/* <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}></IonLabel> */}
              <IonLabel style={{ fontSize: "12px" }}>Nome</IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Tipo</IonLabel>

              <IonLabel style={{ textAlign: "right", maxWidth: "100px" }}>
                <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                  onClick={() => setIsDisabled((current) => !current)}>
                  <IonIcon color="medium" icon={reorderTwo}></IonIcon>
                </IonBadge>
              </IonLabel>
            </IonItem>
          </IonList>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndex("");
            setNome("");
            setFormaPagamento("");
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonList>
          {loadFormaPagamento()}

        </IonList>

        <IonModal onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
          setIndex("");
          setNome("");
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Nova forma de pagamento</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>







              <IonGrid>

                <IonRow>
                  <IonCol>
                    <IonList>
                      <IonItem lines="none">
                        <IonLabel position="stacked">Tipo*</IonLabel>
                        <IonSelect
                          placeholder="Selecione"
                          value={tipo}
                          onIonChange={(e) => {
                            setFormaPagamento(e.detail.value);
                          }}
                        >
                          {opcoesPagamento.map((opcao, index) => (
                            <IonSelectOption key={index} value={opcao}>
                              {opcao}
                            </IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonList>

                      <IonItem lines="none">
                        <IonLabel position="stacked">Nome*</IonLabel>
                        <IonInput placeholder="Digite a forma " value={nome} ref={(ref) => inputRef.current = ref} autoCorrect='true' autoCapitalize='true' required onIonInput={(e: any) => setNome(e.target.value)}></IonInput>
                      </IonItem>
                    </IonList>
                  </IonCol>
                </IonRow>






              </IonGrid>
            </IonContent>

            <IonFooter className="ion-no-border">
              <IonToolbar>
                <IonButton onClick={() => salvar()} expand="block" type="submit">Salvar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage>
  );


};

export default FormaPagamento;