// import '@capacitor-community/http';
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRadio, IonRadioGroup, IonRow, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { alertOutline, arrowBack, closeOutline, copy, ellipsisVertical, informationCircleOutline, listOutline, pencilOutline, timerOutline, trashOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiIfoodAouthUserCode, apiIfoodAouthUserToken, apiIfoodDeleteMerchantsInterruptions, apiIfoodMerchants, apiIfoodMerchantsDetails, apiIfoodMerchantsInterruptions, apiIfoodMerchantsOpeningHours, apiIfoodMerchantsStatus, apiIfoodPostMerchantsInterruptions, apiIfoodPutMerchantsOpeningHours, apiMercadoPagoBuscarConfiguracao, apiMercadoPagoCriarConfiguracao, apiMercadoPagoDevices, apiMercadoPagoOperationMode, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './Integracoes.css';
import iconIfood from "./imgs/ifoodBigIcon.svg";
import iconMercadopago from "./imgs/mercadopagoIcon.svg";

type Props = {
  abrirMenu: boolean;
};

const Integracoes: React.FC<Props> = (props: Props) => {

  const history = useHistory();

  const [showToast2, setShowToast2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [showLoading, setShowLoading] = useState(true);
  const [showSucesso, setShowSucesso] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [mostartAlerta] = useIonAlert();

  const [ativarIfood1, setAtivarIfood1] = useState<boolean>(false);

  const [mercadoPago1, setMercadoPago1] = useState<boolean>(false);
  const [mercadoPago2, setMercadoPago2] = useState<boolean>(false);

  const [mercadoPagoAccessToken, setMercadoPagoAccessToken] = useState<any>();
  const [listaMercadoPagoDevices, setListaMercadoPagoDevices] = useState<any>([]);

  const [userCode, setUserCode] = useState<any>();
  const [authorizationCodeVerifier, setAuthorizationCodeVerifier] = useState<any>();
  const [ativarIfood2, setAtivarIfood2] = useState<boolean>(false);
  const [authorizationCode, setAuthorizationCode] = useState<any>();


  const [showLojasIfood, setShowLojasIfood] = useState<boolean>(false);
  const [listaLojasIfood, setListaLojasIfood] = useState<any>([]);

  const [showDetalheLojaIfood, setShowDetalheLojaIfood] = useState<boolean>(false);
  const [detalheLojaIfood, setDetalheLojaIfood] = useState<any>();

  const [showStatusLojaIfood, setShowStatusLojaIfood] = useState<boolean>(false);
  const [statusLojaIfood, setStatusLojaIfood] = useState<any>([]);

  const [showInterruptionsLojaIfood, setShowInterruptionsLojaIfood] = useState<boolean>(false);
  const [interruptionsLojaIfood, setInterruptionsLojaIfood] = useState<any>([]);

  const [showFecharLojaIfood, setShowFecharLojaIfood] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<string>("");

  const [showPausaProgramada, setShowPausaProgramada] = useState<boolean>(false);
  const [pauseTitle, setPauseTitle] = useState<any>(''); // Armazena o título da pausa programada
  const [startDate, setStartDate] = useState<any>(''); // Armazena a data de início
  const [startTime, setStartTime] = useState<any>(''); // Armazena a hora de início
  const [endDate, setEndDate] = useState<any>(''); // Armazena a data de término
  const [endTime, setEndTime] = useState<any>(''); // Armazena a hora de término

  const [showOpenHoursLojaIfood, setShowOpenHoursLojaIfood] = useState<boolean>(false);
  const [openHoursLojaIfood, setOpenHoursLojaIfood] = useState<any>([]);

  const [showAlterarHorarioLojaIfood, setShowAlterarHorarioLojaIfood] = useState<boolean>(false);




  const [showPopoverGrid, setShowPopoverGrid] = useState(false);
  const [popoverEventGrid, setPopoverEventGrid] = useState(null);
  const [itemSelecionado, setItemSelecionado] = useState<any>(null); // Variável para armazenar o item clicado


  const [idOpenHour, setIdOpenHour] = useState<any>();
  const [dayOfWeekOpenHour, setDayOfWeekOpenHour] = useState<any>();
  const [startOpenHour, setStartOpenHour] = useState<any>();
  const [durationOpenHour, setDurationOpenHour] = useState<any>();

  const openPopoverGrid = (e: any, item: any) => {
    e.persist();
    setPopoverEventGrid(e); // Seta o evento para abrir o popover
    setItemSelecionado(item); // Seta o item clicado
    setShowPopoverGrid(true); // Abre o popover
  };

  const closePopoverGrid = () => {
    setShowPopoverGrid(false); // Fecha o popover
    setPopoverEventGrid(null); // Reseta o evento
    setItemSelecionado(null); // Reseta o item selecionado
  };


  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarConfiguracoesIntegracoes();
  });

  function carregarConfiguracoesIntegracoes() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(apiMercadoPagoBuscarConfiguracao.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: apiMercadoPagoBuscarConfiguracao.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          if (response.Item) {
            setMercadoPagoAccessToken(response.Item.accessToken);
          }
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          // history.replace("/login/");
          setShowLoading(false);
        });

    }

    consultar();
  }

  const cadastrar = () => {
    setShowToast2(true);
  }




  function getButtonsGrid(value: any) {

    let resultado: any = [];

    if (acessos?.configuracoes == 'edicao') {


      resultado.push(
        {
          text: 'Detalhes',
          disabled: false,
          icon: listOutline,
          handler: () => {
            getMerchantsDetail();
          }
        });


      resultado.push(
        {
          text: 'Status',
          disabled: false,
          icon: informationCircleOutline,
          handler: () => {
            getMerchantsStatus();
          }
        });
      resultado.push(
        {
          text: 'Interrupções',
          disabled: false,
          icon: alertOutline,
          handler: () => {
            getMerchantsInterruptions();
          }
        });

      resultado.push(
        {
          text: 'Horários',
          disabled: false,
          icon: timerOutline,
          handler: () => {
            getMerchantsOpenHours();
          }
        });
    }


    resultado.push(
      {
        text: 'Cancelar',
        disabled: false,
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });


    return resultado;
  }

  function readListalojasIfood() {
    return (
      <>
        <IonPopover
          isOpen={showPopoverGrid}
          event={popoverEventGrid}
          onDidDismiss={closePopoverGrid}
        >
          <IonList>
            {getButtonsGrid(itemSelecionado).map((button: any, btnIndex: number) => (
              <IonItem key={btnIndex} lines="none" disabled={button.disabled} button onClick={() => {
                if (button.handler) button.handler();
                closePopoverGrid(); // Fecha o popover após a ação
              }}>
                <IonIcon icon={button.icon} slot="start" />
                <IonLabel>{button.text}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonPopover>


        {listaLojasIfood.map((value: any, index: any) => (

          <IonItem
            key={"lojasifood" + value.id}
            button
            onClick={() => window.localStorage.setItem("merchantIdIfood", value.id)}
            color={window.localStorage.getItem("merchantIdIfood") === value.id ? 'tertiary' : ''}
          >
            <IonLabel>{value.name}</IonLabel>
            <div style={{ position: "absolute", right: "6px", zIndex: 9999999, textAlign: "right" }}>

              <IonButtons slot="end">
                <IonButton color="primary" onClick={(e) => openPopoverGrid(e, value)}>
                  <IonIcon slot="icon-only" icon={ellipsisVertical} />
                </IonButton>
              </IonButtons>

            </div>
          </IonItem>
        ))}
      </>
    );


  }

  function readListaDevices() {
    return (
      <>

        {listaMercadoPagoDevices.map((value: any, index: any) => (

          <IonItem
            key={"lojasifood" + value.id}
            button
            onClick={() => window.localStorage.setItem("deviceIdMercadoPago", value.id)}
            color={window.localStorage.getItem("deviceIdMercadoPago") === value.id ? 'tertiary' : ''}
          >
            <IonLabel>{value.id}</IonLabel>
            <div style={{ position: "absolute", right: "6px", zIndex: 9999999, textAlign: "right" }}>

              <IonButtons slot="end">
                <IonButton color="primary" onClick={(e) => {
                  patchOperationMode(value.id, value.operating_mode == "PDV" ? "STANDALONE" : "PDV");
                }}>
                  {value.operating_mode == "PDV" ? "Desabilitar PDV" : "Habilitar PDV"}
                </IonButton>
              </IonButtons>

            </div>
          </IonItem>
        ))}
      </>
    );


  }


  function getUserCode() {

    const consultar = async () => {

      setShowLoading(true);

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(apiIfoodAouthUserCode.url, {
        method: apiIfoodAouthUserCode.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);
          setUserCode(response.data.userCode || '');
          setAuthorizationCodeVerifier(response.data.authorizationCodeVerifier || '');
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }


  function getTokenIfood() {

    const consultar = async () => {

      setShowLoading(true);

      console.log("aasasasasa", loja);
      let id = loja;

      const objectRequest = {
        authorizationCode,
        authorizationCodeVerifier,
        empresaid: loja
      }

      requestService(apiIfoodAouthUserToken.url, {
        method: apiIfoodAouthUserToken.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objectRequest),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);

          setTextToast("Integração realizada com sucesso.");
          setShowToast(true);

          window.localStorage.setItem("integradoIfood", "true");

          getMerchants();

          setShowLoading(false);
          setAtivarIfood2(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function getMerchants() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(apiIfoodMerchants.url.replace("{id}", id.replace(/#/g, '%23')), {
        method: apiIfoodMerchants.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {


            // Verifica se "merchantIdIfood" está armazenado no localStorage
            let merchantId: any = window.localStorage.getItem("merchantIdIfood");

            if (!merchantId) {
              // Caso não esteja definido, verifica se há itens em `listaLojasIfood`
              if (listaLojasIfood && listaLojasIfood.length > 0) {
                // Define o ID do primeiro item da lista no localStorage
                merchantId = listaLojasIfood[0].id;
                window.localStorage.setItem("merchantIdIfood", merchantId);
                console.log("merchantIdIfood definido como:", merchantId);
              } else {
                console.error("A lista de lojas está vazia ou não definida.");
              }
            } else {
              console.log("merchantIdIfood já definido como:", merchantId);
            }



            setListaLojasIfood(response.data);
            setShowLojasIfood(true);

            // window.localStorage.setItem("merchantIdIfood", response.data[0].id);

            // setTextToast("Integração validada com sucesso para empresa " + response.data[0].name + ".");
            // setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          setTextToast(error.message);
          setShowToast(true);
        });

    }

    consultar();
  }

  function getMerchantsDetail() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsDetails.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsDetails.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setDetalheLojaIfood(response.data);
            setShowDetalheLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }


  function getMerchantsStatus() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsStatus.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsStatus.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setStatusLojaIfood(response.data);
            setShowStatusLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function getMerchantsInterruptions() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setInterruptionsLojaIfood(response.data);
            setShowInterruptionsLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function getMerchantsOpenHours() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);
      requestService(apiIfoodMerchantsOpeningHours.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodMerchantsOpeningHours.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data) {
            setOpenHoursLojaIfood(response.data);
            setShowOpenHoursLojaIfood(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function putMerchantsOpenHours() {

    const enviar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);


      let shiftsAlterado = openHoursLojaIfood.shifts.map((item: any) => {
        // Verifica se o item corresponde ao 'dayOfWeek' fornecido
        if (item.id === idOpenHour) {
          // Atualiza o 'start' e 'duration' com os valores fornecidos
          return {
            ...item,
            dayOfWeek: dayOfWeekOpenHour,
            start: startOpenHour,
            duration: durationOpenHour, // Ajusta a duração para 1439 se for 1440
          };
        }
        // Retorna os itens sem alteração se o 'dayOfWeek' não for o mesmo
        return item;
      });

      let params = {
        storeId: window.localStorage.getItem("merchantIdIfood") || "",
        shifts: shiftsAlterado,
      };

      // Verifica se o 'idOpenHour' foi encontrado no array
      const itemExists = openHoursLojaIfood.shifts.some((item: any) => item.id === idOpenHour);

      // Se o item não for encontrado, adiciona um novo item
      if (!itemExists) {
        shiftsAlterado.push({
          id: idOpenHour, // Usando o idOpenHour fornecido para o novo item
          dayOfWeek: dayOfWeekOpenHour,
          start: startOpenHour,
          duration: durationOpenHour, // Ajusta a duração para 1439 se for 1440
        });
      }

      requestService(apiIfoodPutMerchantsOpeningHours.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPutMerchantsOpeningHours.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response", response);
          if (response.statusCode == 201 || response.statusCode == 200) {

            setTextToast("Horário alterado com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setShowAlterarHorarioLojaIfood(false);

            setTimeout(() => {
              getMerchantsOpenHours();
            }, 3000);

          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    enviar();

  }

  function putMerchantsOpenHoursRemover(idRemover: any) {

    const enviar = async () => {
      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      // Filtra o array para remover o item com id igual ao idOpenHour
      let shiftsAlterado = openHoursLojaIfood.shifts.filter((item: any) => item.id !== idRemover);

      // Monta o objeto params com o array alterado
      let params = {
        storeId: window.localStorage.getItem("merchantIdIfood") || "",
        shifts: shiftsAlterado,
      };

      console.log("shiftsAlterado", shiftsAlterado);


      requestService(apiIfoodPutMerchantsOpeningHours.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPutMerchantsOpeningHours.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log("response", response);
          if (response.statusCode == 201 || response.statusCode == 200) {

            setTextToast("Horário removido com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setShowAlterarHorarioLojaIfood(false);

            setTimeout(() => {
              getMerchantsOpenHours();
            }, 3000);

          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    enviar();

  }

  function deleteMerchantsInterruptions(interruptionId: any) {

    const enviar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      let params = {
        time: selectedTime,
        description: selectedReason
      };

      requestService(apiIfoodDeleteMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId).replace("{interruptionId}", interruptionId), {
        method: apiIfoodDeleteMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.statusCode == 204) {
            setTextToast("Interrupção excluída com sucesso. Aguarde o processamento.");
            setShowToast(true);

            setTimeout(() => {
              getMerchantsInterruptions();
            }, 3000);

          }
          else {
            setTextToast("Ocorreu um erro ao excluir, verifique as informações e tente novamente.");
            setShowToast(true);
          }



        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    enviar();

  }

  function postMerchantsInterruptions() {

    const enviar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      let params = {
        time: selectedTime,
        description: selectedReason
      };

      requestService(apiIfoodPostMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPostMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.statusCode == 201 || response.statusCode == 200) {
            setTextToast("Interrupção realizada com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setTimeout(() => {
              getMerchantsInterruptions();
            }, 3000);

            setShowFecharLojaIfood(false);
          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    if (!selectedTime) {
      setTextToast("Tempo é obrigatório para fechar a loja.");
      setShowToast(true);
    }
    else if (!selectedReason) {
      setTextToast("Motivo é obrigatório para fechar a loja.");
      setShowToast(true);
    }
    else {
      enviar();
    }

  }

  function postMerchantsInterruptionsPausa() {

    const enviar = async () => {
      // Verificar se todos os campos estão preenchidos
      if (!startDate || !startTime || !endDate || !endTime || !pauseTitle) {
        // Caso algum campo esteja vazio, exibir mensagem de erro
        setTextToast("Todos os campos são obrigatórios.");
        setShowToast(true);
        return; // Interromper o envio caso algum campo não esteja preenchido
      }

      console.log("aasasasasa", loja);
      let id = loja;
      let merchantId = window.localStorage.getItem("merchantIdIfood") || "";
      console.log("aasasasasa", merchantId);

      // Extração da data do início
      const extractedStartDate = new Date(startDate).toISOString().split("T")[0]; // "2024-12-04"
      // Combinar data e hora do início em um único formato ISO 8601 com fuso horário
      const startDateTime = `${extractedStartDate}T${startTime}`; // "2024-12-04T15:58:00-03:00"

      // Extração da data do término
      const extractedEndDate = new Date(endDate).toISOString().split("T")[0]; // "2024-12-04"
      // Combinar data e hora do término em um único formato ISO 8601 com fuso horário
      const endDateTime = `${extractedEndDate}T${endTime}`; // "2024-12-04T16:58:00-03:00"

      console.log("startDateTime", startDateTime);

      // Preencher o objeto params corretamente
      const params = {
        description: pauseTitle, // Título da pausa
        start: startDateTime,    // Início completo no formato ISO 8601
        end: endDateTime,        // Término completo no formato ISO 8601
      };

      // Realizar a requisição
      requestService(apiIfoodPostMerchantsInterruptions.url.replace("{id}", id.replace(/#/g, '%23')).replace("{merchantId}", merchantId), {
        method: apiIfoodPostMerchantsInterruptions.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.statusCode == 201 || response.statusCode == 200) {
            setTextToast("Interrupção realizada com sucesso. Aguarde o processamento.");
            setShowToast(true);
            setTimeout(() => {
              getMerchantsInterruptions();
            }, 3000);

            setShowPausaProgramada(false);
          } else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });
    };

    enviar();

  }


  function getDevices() {

    const consultar = async () => {

      console.log("aasasasasa", loja);



      let id = loja;
      requestService(apiMercadoPagoDevices.url.replace("{id}", id.replace(/#/g, '%23')) + "?accessToken=" + mercadoPagoAccessToken, {
        method: apiMercadoPagoDevices.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response.data);

          if (response.data.devices) {
            setListaMercadoPagoDevices(response.data.devices);
            setMercadoPago1(false);
            setMercadoPago2(true);
          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
          setShowToast(true);
        });

    }

    consultar();
  }


  function patchOperationMode(deviceId: any, mode: any) {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;


      let params = {
        operating_mode: mode
      };

      requestService(apiMercadoPagoOperationMode.url.replace("{id}", id.replace(/#/g, '%23')).replace("{device_id}", deviceId) + "?accessToken=" + mercadoPagoAccessToken, {
        method: apiMercadoPagoOperationMode.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(params),
        credentials: 'include'
      },
        history,
        async (response: any) => {
          console.log(response.data);

          if (response.data.operating_mode) {
            setTextToast("Alteração realizada com sucesso.");
            setShowToast(true);
            setMercadoPago2(false);
          }
          else {
            setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
            setShowToast(true);
          }

        },
        (error: any) => {
          console.log(error);
          setTextToast("Ocorreu um erro ao processar, verifique as informações e tente novamente.");
          setShowToast(true);
        });

    }

    consultar();
  }

  const generateTimeOptions2359 = () => {
    const times = [];
    let hour = 0;
    let minute = 0;

    // Gera opções de hora no formato HH:mm:ss, de 30 em 30 minutos
    for (let i = 0; i < 48; i++) {
      const hourStr = hour < 10 ? `0${hour}` : `${hour}`;
      const minuteStr = minute < 10 ? `0${minute}` : `${minute}`;
      times.push(`${hourStr}:${minuteStr}:00`);

      // Incrementa os minutos em 30
      minute += 30;
      if (minute === 60) {
        minute = 0;
        hour += 1;
      }
    }

    // Adiciona a última opção de horário como "23:59"
    times.push("23:59:00");

    return times;
  };

  const generateDurationOptions = () => {
    const durations = [];
    let duration = 30; // Começando com 30 minutos
    while (duration <= 1439) { // Limite máximo de 1439 minutos (23h59)
      durations.push(duration);
      duration += 30; // Incrementa de 30 em 30 minutos
    }

    // Adiciona explicitamente 23:59:00
    durations.push(1439); // 1439 minutos correspondem a 23:59

    return durations;
  };


  const formatDuration = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Se for 0 horas, retorna apenas os minutos
    if (hours === 0 && mins > 0) {
      return `${mins} minutos`;
    }

    // Se for 0 minutos, retorna apenas as horas
    if (mins === 0 && hours > 0) {
      return `${hours} horas`;
    }

    // Se ambos forem diferentes de 0, retorna as horas e minutos
    return `${hours} horas e ${mins} minutos`;
  };


  const handleDurationChange = (duration: number) => {
    console.log("duration", duration);
    setDurationOpenHour(duration);

    if (startOpenHour) {
      const [startHour, startMinute] = startOpenHour.split(':').map(Number);
      const startTotalMinutes = startHour * 60 + startMinute;

      const endTotalMinutes = startTotalMinutes + duration;

      // Verifica se o cálculo da duração gera um valor negativo
      console.log("startTotalMinutes", startTotalMinutes);
      console.log("endTotalMinutes", endTotalMinutes);
      // Verifica se o total de minutos ultrapassa 1440 minutos (24 horas)
      if (endTotalMinutes > 1439) {
        setDurationOpenHour(0);
        setTextToast("A duração não pode ultrapassar 23:59 horas. Verifique as informações e tente novamente.");
        setShowToast(true);
      }
    }
  };

  function converterParaString(dadosImpressao: any) {
    return String.fromCharCode(...dadosImpressao.filter((byte: any) => byte <= 0xFF)); // Filtrando valores válidos
  }

  async function enviarImpressao(printer: any, data: any) {

    const url = "http://localhost:9100/print";

    // Montando o corpo da requisição no formato necessário
    var raw = JSON.stringify({
      "printer": printer,
      "data": data,  // Dados no formato correto (com códigos ESC/POS)
    });

    try {
      // Configurações da requisição
      const requestOptions: any = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: 'follow',
      };

      // Envia a requisição utilizando fetch
      const resp = await fetch(url, requestOptions);

      // Aguardar a resposta como texto
      const result = await resp.text();

      if (result) {
        console.log("Impressão enviada com sucesso:", result);
      } else {
        console.error("Erro: Nenhuma resposta recebida.");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para a impressora:", error);
    }
  }


  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">


          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>


          <IonTitle slot="start">Integrações</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding ion-text-center" fullscreen>
        <IonGrid style={{ height: "100%" }}>
          <IonRow
            style={{ height: "100%" }}
          >
            {/* Card iFood */}
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <IonCard style={{ textAlign: "center" }}>
                <IonCardHeader>
                  <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                    <IonImg
                      src={iconIfood}
                      alt="iFood"
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <IonCardTitle>iFood</IonCardTitle>
                  <IonCardSubtitle>Marketplace</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  <p style={{ marginBottom: "20px" }}>
                    Automatize os pedidos recebidos no delivery em seu estabelecimento.
                  </p>
                  <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                    <IonButton size="large" onClick={() => {
                      getUserCode();
                      setAtivarIfood1(true);
                    }}>
                      Ativar
                    </IonButton>
                    <IonButton size="large" onClick={() => {
                      getMerchants();
                    }}>
                      Detalhes
                    </IonButton>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>

            {/* Card Mercado Pago */}
            <IonCol size="12" sizeMd="6" sizeLg="4">
              <IonCard style={{ textAlign: "center" }}>
                <IonCardHeader>
                  <div style={{ display: "flex", justifyContent: "center", marginBottom: "10px" }}>
                    <IonImg
                      src={iconMercadopago}
                      alt="Mercado Pago"
                      style={{ width: "80px", height: "80px" }}
                    />
                  </div>
                  <IonCardTitle>Mercado Pago</IonCardTitle>
                  <IonCardSubtitle>Pagamentos</IonCardSubtitle>
                </IonCardHeader>

                <IonCardContent>
                  <p style={{ marginBottom: "20px" }}>
                    Receba pagamentos facilmente via Mercado Pago - Point Pro.
                  </p>
                  <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                    <IonButton size="large" onClick={() => {
                      setMercadoPago1(true);
                    }}>
                      Ativar
                    </IonButton>
                    <IonButton size="large" onClick={() => {
                      getDevices();
                    }}>
                      Detalhes
                    </IonButton>
                  </div>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonModal isOpen={ativarIfood1} onDidDismiss={() => { setAtivarIfood1(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setAtivarIfood1(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
              <IonItem lines="none" style={{ justifyContent: "center" }}>
                <img style={{ width: '120px', borderRadius: '5px' }} src={iconIfood} alt="iFood icon" />
              </IonItem>

              <IonItem lines="none" style={{ justifyContent: "center", textAlign: "center" }}>
                <div>
                  Ao integrar, os pedidos do iFood irão aparecer em {window.localStorage.getItem("servicoTipoPlural") || "Projetos"}
                </div>
              </IonItem>

              <IonItem lines="none" style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <div>
                  Copie o código abaixo e cole no portal do parceiro:
                </div>




              </IonItem>

              <IonItem lines="none" style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <div>


                  <IonInput
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#7D7D7D",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      padding: "12px"
                    }}
                    value={userCode}
                    readonly
                  />

                  <IonButton fill="clear" onClick={() => {
                    navigator.clipboard.writeText(userCode);
                    setTextToast("Código copiado com sucesso.");
                    setShowToast(true);
                  }}>
                    <IonIcon color="primary" icon={copy} /> copiar
                  </IonButton>
                </div>
              </IonItem>
              <IonItem lines="none" style={{ textAlign: "center", justifyContent: "center" }}>
                <div>
                  Ou clique em: <a href={`https://portal.ifood.com.br/apps/code?c=${userCode}`} target="_blank" rel="noopener noreferrer">https://portal.ifood.com.br/apps/code?c={userCode}</a>
                </div>
              </IonItem>
            </IonList>

          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {
              setAtivarIfood1(false);
              setAtivarIfood2(true);
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={ativarIfood2} onDidDismiss={() => { setAtivarIfood2(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setAtivarIfood2(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
              <IonItem lines="none" style={{ justifyContent: "center" }}>
                <img style={{ width: '200px', borderRadius: '5px' }} src={iconIfood} alt="iFood icon" />
              </IonItem>

              <IonItem lines="none" style={{ justifyContent: "center", textAlign: "center" }}>
                <div>
                  Agora, copie o código no portal do parceiro gerado do iFood e cole abaixo:
                </div>
              </IonItem>

              <IonItem lines="none" style={{ justifyContent: "center", width: "100%", maxWidth: "300px" }}>
                <IonInput
                  style={{
                    textAlign: "center",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#7D7D7D",
                    border: "1px solid #E0E0E0",
                    borderRadius: "8px",
                    padding: "12px"
                  }}
                  value={authorizationCode}
                  onIonInput={(e: any) => setAuthorizationCode(e.target.value)}
                  placeholder='AAAA-AAAA'
                />

              </IonItem>

            </IonList>

          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {

              getTokenIfood();

            }}>Integrar</IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showLojasIfood} onDidDismiss={() => { setShowLojasIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowLojasIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListalojasIfood()}
            </IonList>
          </IonContent>

          {/* <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {
              setShowLojasIfood(false);
            }}>Remover integrações</IonButton>
          </IonFooter> */}
        </IonModal>

        <IonModal isOpen={showDetalheLojaIfood} onDidDismiss={() => { setShowDetalheLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Detalhes - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowDetalheLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {detalheLojaIfood && (
              <>
                <IonCard>
                  {/* <IonCardHeader>
                    <IonCardTitle>{detalheLojaIfood.name}</IonCardTitle>
                    <IonCardSubtitle>{detalheLojaIfood.corporateName}</IonCardSubtitle>
                  </IonCardHeader> */}
                  <IonCardContent>
                    <IonList>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>{detalheLojaIfood.name}</h3>
                          <p>{detalheLojaIfood.corporateName}</p>

                        </IonLabel>
                      </IonItem>

                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Endereço</h3>
                          <p>{detalheLojaIfood.address.street}, {detalheLojaIfood.address.number}</p>
                          <p>{detalheLojaIfood.address.city}, {detalheLojaIfood.address.state} - {detalheLojaIfood.address.country}</p>
                          <p>CEP: {detalheLojaIfood.address.postalCode}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Latitude / Longitude</h3>
                          <p>{detalheLojaIfood.address.latitude}, {detalheLojaIfood.address.longitude}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Ticket Médio</h3>
                          <p>R$ {detalheLojaIfood.averageTicket.toFixed(2)}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Status</h3>
                          <p>{detalheLojaIfood.status}</p>
                        </IonLabel>
                      </IonItem>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>Operações</h3>
                          {detalheLojaIfood.operations?.map((op: any, index: any) => (
                            <p key={index}>{op.name}</p>
                          ))}
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </>
            )}
          </IonContent>

          {/* <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => setShowLojasIfood(false)}>
              Desativar integração
            </IonButton>
          </IonFooter> */}
        </IonModal>

        <IonModal isOpen={showStatusLojaIfood} onDidDismiss={() => { setShowStatusLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Status - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowStatusLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {statusLojaIfood && Array.isArray(statusLojaIfood) && statusLojaIfood?.map((item: any, index: any) => (
              <IonCard key={index}>
                {/* <IonCardHeader>
                  <IonCardTitle>{item.message?.title}</IonCardTitle>
                  <IonCardSubtitle>{item.message?.subtitle}</IonCardSubtitle>
                </IonCardHeader> */}
                <IonCardContent>
                  <IonList>
                    {/* <IonItem>
                      <IonLabel>
                        <h3>Operação</h3>
                        <p>{item.operation || "Não especificado"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Canal de Venda</h3>
                        <p>{item.salesChannel || "Não especificado"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Disponível</h3>
                        <p>{item.available ? "Sim" : "Não"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Estado</h3>
                        <p>{item.state || "Não especificado"}</p>
                      </IonLabel>
                    </IonItem>
                    <IonItem>
                      <IonLabel>
                        <h3>Reabertura Permitida</h3>
                        <p>{item.reopenable?.reopenable ? "Sim" : "Não"}</p>
                      </IonLabel>
                    </IonItem> */}

                    <IonItem lines="none">
                      <IonLabel>
                        <h3>{item.message?.title}</h3>
                        <p>{item.message?.subtitle}</p>
                      </IonLabel>
                    </IonItem>
                    {item.validations?.length > 0 && (
                      <>
                        {
                          item.validations.map((validation: any, idx: any) => (
                            <div key={idx}>
                              <IonItem lines="none">
                                <IonLabel>
                                  <h3>{validation.message?.title}</h3>
                                  <p>{validation.message?.subtitle}</p>
                                </IonLabel>
                              </IonItem>


                            </div>
                          ))
                        }
                      </>
                    )}
                  </IonList>
                </IonCardContent>
              </IonCard>
            ))}
          </IonContent>

          {/* <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => setShowFecharLojaIfood(true)}>
              Fechar Loja
            </IonButton>
          </IonFooter> */}
        </IonModal>

        <IonModal isOpen={showInterruptionsLojaIfood} onDidDismiss={() => { setShowInterruptionsLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Interrupções - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowInterruptionsLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {/* <IonFab vertical="top" horizontal="end" slot="fixed">
              <IonFabButton color="tertiary" onClick={() => { getMerchantsInterruptions(); }} >
                <IonIcon color="light" icon={refresh}></IonIcon>
              </IonFabButton>
            </IonFab> */}
            {interruptionsLojaIfood && Array.isArray(interruptionsLojaIfood) && interruptionsLojaIfood.map((item: any, index: any) => (
              <IonCard key={index}>
                <IonCardContent>
                  <IonList>
                    <IonItem lines="none">
                      <IonLabel>
                        <h3>{item.description}</h3>
                        <p>
                          Início: {new Date(item.start).toLocaleDateString("pt-BR") + " " +
                            new Date(item.start).toLocaleTimeString("pt-BR", { hour12: false })}
                        </p>
                        <p>
                          Fim: {new Date(item.end).toLocaleDateString("pt-BR") + " " +
                            new Date(item.end).toLocaleTimeString("pt-BR", { hour12: false })}
                        </p>
                      </IonLabel>
                      <IonButton
                        fill="clear"
                        slot="end"
                        color="danger"
                        onClick={() => deleteMerchantsInterruptions(item.id)}
                      >
                        <IonIcon icon={trashOutline} />
                      </IonButton>
                    </IonItem>
                  </IonList>
                </IonCardContent>
              </IonCard>
            ))}
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => {
              setStartDate("");  // Limpa o campo de data de início
              setStartTime("");  // Limpa o campo de hora de início
              setEndDate("");    // Limpa o campo de data de término
              setEndTime("");    // Limpa o campo de hora de término

              // Limpar o título da pausa
              setPauseTitle(""); // Limpa o título da pausa
              setShowPausaProgramada(true);
            }}>
              Pausa Programada
            </IonButton>
            <IonButton expand="block" color="primary" onClick={() => {
              setSelectedTime("");
              setSelectedReason("");
              setShowFecharLojaIfood(true);
            }}>
              Fechar Loja
            </IonButton>
          </IonFooter>


        </IonModal>

        <IonModal isOpen={showOpenHoursLojaIfood} onDidDismiss={() => { setShowOpenHoursLojaIfood(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Horários - Integração iFood</IonTitle>
              <IonButtons slot="start" onClick={() => setShowOpenHoursLojaIfood(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {/* <IonFab vertical="top" horizontal="end" slot="fixed">
              <IonFabButton color="tertiary" onClick={() => { getMerchantsOpenHours(); }} >
                <IonIcon color="light" icon={setTime}></IonIcon>
              </IonFabButton>
            </IonFab> */}
            {openHoursLojaIfood.shifts && Array.isArray(openHoursLojaIfood.shifts) && openHoursLojaIfood.shifts.map((item: any, index: any) => {
              // Função para converter duração (em minutos) para horas e minutos
              const formatDuration = (minutes: number) => {
                const hours = Math.floor(minutes / 60);
                const mins = minutes % 60;
                return `${hours} horas e ${mins} minutos`;
              };

              // Mapeamento dos dias da semana para português
              const daysOfWeekMap: { [key: string]: string } = {
                "MONDAY": "Segunda-feira",
                "TUESDAY": "Terça-feira",
                "WEDNESDAY": "Quarta-feira",
                "THURSDAY": "Quinta-feira",
                "FRIDAY": "Sexta-feira",
                "SATURDAY": "Sábado",
                "SUNDAY": "Domingo"
              };

              // Traduz o dia da semana
              const translatedDay = daysOfWeekMap[item.dayOfWeek] || item.dayOfWeek;

              return (
                <IonCard key={index}>
                  <IonCardContent>
                    <IonList>
                      <IonItem lines="none">
                        <IonLabel>
                          <h3>{translatedDay}</h3> {/* Usa o dia traduzido */}
                          <p>Começa: {item.start}</p>
                          <p>Duração: {formatDuration(item.duration)}</p> {/* Converte a duração */}
                        </IonLabel>
                        <IonButton
                          fill="clear"
                          slot="end"
                          color="primary"
                          onClick={() => {
                            setIdOpenHour(item.id);
                            setDayOfWeekOpenHour(item.dayOfWeek);
                            setStartOpenHour(item.start);
                            setDurationOpenHour(item.duration);
                            setShowAlterarHorarioLojaIfood(true);
                          }}
                        >
                          <IonIcon icon={pencilOutline} />
                        </IonButton>
                        <IonButton
                          fill="clear"
                          slot="end"
                          color="danger"
                          onClick={() => {

                            putMerchantsOpenHoursRemover(item.id);

                          }}
                        >
                          <IonIcon icon={trashOutline} />
                        </IonButton>
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              );
            })}

          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => {
              setIdOpenHour("");
              setDayOfWeekOpenHour("");
              setStartOpenHour("");
              setDurationOpenHour("");
              setShowAlterarHorarioLojaIfood(true);
            }
            }>
              Adicionar horário
            </IonButton>
          </IonFooter>

        </IonModal>

        <IonModal isOpen={showAlterarHorarioLojaIfood} onDidDismiss={() => setShowAlterarHorarioLojaIfood(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Alterar Horário Loja - Integração iFood</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowAlterarHorarioLojaIfood(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <IonList>
              <IonItem>
                <IonLabel position="stacked">Dia da Semana</IonLabel>
                <IonSelect
                  value={dayOfWeekOpenHour}
                  placeholder="Selecione um dia"
                  onIonChange={(e) => setDayOfWeekOpenHour(e.detail.value)}
                >
                  <IonSelectOption value="MONDAY">Segunda-feira</IonSelectOption>
                  <IonSelectOption value="TUESDAY">Terça-feira</IonSelectOption>
                  <IonSelectOption value="WEDNESDAY">Quarta-feira</IonSelectOption>
                  <IonSelectOption value="THURSDAY">Quinta-feira</IonSelectOption>
                  <IonSelectOption value="FRIDAY">Sexta-feira</IonSelectOption>
                  <IonSelectOption value="SATURDAY">Sábado</IonSelectOption>
                  <IonSelectOption value="SUNDAY">Domingo</IonSelectOption>
                </IonSelect>
              </IonItem>


              <IonItem>
                <IonLabel position="stacked">Hora de Início</IonLabel>
                <IonSelect
                  value={startOpenHour}
                  placeholder="Selecione um horário"
                  onIonChange={(e) => setStartOpenHour(e.detail.value)}
                >
                  {generateTimeOptions2359().map((time: any, index: any) => (
                    <IonSelectOption key={index} value={time}>
                      {time}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Duração</IonLabel>
                <IonSelect
                  value={durationOpenHour}
                  placeholder="Selecione a duração"
                  onIonChange={(e) => handleDurationChange(e.detail.value)}
                >
                  {generateDurationOptions().map((duration: any, index: any) => (
                    <IonSelectOption key={index} value={duration}>
                      {formatDuration(duration)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>


            </IonList>
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => putMerchantsOpenHours()}>
              Salvar
            </IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showFecharLojaIfood} onDidDismiss={() => setShowFecharLojaIfood(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Fechar Loja - Integração iFood</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowFecharLojaIfood(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <div>
              <h2>Informe o motivo do fechamento.</h2>

              <IonList>
                <IonItem lines="none">
                  <IonLabel>Fechar por quanto tempo?</IonLabel>
                </IonItem>
                <IonRadioGroup value={selectedTime} onIonChange={(e) => setSelectedTime(e.detail.value)}>
                  <IonItem lines="none">
                    <IonLabel>15 min</IonLabel>
                    <IonRadio slot="start" value="15m" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>30 min</IonLabel>
                    <IonRadio slot="start" value="30m" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>1 h</IonLabel>
                    <IonRadio slot="start" value="1h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>3 h</IonLabel>
                    <IonRadio slot="start" value="3h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>6 h</IonLabel>
                    <IonRadio slot="start" value="6h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>12 h</IonLabel>
                    <IonRadio slot="start" value="12h" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>24 h</IonLabel>
                    <IonRadio slot="start" value="24h" />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
              <IonList>
                <IonItem lines="none">
                  <IonLabel>Qual o motivo?</IonLabel>
                </IonItem>
                <IonRadioGroup value={selectedReason} onIonChange={(e) => setSelectedReason(e.detail.value)}>
                  <IonItem lines="none">
                    <IonLabel>Muitos pedidos</IonLabel>
                    <IonRadio slot="start" value="Muitos pedidos" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Manutenção/Problemas Técnicos</IonLabel>
                    <IonRadio slot="start" value="Manutenção/Problemas Técnicos" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Horário de Funcionamento/Feriado</IonLabel>
                    <IonRadio slot="start" value="Horário de Funcionamento/Feriado" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Falta de Equipe/Problemas Pessoais</IonLabel>
                    <IonRadio slot="start" value="Falta de Equipe/Problemas Pessoais" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Problemas de Logística</IonLabel>
                    <IonRadio slot="start" value="Problemas de Logística" />
                  </IonItem>
                  <IonItem lines="none">
                    <IonLabel>Outros</IonLabel>
                    <IonRadio slot="start" value="Outros" />
                  </IonItem>
                </IonRadioGroup>
              </IonList>
            </div>
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => postMerchantsInterruptions()}>
              Fechar Loja
            </IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={showPausaProgramada} onDidDismiss={() => setShowPausaProgramada(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Criar Pausa Programada</IonTitle>
              <IonButtons slot="start">
                <IonButton onClick={() => setShowPausaProgramada(false)}>
                  <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent className="ion-padding">
            <div>
              <h3>A loja ficará indisponível nesta data, mesmo que esteja dentro do horário de funcionamento e o Gestor de Pedidos estiver aberto.</h3>

              {/* Título da pausa programada */}
              <IonItem>
                <IonLabel position="stacked">Título da Pausa Programada</IonLabel>
                <IonInput placeholder="Digite o título" onIonChange={(e) => setPauseTitle(e.detail.value!)} />
              </IonItem>

              {/* Começa em */}
              <div>
                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Começa em</IonLabel>
                  <IonDatetime
                    presentation="date"
                    onIonChange={(e) => setStartDate(e.detail.value!)}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Hora de início</IonLabel>
                  <IonSelect
                    value={startTime}
                    placeholder="Selecione um horário"
                    onIonChange={(e) => setStartTime(e.detail.value)}
                  >
                    {generateTimeOptions2359().map((time: any, index: any) => (
                      <IonSelectOption key={index} value={time}>
                        {time}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                {/* Termina em */}
                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Termina em</IonLabel>
                  <IonDatetime
                    presentation="date"
                    onIonChange={(e) => setEndDate(e.detail.value!)}
                  />
                </IonItem>

                <IonItem>
                  <IonLabel position="stacked" style={{ paddingBottom: "14px" }}>Hora de término</IonLabel>
                  <IonSelect
                    value={endTime}
                    placeholder="Selecione um horário"
                    onIonChange={(e) => setEndTime(e.detail.value)}
                  >
                    {generateTimeOptions2359().map((time: any, index: any) => (
                      <IonSelectOption key={index} value={time}>
                        {time}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </div>
            </div>
          </IonContent>

          <IonFooter>
            <IonButton expand="block" color="primary" onClick={() => postMerchantsInterruptionsPausa()}>
              Salvar pausa programada
            </IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={mercadoPago1} onDidDismiss={() => { setMercadoPago1(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração Mercado Pago - Point</IonTitle>
              <IonButtons slot="start" onClick={() => setMercadoPago1(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" }}>
              <IonItem lines="none" style={{ justifyContent: "center" }}>
                <img style={{ width: '120px', borderRadius: '5px' }} src={iconMercadopago} alt="Mercado Pago icon" />
              </IonItem>

              <IonItem lines="none" style={{ justifyContent: "center", textAlign: "center" }}>
                <div>
                  Ao integrar, os pagamentos serão automaticamente vinculados a sua Maquininha Point para realizar os pagamentos
                </div>
              </IonItem>

              <IonItem lines="none" style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <div>
                  Entre com a <b>credencial de produção - Access Token</b> em: <a href={`https://www.mercadopago.com.br/developers`} target="_blank" rel="noopener noreferrer">https://www.mercadopago.com.br/developers</a>
                </div>




              </IonItem>

              <IonItem lines="none" style={{ flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                <div>


                  <IonInput
                    style={{
                      textAlign: "center",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#7D7D7D",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      padding: "12px"
                    }}
                    value={mercadoPagoAccessToken}
                    onIonInput={(e: any) => setMercadoPagoAccessToken(e.target.value)}
                    placeholder='APP_USR-132131...'
                  />

                </div>
              </IonItem>
            </IonList>

          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {


              setShowLoading(true);
              const objectRequest = {
                accessToken: mercadoPagoAccessToken,
              };
              let id = loja;

              console.log("id", id)

              requestService(
                apiMercadoPagoCriarConfiguracao.url.replace("{id}", id.replaceAll("#", "%23")),
                {
                  method: apiMercadoPagoCriarConfiguracao.method,
                  body: JSON.stringify(objectRequest),
                  headers: [
                    ["token", window.localStorage.getItem("token")]
                  ],
                  credentials: 'include'
                },
                history,
                (response: any) => {
                  console.log(response);
                  if (response) {
                    getDevices();
                    console.log("Gravou as configurações de accessToken");
                  }
                  setShowLoading(false);
                },
                (error: any) => {
                  console.log(error);
                  setTextToast("Ocorreu um erro ao gravar o accessToken.");
                  setShowToast(true);
                  setShowLoading(false);
                })


        
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>

        <IonModal isOpen={mercadoPago2} onDidDismiss={() => { setMercadoPago2(false); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Integração Mercado Pago - Devices</IonTitle>
              <IonButtons slot="start" onClick={() => setMercadoPago2(false)}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListaDevices()}
            </IonList>
          </IonContent>

          {/* <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {
              setShowLojasIfood(false);
            }}>Remover integrações</IonButton>
          </IonFooter> */}
        </IonModal>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e: any) => { setShowSucesso(false); history.goBack(); }}
          message={'Alterações salvas com sucesso!'}
          duration={3000}
          animated={false}
        />
      </IonContent >

    </IonPage >
  );
};

export default Integracoes;
