
const URL_BASE = "https://ms75utuwu0.execute-api.sa-east-1.amazonaws.com/prd"; // Produção
//const URL_BASE = "http://localhost:3000/prd";
const URL_INTEGRACOES_BASE = "https://j8hr02kzb7.execute-api.sa-east-1.amazonaws.com/prd";
//const URL_INTEGRACOES_BASE = "http://localhost:3000/prd";



// variavel utilizada para indicar a pasta de gravação das imagens no s3
export const ambiente = "prd"
//export const ambiente = "dev"

export const urlBase = { url: URL_BASE, method: "GET" };
export const enviarEmail = { url: URL_BASE + "/usuarios/{cpf}/autenticacao/email", method: "POST" };
export const logar = { url: URL_BASE + "/usuarios/{cpf}/autenticacao", method: "POST" };
export const confirmarLogin = { url: URL_BASE + "/usuarios/{cpf}/autenticacao/otp", method: "POST" };
export const autenticado = { url: URL_BASE + "/usuarios/autenticado", method: "POST" };
export const listaEquipe = { url: URL_BASE + "/usuarios", method: "GET" };
export const listaEquipePorPerfil = { url: URL_BASE + "/usuarios/perfil/{perfil}", method: "GET" };
export const cadastraEquipe = { url: URL_BASE + "/usuarios", method: "POST" };
export const alteraEquipe = { url: URL_BASE + "/usuarios/{cpf}", method: "PUT" };
export const resetSenhaUsuario = { url: URL_BASE + "/usuarios/{cpf}", method: "PUT" };
export const excluiUsuario = { url: URL_BASE + "/usuarios/{cpf}", method: "DELETE" };
export const reportsBuscar = { url: URL_BASE + "/reports", method: "GET" };
export const operacaoChamarMotorista = { url: URL_BASE + "/operacoes/{pk}", method: "POST" };
export const operacaoRegistrarAnotacao = { url: URL_BASE + "/operacoes/{pk}/anotacoes", method: "POST" };
export const cadastraConvidado = { url: URL_BASE + "/convidados", method: "POST" };
export const excluiConvidado = { url: URL_BASE + "/convidados/{pk}/{sk}", method: "DELETE" };
export const listaConvidados = { url: URL_BASE + "/convidados", method: "GET" };
export const deslogar = { url: URL_BASE + "/usuarios/saida", method: "POST" };
export const onboarding_all = { url: URL_BASE + "/onBoarding/v1?onBoarding=1", method: "GET" };
export const usuarioCadastrar = { url: URL_BASE + "/usuarios/{id}/cadastro", method: "POST" };
export const usuarioExcluir = { url: URL_BASE + "/usuarios/{id}/cadastro", method: "DELETE" };
export const usuariosAlterarAcesso = { url: URL_BASE + "/usuarios/{pk}/acessos", method: "PUT" };
export const confirmarCadastro = { url: URL_BASE + "/usuario/confirmar", method: "POST" };
export const verificaLogin = { url: URL_BASE + "/echoLogado", method: "GET" };
export const empresaCarregar = { url: URL_BASE + "/empresas/{id}", method: "GET" };
export const empresaConfiguracoesCarregar = { url: URL_BASE + "/empresas/{id}/configuracoes", method: "GET" };
export const empresaAlterar = { url: URL_BASE + "/empresas/{id}", method: "PUT" };
export const projetoCarregar = { url: URL_BASE + "/agendamentos/{id}/projeto", method: "GET" };
export const agendamentoProjetoCarregar = { url: URL_BASE + "/agendamentos/projeto/{projetoid}", method: "GET" };
// export const incluirOperacao = { url: URL_BASE + "/operacoes", method: "POST" };
export const encerrarOperacao = { url: URL_BASE + "/operacoes/{id}", method: "PUT" };
export const incluirFotoOperacao = { url: URL_BASE + "/operacoes", method: "PATCH" };
export const removerFotoOperacao = { url: URL_BASE + "/operacoes/{id}/photo", method: "DELETE" };
export const incluiNotaFiscal = { url: URL_BASE + "/operacoes/{id}/notas", method: "POST" };
export const operacaoProjetoCarregar = { url: URL_BASE + "/operacoes/projeto/{projetoid}", method: "GET" };
export const listaFotosProjeto = { url: URL_BASE + "/operacoes/{projeto}/projeto/", method: "GET" };
export const incluirAgendamento = { url: URL_BASE + "/agendamentos", method: "POST" };
export const finalizarAgendamento = { url: URL_BASE + "/agendamentos/{id}", method: "PUT" };
export const agendamentoRegistrarAnotacao = { url: URL_BASE + "/agendamentos/{pk}/anotacoes", method: "POST" };
export const incluirProjeto = { url: URL_BASE + "/projetos", method: "POST" };
export const alterarProjeto = { url: URL_BASE + "/projetos", method: "PUT" };



export const abrirMesa = { url: URL_BASE + "/projetos/{id}/novo", method: "POST" };
export const enviarMesa = { url: URL_BASE + "/projetos/{id}/enviarpedido", method: "PUT" };
export const fecharMesa = { url: URL_BASE + "/projetos/{id}/fechar", method: "PATCH" };

export const pagamentoMensalidade = { url: URL_BASE + "/empresas/{id}/pagamento", method: "POST" };
export const consultaPagamentoMensalidade = { url: URL_BASE + "/empresas/{id}/pagamento", method: "GET" };

// export const unirComanda = { url: URL_BASE + "/projetos/{id}/comanda", method: "GET" };
// export const encerrarComanda = { url: URL_BASE + "/projetos/{id}/comanda", method: "POST" };
export const alterarStatusProjeto = { url: URL_BASE + "/projetos/{id}", method: "PATCH" };
export const enviarMensagemCliente = { url: URL_BASE + "/projetos/{id}", method: "POST" };
export const listarMensagensCliente = { url: URL_BASE + "/projetos/{id}/mensagem", method: "GET" };
export const finalizarProjeto = { url: URL_BASE + "/projetos/{id}", method: "PUT" };
export const excluirProjeto = { url: URL_BASE + "/projetos/{id}", method: "DELETE" };
export const reabrirProjeto = { url: URL_BASE + "/projetos/{id}?status=ativo", method: "PUT" };
export const projetoRegistrarAnotacao = { url: URL_BASE + "/projetos/{pk}/anotacoes", method: "POST" };
export const removerArquivoDetalheProjeto = { url: URL_BASE + "/projetos/{id}/arquivodetalhe", method: "DELETE" };
export const projetoDetalheRegistrarArquivo = { url: URL_BASE + "/projetos/{id}/arquivodetalhe", method: "POST" };
export const projetoRegistrarResponsavel = { url: URL_BASE + "/projetos/{pk}/responsavel", method: "POST" };
export const projetoRegistrarInformacao = { url: URL_BASE + "/projetos/{pk}/informacao", method: "POST" };
export const projetoLista = { url: URL_BASE + "/projetos/loja/{loja}", method: "GET" };
export const removerFotoProjeto = { url: URL_BASE + "/projetos/{id}/photo", method: "DELETE" };
export const removerArquivoProjeto = { url: URL_BASE + "/projetos/{id}/arquivo", method: "DELETE" };
export const projetoGerarAcompanhamento = { url: URL_BASE + "/projetos/{id}/acompanhamento", method: "GET" };
export const projetoGerarCupom = { url: URL_BASE + "/projetos/{id}/cupom", method: "GET" };
export const projetoGerarCupomParcial = { url: URL_BASE + "/projetos/{id}/cupomparcial", method: "GET" };
export const projetoGerarCupomPdf = { url: URL_BASE + "/projetos/{id}/cupompdf", method: "GET" };
export const comandaGerarCupom = { url: URL_BASE + "/projetos/{id}/comandacupom", method: "GET" };
export const comandaGerarCupomPdf = { url: URL_BASE + "/projetos/{id}/comandacupompdf", method: "GET" };
export const mesaGerarCupom = { url: URL_BASE + "/projetos/{id}/mesacupom", method: "GET" };
export const incluirVenda = { url: URL_BASE + "/vendas", method: "POST" };
export const alterarStatusVenda = { url: URL_BASE + "/vendas/{id}", method: "PATCH" };
export const finalizarVenda = { url: URL_BASE + "/vendas/{id}", method: "PUT" };
export const vendaRegistrarAnotacao = { url: URL_BASE + "/vendas/{pk}/anotacoes", method: "POST" };
export const removerArquivoVenda = { url: URL_BASE + "/vendas/{id}/arquivo", method: "DELETE" };
export const vendaRegistrarArquivo = { url: URL_BASE + "/vendas/{id}/arquivo", method: "POST" };
export const vendaGerarOrcamento = { url: URL_BASE + "/vendas/{id}/orcamento", method: "GET" };
export const incluirFinanceiro = { url: URL_BASE + "/financeiro", method: "POST" };
export const baixarFinanceiro = { url: URL_BASE + "/financeiro/{id}", method: "PATCH" };
export const alterarFinanceiro = { url: URL_BASE + "/financeiro/{id}", method: "PUT" };
export const excluirFinanceiro = { url: URL_BASE + "/financeiro/{id}", method: "DELETE" };
export const removerFinanceiroCategoriaEmpresa = { url: URL_BASE + "/empresas/{id}/categoria", method: "DELETE" };
export const removerFormularioCategoriaEmpresa = { url: URL_BASE + "/empresas/{id}/formulariocategoria", method: "DELETE" };
export const removerTagsEnderecoEmpresa = { url: URL_BASE + "/empresas/{id}/tagsendereco", method: "DELETE" };
export const removerItemFormularioEmpresa = { url: URL_BASE + "/empresas/{id}/formulario", method: "DELETE" };
export const ordernarItemFormularioEmpresa = { url: URL_BASE + "/empresas/{id}/formulario", method: "PATCH" };
export const inserirItemFormularioEmpresa = { url: URL_BASE + "/empresas/{id}/formulario", method: "POST" };
export const alterarItemFormularioEmpresa = { url: URL_BASE + "/empresas/{id}/formulario", method: "PUT" };

export const removerItemCatalogoProdutosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoprodutos", method: "DELETE" };
export const ordernarItemCatalogoProdutosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoprodutos", method: "PATCH" };
export const inserirItemCatalogoProdutosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoprodutos", method: "POST" };
export const alterarItemCatalogoProdutosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoprodutos", method: "PUT" };
export const uploadCatalogoProdutosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoprodutosupload", method: "POST" };
export const uploadCatalogoProdutosEmpresaIfood = { url: URL_BASE + "/empresas/{id}/catalogoprodutosuploadifood", method: "POST" };
// export const aumentoCatalogoProdutosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoprodutosaumento", method: "POST" };

export const removerItemCatalogoServicosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoservicos", method: "DELETE" };
export const ordernarItemCatalogoServicosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoservicos", method: "PATCH" };
export const inserirItemCatalogoServicosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoservicos", method: "POST" };
export const alterarItemCatalogoServicosEmpresa = { url: URL_BASE + "/empresas/{id}/catalogoservicos", method: "PUT" };



export const removerStatusEmpresa = { url: URL_BASE + "/empresas/{id}/status", method: "DELETE" };
export const ordernarStatusEmpresa = { url: URL_BASE + "/empresas/{id}/status", method: "PATCH" };
export const inserirStatusEmpresa = { url: URL_BASE + "/empresas/{id}/status", method: "POST" };
export const alterarStatusEmpresa = { url: URL_BASE + "/empresas/{id}/status", method: "PUT" };
export const removerStatusVendaEmpresa = { url: URL_BASE + "/empresas/{id}/statusvenda", method: "DELETE" };
export const ordernarStatusVendaEmpresa = { url: URL_BASE + "/empresas/{id}/statusvenda", method: "PATCH" };
export const inserirStatusVendaEmpresa = { url: URL_BASE + "/empresas/{id}/statusvenda", method: "POST" };
export const alterarStatusVendaEmpresa = { url: URL_BASE + "/empresas/{id}/statusvenda", method: "PUT" };
export const carregaAssinatura = { url: URL_BASE + "/assinatura/{id}", method: "GET" };
// export const empresaRegistrarInformacao = { url: URL_BASE + "/empresas/{pk}/informacao", method: "POST" };
// export const removerFotoEmpresa = { url: URL_BASE + "/empresas/{id}/photo", method: "DELETE" };
// export const removerArquivoEmpresa = { url: URL_BASE + "/empresas/{id}/arquivo", method: "DELETE" };
// export const empresaWorkflowCarregar = { url: URL_BASE + "/empresas/{id}/workflow", method: "GET" };
export const enviarMensagemWhatsapp = { url: URL_BASE + "/whatsapp", method: "POST" };
export const enviarArquivoWhatsapp = { url: URL_BASE + "/whatsapp/arquivo", method: "POST" };
export const enviarAudioWhatsapp = { url: URL_BASE + "/whatsapp/audio", method: "POST" };
export const habilitarMensagemWhatsapp = { url: URL_BASE + "/whatsapp/habilitar", method: "POST" };
export const carregarMensagemWhatsapp = { url: URL_BASE + "/whatsapp/{id}/loja/{loja}", method: "GET" };
export const carregarEmpresaMensagemWhatsapp = { url: URL_BASE + "/whatsapp/{id}/empresa", method: "GET" };
export const assinaUrl = { url: URL_BASE + "/assinaUrl", method: "POST" };
export const enviarArquivoPortfolio = { url: URL_BASE + "/portfolio", method: "POST" };
export const alterarDetaquePortfolio = { url: URL_BASE + "/portfolio/{id}", method: "PUT" };
export const removerArquivoPortfolioCategoria = { url: URL_BASE + "/portfolio/{id}", method: "DELETE" };
export const removerArquivoPortfolioGaleria = { url: URL_BASE + "/portfolio/{id}/galeria", method: "DELETE" };
export const enviarNotificacao = { url: URL_BASE + "/notificacao", method: "POST" };
export const removerAnotacaoProjeto = { url: URL_BASE + "/projetos/{id}?anotacao={anotacao}", method: "PUT" };
export const removerAnotacaoOperacao = { url: URL_BASE + "/operacoes/{id}?anotacao={anotacao}", method: "PUT" };
export const removerAnotacaoAgendamento = { url: URL_BASE + "/agendamentos/{id}?anotacao={anotacao}", method: "PUT" };
export const removerAnotacaoVenda = { url: URL_BASE + "/vendas/{id}?anotacao={anotacao}", method: "PUT" };
export const incluirLembrete = { url: URL_BASE + "/lembretes", method: "POST" };
export const lembretesCarregar = { url: URL_BASE + "/lembretes/{id}", method: "GET" };
export const removerLembrete = { url: URL_BASE + "/lembretes/{id}", method: "DELETE" };
export const removerNotificacao = { url: URL_BASE + "/projetos/{id}/mensagem", method: "PUT" };
export const removerResponsavelProjeto = { url: URL_BASE + "/projetos/{id}?responsavel={responsavel}", method: "PUT" };
export const carregaFormulario = { url: URL_BASE + "/operacoes/{id}/formulario", method: "GET" };
export const salvarFormulario = { url: URL_BASE + "/operacoes/{id}/formulario", method: "POST" };
export const registrarFoto = { url: URL_BASE + "/operacoes/{id}/formulariofoto", method: "POST" };
export const removerFotoFormulario = { url: URL_BASE + "/operacoes/{id}/formulariofoto", method: "DELETE" };

export const removerServicosCategoriaEmpresa = { url: URL_BASE + "/empresas/{id}/servicoscategoria", method: "DELETE" };
export const removerProdutosCategoriaEmpresa = { url: URL_BASE + "/empresas/{id}/produtoscategoria", method: "DELETE" };
export const ordernarItemCategoriaProdutosEmpresa = { url: URL_BASE + "/empresas/{id}/produtoscategoria", method: "PATCH" };
export const ordernarItemFormaPagamentoEmpresa = { url: URL_BASE + "/empresas/{id}/formapagamento", method: "PATCH" };

export const removerProdutosCategoriaNovoEmpresa = { url: URL_BASE + "/empresas/{id}/produtoscategorianovo", method: "DELETE" };
export const removerFormaPagamentoEmpresa = { url: URL_BASE + "/empresas/{id}/formapagamento", method: "DELETE" };

export const removerProjetosItemServicosEmpresa = { url: URL_BASE + "/projetos/{id}/servicos", method: "DELETE" };
export const ordernarProjetosItemServicosEmpresa = { url: URL_BASE + "/projetos/{id}/servicos", method: "PATCH" };
export const inserirProjetosItemServicosEmpresa = { url: URL_BASE + "/projetos/{id}/servicos", method: "POST" };
export const alterarProjetosItemServicosEmpresa = { url: URL_BASE + "/projetos/{id}/servicos", method: "PUT" };


export const removerProjetosItemProdutosEmpresa = { url: URL_BASE + "/projetos/{id}/produtos", method: "DELETE" };
export const ordernarProjetosItemProdutosEmpresa = { url: URL_BASE + "/projetos/{id}/produtos", method: "PATCH" };
export const inserirProjetosItemProdutosEmpresa = { url: URL_BASE + "/projetos/{id}/produtos", method: "POST" };
export const alterarProjetosItemProdutosEmpresa = { url: URL_BASE + "/projetos/{id}/produtos", method: "PUT" };

export const removerVendasItemServicosEmpresa = { url: URL_BASE + "/vendas/{id}/servicos", method: "DELETE" };
export const ordernarVendasItemServicosEmpresa = { url: URL_BASE + "/vendas/{id}/servicos", method: "PATCH" };
export const inserirVendasItemServicosEmpresa = { url: URL_BASE + "/vendas/{id}/servicos", method: "POST" };
export const alterarVendasItemServicosEmpresa = { url: URL_BASE + "/vendas/{id}/servicos", method: "PUT" };

export const removerVendasItemProdutosEmpresa = { url: URL_BASE + "/vendas/{id}/produtos", method: "DELETE" };
export const ordernarVendasItemProdutosEmpresa = { url: URL_BASE + "/vendas/{id}/produtos", method: "PATCH" };
export const inserirVendasItemProdutosEmpresa = { url: URL_BASE + "/vendas/{id}/produtos", method: "POST" };
export const alterarVendasItemProdutosEmpresa = { url: URL_BASE + "/vendas/{id}/produtos", method: "PUT" };

export const buscarEndereco = { url: URL_BASE + "/empresas/{id}/endereco", method: "GET" };
export const empresaDadosProdutosCarregar = { url: URL_BASE + "/empresas/{id}/catalogoprodutos", method: "GET" };
// export const empresaDadosProdutosCarregar = { url: URL_BASE + "/empresas/{id}/catalogoprodutos", method: "GET" }; // precisa remover do back

// caixa geral
export const apiProjetosCaixaGeralLancamentosGET = { url: URL_BASE + "/projetos/{id}/caixageral/lancamentos", method: "GET" };
export const apiProjetosCaixasGET = { url: URL_BASE + "/projetos/{id}/caixas", method: "GET" };
export const apiProjetosCaixasPOST = { url: URL_BASE + "/projetos/{id}/caixas", method: "POST" };
export const apiProjetosCaixasPUT = { url: URL_BASE + "/projetos/{id}/caixas", method: "PUT" };

export const apiIfoodAouthUserCode = { url: URL_INTEGRACOES_BASE + "/ifood/oauth/userCode", method: "POST" };
export const apiIfoodAouthUserToken = { url: URL_INTEGRACOES_BASE + "/ifood/oauth/token", method: "POST" };
export const apiIfoodMerchants = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants", method: "GET" };

export const apiIfoodMerchantsDetails = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants/{merchantId}", method: "GET" };
export const apiIfoodMerchantsStatus = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants/{merchantId}/status", method: "GET" };

export const apiIfoodMerchantsInterruptions = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants/{merchantId}/interruptions", method: "GET" };
export const apiIfoodPostMerchantsInterruptions = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants/{merchantId}/interruptions", method: "POST" };
export const apiIfoodDeleteMerchantsInterruptions = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants/{merchantId}/interruptions/{interruptionId}", method: "DELETE" };

export const apiIfoodMerchantsOpeningHours = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants/{merchantId}/opening-hours", method: "GET" };
export const apiIfoodPutMerchantsOpeningHours = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/merchants/{merchantId}/opening-hours", method: "PUT" };

export const apiIfoodEvents = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/events", method: "GET" };
export const apiIfoodDispatch = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/order/{orderId}/dispatch", method: "POST" };
export const apiIfoodReadToPickup = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/order/{orderId}/readyToPickup", method: "POST" };
export const apiIfoodConfirm = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/order/{orderId}/confirm", method: "POST" };
export const apiIfoodCancellationReasons = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/order/{orderId}/cancellationReasons", method: "GET" };
export const apiIfoodRequestCancellation = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/order/{orderId}/requestCancellation", method: "POST" };

export const apiIfoodDisputeAccept = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/order/disputes/{disputeId}/accept", method: "POST" };
export const apiIfoodDisputeReject = { url: URL_INTEGRACOES_BASE + "/ifood/{id}/order/disputes/{disputeId}/reject", method: "POST" };

export const apiMercadoPagoDevices = { url: URL_INTEGRACOES_BASE + "/mercadopago/point/integration-api/devices", method: "GET" };
export const apiMercadoPagoOperationMode = { url: URL_INTEGRACOES_BASE + "/mercadopago/point/integration-api/devices/{device_id}", method: "PATCH" };

export const apiMercadoPagoOperationCriarPagamento = { url: URL_INTEGRACOES_BASE + "/projetos/{id}/empresa/{idEmpresa}/point/integration-api/devices/{device_id}/payment-intents", method: "POST" };
export const apiMercadoPagoConfirmacaoPagamento = { url: URL_INTEGRACOES_BASE + "/projetos/{id}/payment-intents", method: "GET" };
export const apiMercadoPagoCancelamentoPagamento = { url: URL_INTEGRACOES_BASE + "/projetos/{id}/point/integration-api/devices/{device_id}/payment-intents/{paymentIntent}", method: "DELETE" };

export const apiMercadoPagoCriarConfiguracao = { url: URL_INTEGRACOES_BASE + "/empresas/{id}/configuracao/mercadopago", method: "POST" };
export const apiMercadoPagoBuscarConfiguracao = { url: URL_INTEGRACOES_BASE + "/empresas/{id}/configuracao/mercadopago", method: "GET" };

export async function getJSONObject(url: string) {
  const response = await fetch(url);
  const myJson = await response.json();
  return myJson;
}

export async function requestService(url: any, config: any, history: any, callbackSuccess: any, callbackError: any, redirectLogin?: boolean, getStatus?: boolean) {
  return await fetch(url, config).then(res => {
    if (res) {
      if (res.status === 401 && (redirectLogin || redirectLogin === undefined)) {
        if (history) history.replace("/login");
        res.json().then(response => {
          response.status = res.status;
          callbackError(response);
        });
      } else if (res.status === 200 || res.status === 201) {
        res.json().then(response => {
          if (getStatus)
            response.status = res.status
          callbackSuccess(response);
        });
      } else {
        res.json().then(response => {
          response.status = res.status;
          callbackError(response);
        });
      }
    }
  });

}