// import { Printer } from '@awesome-cordova-plugins/printer';
import { Toast } from '@capacitor/toast';
import { IonBadge, IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonPopover, IonRadio, IonRadioGroup, IonReorder, IonReorderGroup, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonTitle, IonToast, IonToggle, IonToolbar, isPlatform, ItemReorderEventDetail, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import imageCompression from 'browser-image-compression';
import { add, addCircle, arrowBack, closeOutline, copy, copyOutline, downloadOutline, ellipsisVertical, linkOutline, pencilOutline, qrCodeOutline, removeCircle, reorderTwo, star, trashOutline, unlinkOutline } from 'ionicons/icons';
import QRCode from 'qrcode';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
// import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';
import { alterarItemCatalogoProdutosEmpresa, assinaUrl, empresaCarregar, inserirItemCatalogoProdutosEmpresa, ordernarItemCatalogoProdutosEmpresa, removerItemCatalogoProdutosEmpresa, requestService, uploadCatalogoProdutosEmpresa } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import UploadArquivo from '../UploadArquivo/UploadArquivo';
import UploadIfood from '../UploadIfood/UploadIfood';
import "./ProdutosCatalogo.css";
import imgDefaultImage from './imgs/defaultImage.svg';

type Props = {
  recarregar: boolean;
  onDismiss: () => void;
};

const ProdutosCatalogo: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const location = useLocation<any>();

  const [showLoading, setShowLoading] = useState(false);
  const [listaItemCatalogoProdutos, setListaItemCatalogoProdutos] = useState<any>([]);

  const [showDetalhes, setShowDetalhes] = useState(false);
  const [indice, setIndice] = useState("");
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [id, setId] = useState("");
  const [internet, setInternet] = useState(false);

  const [controlarEstoque, setControlarEstoque] = useState(false);
  const [quantidadeEstoque, setQuantidadeEstoque] = useState("");
  const [quantidadeMinEstoque, setQuantidadeMinEstoque] = useState("");



  const [grupoComplementos, setGrupoComplementos]: any = useState([{ id: '', name: '', sizeMin: 0, sizeMax: 0, obrigatoriedade: '', itens: [{ id: '', name: '', price: 0 }] }]);
  const [items, setItems] = useState([{ name: '', price: 0 }]);

  const [codigo, setCodigo] = useState("");
  const [categoria, setCategoria] = useState("");
  const [objetoCategoria, setObjetoCategoria] = useState<any>();
  const [valor, setValor] = useState<any>();
  const [unidadeMedida, setUnidadeMedida] = useState("");


  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [listaCategoria, setListaCategoria] = useState<any>([]);

  const [isDisabled, setIsDisabled] = useState(true);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [fileDataURL, setFileDataURL] = useState<any>(null);
  const [fileArquivos, setFileArquivos] = useState<any>();
  const imageMimeType = /image\/(png|jpg|jpeg|avif|webp)/i;

  const inputRef = useRef<any>(null);

  const inputUploadArquivoRef = useRef<any>(null);

  const [showUploadArquivo, setShowUploadArquivo] = useState(false);
  const [showIfood, setShowIfood] = useState(false);
  const [urlIfood, setUrlIfood] = useState<any>();
  const [alertaPreenchimento, setAlertaPreenchimento] = useState(false);

  const [mostartAlerta] = useIonAlert();

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);



  // Estado para armazenar os valores como um array
  const [valores, setValores] = useState<string[]>([]);

  const [showPopover, setShowPopover] = useState(false);
  const [popoverEvent, setPopoverEvent] = useState(null);


  const [showPopoverGrid, setShowPopoverGrid] = useState(false);
  const [popoverEventGrid, setPopoverEventGrid] = useState(null);
  const [itemSelecionado, setItemSelecionado] = useState<any>(null); // Variável para armazenar o item clicado


  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const [etapa, setEtapa] = useState<any>("1");

  const [isItemValid, setIsItemValid] = useState(false);

  const contentNomeGrupoComplementoRef = useRef<any>(null);


  const [showModalQrCode, setShowModalQrCode] = useState(false);
  const [qrCodeData, setQRCodeData] = useState<string | null>(null);
  const [qrCode, setQRCode] = useState<any>();

  const [categoriaSelecionada, setCategoriaSelecionada] = useState(''); // Estado para armazenar a categoria

  const [busca, setBusca] = useState(''); // Estado para armazenar a categoria

  const [itemCatalogoParaComplementos, setItemCatalogoParaComplementos] = useState(''); // Estado para armazenar a categoria



  const openPopover = (e: any) => {
    e.persist();
    setPopoverEvent(e);
    setShowPopover(true);
  };

  const closePopover = () => {
    setShowPopover(false);
    setPopoverEvent(null);
  };


  const openPopoverGrid = (e: any, item: any) => {
    e.persist();
    setPopoverEventGrid(e); // Seta o evento para abrir o popover
    setItemSelecionado(item); // Seta o item clicado
    setShowPopoverGrid(true); // Abre o popover
  };

  const closePopoverGrid = () => {
    setShowPopoverGrid(false); // Fecha o popover
    setPopoverEventGrid(null); // Reseta o evento
    setItemSelecionado(null); // Reseta o item selecionado
  };



  let params: any = {
    recarregar: ""
  }
  params = useParams();

  if (location && location.state && location.state.recarregar) {
    params.recarregar = location.state.recarregar;
  }


  if (props.recarregar) {
    params = {
      recarregar: props.recarregar
    }
  }

  useEffect(() => {

    // console.log("*****************", fileArquivos);
    let fileReader: FileReader, isCancel = false;
    if (fileArquivos) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const result = e.target;
        if (result && !isCancel) {
          setFileDataURL(result.result);
          // atualizarTamanhoImagem();
        }
      }
      fileReader.readAsDataURL(fileArquivos);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [fileArquivos]);


  useEffect(() => {

    console.log("*****************carregarEmpresa");
    carregarEmpresa();
  }, []);

  function carregarEmpresa() {

    const consultar = async () => {

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setDadosEmpresa(response.Item);

          setListaItemCatalogoProdutos(response.Item.catalogoProdutos || []);
          setListaCategoria(response.Item.categoriaProdutos || []);
          
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");

    // setListaItemCatalogoProdutos(dadosEmpresa.catalogoProdutos || []);
    // setListaCategoria(dadosEmpresa.categoriaProdutos || []);

    // carregarConta();
  });


  // function carregarConta() {

  //   const consultar = async () => {

  //     //console.log("aasasasasa", loja);
  //     let id = loja;

  //     requestService(empresaAssinadoCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
  //       method: empresaAssinadoCarregar.method,
  //       headers: [
  //         ["token", window.localStorage.getItem("token")]
  //       ],
  //       credentials: 'include'
  //     },
  //       history,
  //       (response: any) => {
  //         // console.log(response);
  //         setListaItemCatalogoProdutos(response.Item.catalogoProdutos || []);
  //         setListaCategoria(response.Item.categoriaProdutos || []);
  //         setShowLoading(false);
  //       },
  //       (error: any) => {
  //         console.log(error);
  //         history.replace("/login/");
  //       });

  //   }

  //   consultar();
  // }


  const itensFiltrados = listaItemCatalogoProdutos
    .map((value: any, index: any) => ({ ...value, index })) // Preservar o índice original
    .filter((value: any) => {
      const categoriaFiltrada = categoriaSelecionada === '' || value.categoria === categoriaSelecionada;
      const buscaFiltrada = busca === '' || value.nome.toLowerCase().includes(busca.toLowerCase());

      return categoriaFiltrada && buscaFiltrada;
    });



  function deletarItemCatalogoProdutos(indice: any) {

    var token: string = "";
    var tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") { token = tkn; }

    const delItemCatalogoProdutos = async () => {
      setShowLoading(true);
      const resp = await fetch(removerItemCatalogoProdutosEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")),
        {
          method: removerItemCatalogoProdutosEmpresa.method,
          headers: [
            ["token", token]
          ],
          body: JSON.stringify({ 'indice': indice }),
          credentials: 'include'
        });
      const retorno = await resp.json();
      // console.log("retorno", retorno);

      if (retorno && retorno.Item?.Attributes) {
        setTextToast("Item do catálogo removido com sucesso.");
        setShowToast(true);
        setListaItemCatalogoProdutos(retorno.Item?.Attributes?.catalogoProdutos || []);
        dadosEmpresa.catalogoProdutos = retorno.Item?.Attributes?.catalogoProdutos;
        setDadosEmpresa(dadosEmpresa);

      }
      else {
        setTextToast("Ocorreu um erro ao efetuar a exclusão.");
        setShowToast(true);
      }
    }

    delItemCatalogoProdutos().finally(() => {
      setShowLoading(false);
    });

  }

  const agruparPorCategoria = (itens: any[], listaCategoria: any[]) => {
    // Agrupa os itens por categoria
    const agrupados = itens.reduce((acc: any, item: any) => {
      const categoria = item.categoria || 'Sem Categoria'; // Adiciona a categoria "Sem Categoria" se não houver uma definida
      if (!acc[categoria]) {
        acc[categoria] = [];
      }
      acc[categoria].push(item);
      return acc;
    }, {});

    // Cria um objeto com categorias na ordem da listaCategoria
    // Inclui somente categorias que têm itens
    const resultadoOrdenado = listaCategoria.reduce((acc: any, categoria: any) => {
      const nomeCategoria = categoria.nome || categoria; // Acessa o nome da categoria
      if (agrupados[nomeCategoria] && agrupados[nomeCategoria].length > 0) {
        acc[nomeCategoria] = agrupados[nomeCategoria];
      }
      return acc;
    }, {});

    // Adiciona "Sem Categoria" se houver itens sem uma categoria definida
    if (agrupados['Sem Categoria'] && agrupados['Sem Categoria'].length > 0) {
      resultadoOrdenado['Sem Categoria'] = agrupados['Sem Categoria'];
    }

    return resultadoOrdenado;
  };


  // const agruparPorCategoria = (itens: any[], listaCategoria: any[]) => {
  //   // Agrupa os itens por categoria
  //   const agrupados = itens.reduce((acc: any, item: any) => {
  //     const categoria = item.categoria || 'Sem Categoria';
  //     if (!acc[categoria]) {
  //       acc[categoria] = [];
  //     }
  //     acc[categoria].push(item);
  //     return acc;
  //   }, {});

  //   // Cria um objeto com categorias na ordem da listaCategoria, incluindo "Sem Categoria" se necessário
  //   const resultadoOrdenado = listaCategoria.reduce((acc: any, categoria: any) => {
  //     const nomeCategoria = categoria.nome || categoria; // Acessa o nome da categoria
  //     if (agrupados[nomeCategoria]) {
  //       acc[nomeCategoria] = agrupados[nomeCategoria];
  //     } else {
  //       acc[nomeCategoria] = [];
  //     }
  //     return acc;
  //   }, {});

  //   // Adiciona "Sem Categoria" se necessário
  //   if (agrupados['Sem Categoria']) {
  //     resultadoOrdenado['Sem Categoria'] = agrupados['Sem Categoria'];
  //   } else if (!resultadoOrdenado['Sem Categoria']) {
  //     resultadoOrdenado['Sem Categoria'] = [];
  //   }

  //   return resultadoOrdenado;
  // };



  function getButtonsGrid(value: any) {

    let resultado: any = [];

    if (acessos?.configuracoes == 'edicao') {


      resultado.push(
        {
          text: 'Editar',
          disabled: false,
          icon: pencilOutline,
          handler: () => {

            setIndice(value.indice);
            setNome(value.nome);
            setId(value.id);
            setDescricao(value.descricao);
            setFileArquivos(null);
            setFileDataURL(value.urlImagem || null);
            setInternet(value.internet);
            setControlarEstoque(value.controlarEstoque);
            setQuantidadeEstoque(value.quantidadeEstoque);
            setQuantidadeMinEstoque(value.quantidadeMinEstoque);
            setGrupoComplementos(value.grupoComplementos);
            setCodigo(value.codigo);
            setCategoria(value.categoria);




            let found;
            found = listaCategoria.find((obj: any) => {
              return obj.nome === value.categoria;
            });
            console.log("found", found);
            setObjetoCategoria(found);
            if (found.tipo == "pizzas") {

              console.log("value.valores", value.valores);

              if (value.valores && value.valores.length > 0) {
                // Use a função setState para atualizar o estado corretamente
                setValores(prevValores => {
                  // Cria uma cópia do estado anterior
                  const novosValores = [...prevValores];

                  // Itera sobre o array de valores para modificar o estado
                  value.valores.map((item: any, index: any) => {
                    // Atualiza o índice específico no array de novos valores
                    novosValores[index] = item.valor; // Se você quer o campo `valor` do item
                  });

                  // Retorna a nova array atualizada para setar o estado
                  return novosValores;
                });
              }


              setValor("");
            }
            else {
              setValor(value.valor);
              setValores([]);
            }

            setEtapa("1");
            setIsItemValid(false);
            setUnidadeMedida(value.unidadeMedida);
            setShowDetalhes(true);

          }
        });


      resultado.push(
        {
          text: 'Duplicar',
          disabled: false,
          icon: copyOutline,
          handler: () => {

            setIndice("");
            setNome(value.nome);
            setId(value.id);
            setDescricao(value.descricao);
            setFileArquivos(null);
            setFileDataURL(null);
            setInternet(value.internet);
            setControlarEstoque(value.controlarEstoque);
            setQuantidadeEstoque(value.quantidadeEstoque);
            setQuantidadeMinEstoque(value.quantidadeMinEstoque);
            setGrupoComplementos(value.grupoComplementos);
            setCodigo(value.codigo);
            setCategoria(value.categoria);

            let found;
            found = listaCategoria.find((obj: any) => {
              return obj.nome === value.categoria;
            });
            console.log("found", found);
            setObjetoCategoria(found);
            if (found.tipo == "pizzas") {

              console.log("value.valores", value.valores);

              if (value.valores && value.valores.length > 0) {
                // Use a função setState para atualizar o estado corretamente
                setValores(prevValores => {
                  // Cria uma cópia do estado anterior
                  const novosValores = [...prevValores];

                  // Itera sobre o array de valores para modificar o estado
                  value.valores.map((item: any, index: any) => {
                    // Atualiza o índice específico no array de novos valores
                    novosValores[index] = item.valor; // Se você quer o campo `valor` do item
                  });

                  // Retorna a nova array atualizada para setar o estado
                  return novosValores;
                });
              }


              setValor("");
            }
            else {
              setValor(value.valor);
              setValores([]);
            }

            setEtapa("1");
            setIsItemValid(false);
            setUnidadeMedida(value.unidadeMedida);
            setShowDetalhes(true);

          }
        });



      resultado.push(
        {
          text: 'Excluir',
          disabled: false,
          icon: trashOutline,
          handler: () => {
            mostartAlerta({
              backdropDismiss: false,
              header: "Remover item",
              message: "Deseja <strong>remover</strong>?",
              buttons: [
                { text: "Cancelar", role: "cancel" },
                {
                  text: "Confirmar",
                  cssClass: "primary",
                  handler: () => deletarItemCatalogoProdutos(value.indice),
                },
              ],
            });
          }
        }
      );
    }


    resultado.push(
      {
        text: 'Cancelar',
        disabled: false,
        icon: closeOutline,
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      });


    return resultado;
  }

  const loadItemCatalogoProdutos = () => {
    if (!listaItemCatalogoProdutos) return;

    // Agrupar itens por categoria
    const itensAgrupados = agruparPorCategoria(itensFiltrados, listaCategoria);

    if (listaItemCatalogoProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">
            <IonLabel>
              <h2 style={{ verticalAlign: "middle" }}>
                <strong>Nenhum item cadastrado</strong>
              </h2>
            </IonLabel>
          </IonItem>
        </>
      );
    }

    return (
      <>


        <IonPopover
          isOpen={showPopoverGrid}
          event={popoverEventGrid}
          onDidDismiss={closePopoverGrid}
        >
          <IonList>
            {getButtonsGrid(itemSelecionado).map((button: any, btnIndex: number) => (
              <IonItem key={btnIndex} lines="none" disabled={button.disabled} button onClick={() => {
                if (button.handler) button.handler();
                closePopoverGrid(); // Fecha o popover após a ação
              }}>
                <IonIcon icon={button.icon} slot="start" />
                <IonLabel>{button.text}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonPopover>


        {Object.keys(itensAgrupados).map((categoria, catIndex) => (
          <div key={"categoria-" + catIndex}>

            <IonItem lines="none">
              <IonLabel>
                <h2><b>{categoria}</b></h2>
              </IonLabel>
            </IonItem>



            {/* <div key={"categoria2-" + catIndex} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <IonItem lines="none" style={{ flex: 1 }}>
                <IonLabel>
                  <h2><b>{categoria}</b></h2>
                </IonLabel>
              </IonItem>
              <IonButton fill="outline" color="danger">
                <IonIcon slot="start" icon={add} />
                Adicionar item
              </IonButton>
            </div> */}
            <IonItem lines="none" style={{ fontWeight: 'bold', borderBottom: '1px solid #dddddd' }}>
              <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}></IonLabel>
              <IonLabel style={{ fontSize: "12px" }}>Nome</IonLabel>
              <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ fontSize: "12px" }}>Código</IonLabel>
              <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ fontSize: "12px" }}>Estoque</IonLabel>
              <IonLabel style={{ textAlign: "center", fontSize: "12px" }}>Valor</IonLabel>
              <IonLabel style={{ textAlign: "right", maxWidth: "60px" }}>
                <IonBadge hidden={acessos?.configuracoes != 'edicao'}
                  onClick={() => setIsDisabled((current) => !current)}>
                  <IonIcon color="medium" icon={reorderTwo}></IonIcon>
                </IonBadge>
              </IonLabel>
            </IonItem>
            <IonReorderGroup disabled={isDisabled} onIonItemReorder={(event) => handleReorder(event, categoria)}>
              {itensAgrupados[categoria].map((value: any, index: any) => (
                <IonItem lines="none" key={"produto-" + index}>
                  <IonLabel style={{ fontSize: "12px", maxWidth: "60px" }}>

                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      {/* Imagem */}
                      <img
                        id="imagem"
                        style={{
                          objectFit: 'cover',
                          width: "50px",
                          height: "50px",
                          borderRadius: '5px'
                        }}
                        src={value.urlImagem || imgDefaultImage}
                      />

                      {/* Ícone da estrela */}
                      <IonIcon
                        color="danger"
                        icon={star}
                        hidden={!value.internet}
                        style={{
                          position: 'absolute',
                          top: '2px',
                          right: '2px',
                          fontSize: '12px', // Ajuste o tamanho da estrela conforme necessário
                          zIndex: 1 // Certifica-se de que o ícone fique acima da imagem
                        }}
                      />
                    </div>
                  </IonLabel>
                  <IonLabel style={{ fontSize: "12px" }}>{value.nome}</IonLabel>
                  <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ fontSize: "12px" }}>{value.codigo}</IonLabel>
                  <IonLabel hidden={(!isPlatform('mobileweb') && isPlatform('mobile'))} style={{ fontSize: "12px" }}>{value.quantidadeEstoque}</IonLabel>

                  <IonLabel style={{ textAlign: "center", fontSize: "12px" }}>
                    {value.valor
                      ? value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      :
                      value.valores && Array.isArray(value.valores) ?
                        value.valores.map((item: any) => item.valor).join(' | ')
                        :
                        ""}
                  </IonLabel>

                  <IonLabel style={{ maxWidth: "60px" }}></IonLabel>



                  <div style={{ position: "absolute", right: "6px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>

                    <IonButtons slot="end">
                      <IonButton color="primary" onClick={(e) => openPopoverGrid(e, value)}>
                        <IonIcon slot="icon-only" icon={ellipsisVertical} />
                      </IonButton>
                    </IonButtons>

                  </div>

                  <div style={{ position: "absolute", right: "10px", zIndex: 9999999, backgroundColor: "#ffffff", textAlign: "right" }}>
                    <IonReorder slot="end"></IonReorder>
                  </div>

                </IonItem>
              ))}
            </IonReorderGroup>
          </div >
        ))}
      </>
    );

  };

  // Função de reordenação específica para cada categoria
  function handleReorder(event: CustomEvent<ItemReorderEventDetail>, categoria: string) {
    // Clonar a lista de produtos
    const novaLista = [...listaItemCatalogoProdutos];

    // Encontrar os itens da categoria
    const itensDaCategoria = novaLista.filter((item: any) => item.categoria === categoria);

    // Atualizar a posição dos itens dentro da categoria
    const itensReordenados = event.detail.complete(itensDaCategoria);

    // Substituir os itens da categoria pela nova ordem
    const listaAtualizada = novaLista.map((item: any) => {
      if (item.categoria === categoria) {
        return itensReordenados.shift();
      }
      return item;
    });

    // Atualizar o estado com a nova lista ordenada
    setListaItemCatalogoProdutos(listaAtualizada);

    // Realizar a ordenação no servidor
    const ordenarItens = async () => {
      setShowLoading(true);
      const token = localStorage.getItem("token") || "";
      const resp = await fetch(ordernarItemCatalogoProdutosEmpresa.url.replace("{id}", loja.replace(/#/g, "%23")), {
        method: ordernarItemCatalogoProdutosEmpresa.method,
        headers: [["token", token]],
        body: JSON.stringify({ catalogoProdutos: listaAtualizada }),
        credentials: "include",
      });
      const retorno = await resp.json();

      if (retorno?.Item?.Attributes) {
        // setTextToast("Ordenação alterada com sucesso.");
        // setShowToast(true);
        setListaItemCatalogoProdutos(retorno.Item.Attributes.catalogoProdutos);
        dadosEmpresa.catalogoProdutos = retorno.Item.Attributes.catalogoProdutos;
        setDadosEmpresa(dadosEmpresa);
      } else {
        setTextToast("Ocorreu um erro ao ordenar.");
        setShowToast(true);
      }

    };

    ordenarItens().finally(() => {
      setShowLoading(false);
    });
  }


  async function assinaUploadS3ArquivoESalvar(file: any) {
    const atualizar = async () => {

      try {
        // Ativa o loading
        setShowLoading(true);



        let fileParts = file && file.name ? file.name.split('.') : [];
        let fileNameArquivo = fileParts[0] || '';
        let fileTypeArquivo = fileParts[1] || '';

        let params = {
          nome: fileNameArquivo,
          tipo: fileTypeArquivo
        }

        let urlImagem = '', nomeImagem = '', tipoImagem = '';

        // console.log("file", file);
        if (file && file.name) {
          // Assina URL
          const resp = await fetch(assinaUrl.url.concat("?pasta=produtos-catalago&bucket=xeguei-mobile-publico"), {
            method: assinaUrl.method,
            headers: [
              ["token", localStorage.getItem("token") || ""]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

          const response = await resp.json();

          if (response.Item) {




            // const compressedFile = await imageCompression(file, {
            //   maxSizeMB: 0.1,
            //   maxWidthOrHeight: 600,
            //   useWebWorker: true,
            //   initialQuality: 0.5
            // });

            // console.log("Tamanho comprimido:", compressedFile.size);

            // console.log("fileTypeArquivo", fileTypeArquivo);

            const uploadToS3 = await fetch(response.Item.urlAssinado, {
              method: "PUT",
              headers: {
                "Content-Type": "image/" + fileTypeArquivo,
              },
              body: file,
            });

            if (uploadToS3.status === 200) {
              urlImagem = response.Item.urlPublica;
              nomeImagem = response.Item.fileName;
              tipoImagem = fileTypeArquivo;
            } else {
              throw new Error('Erro ao fazer upload do arquivo.');
            }
          } else {
            throw new Error('Erro ao assinar a URL.');
          }
        }

        let objetoUnido: any;
        if (objetoCategoria && objetoCategoria.tamanhos) {
          objetoUnido = {
            ...objetoCategoria,
            tamanhos: objetoCategoria.tamanhos.map((size: any, index: number) => ({
              ...size,
              valor: valores[index] || ''
            }))
          };
        }

        const objectRequest = {
          nome: nome,
          descricao: descricao,
          id: id,
          internet: internet,
          urlImagem: urlImagem,
          nomeImagem: nomeImagem,
          tipoImagem: tipoImagem,
          codigo: codigo,
          categoria: categoria,
          grupoComplementos: grupoComplementos,
          valor: valor,
          valores: objetoUnido && objetoUnido.tamanhos ? objetoUnido.tamanhos : [],
          tipo: objetoCategoria ? objetoCategoria.tipo : "simples",
          unidadeMedida: unidadeMedida,
          controlarEstoque: controlarEstoque,
          quantidadeEstoque: quantidadeEstoque,
          quantidadeMinEstoque: quantidadeMinEstoque,
          indice: indice || null
        };

        let url = indice ? alterarItemCatalogoProdutosEmpresa.url : inserirItemCatalogoProdutosEmpresa.url;

        await requestService(
          url.replace("{id}", loja.replaceAll("#", "%23")),
          {
            method: indice ? alterarItemCatalogoProdutosEmpresa.method : inserirItemCatalogoProdutosEmpresa.method,
            body: JSON.stringify(objectRequest),
            headers: [
              ["token", window.localStorage.getItem("token")]
            ],
            credentials: 'include'
          },
          history,
          (response: any) => {
            if (response) {
              setTextToast("Novo item cadastrado com sucesso.");
              setShowToast(true);

              setListaItemCatalogoProdutos(response.message.Attributes?.catalogoProdutos || []);
              dadosEmpresa.catalogoProdutos = response.message.Attributes?.catalogoProdutos;
              setDadosEmpresa(dadosEmpresa);

              setShowDetalhes(false);
            } else if (response.status === 400) {
              setTextToast("Ocorreu um erro ao efetuar a operação.");
              setShowToast(true);
            }
          },
          (error: any) => {
            setTextToast("Ocorreu um erro ao efetuar a operação.");
            setShowToast(true);
          }
        );
      } catch (error) {
        console.log("erro", error);
      }
    }
    await atualizar().finally(() => { setShowLoading(false) });
  }

  function sleep(miliseconds: number) {
    var currentTime = new Date().getTime();

    while (currentTime + miliseconds >= new Date().getTime()) {
    }
  }


  // Função para verificar se todos os campos foram preenchidos
  const todosCamposPreenchidos = (): boolean => {
    if (!objetoCategoria || !objetoCategoria.tamanhos) return false;

    // Verifica se todos os campos no array de tamanhos têm um valor correspondente preenchido
    return objetoCategoria.tamanhos.length === valores.length &&
      valores.every(valor => valor.trim() !== ''); // Todos os valores devem ser não vazios
  };

  const validateOpcoes = () => {
    let isValid = true;
    let errors: Array<{ message: string }> = [];

    // Validação dos grupoComplementos

    if (grupoComplementos && Array.isArray(grupoComplementos)) {
      grupoComplementos.forEach((grupoComplementos: any, groupIndex: any) => {
        if (!grupoComplementos.name) {
          isValid = false;
          errors.push({ message: `Nome do grupo de complementos no item ${groupIndex + 1} não pode estar vazio.` });
        }

        if (!grupoComplementos.obrigatoriedade) {
          isValid = false;
          errors.push({ message: `Obrigatoriedade do grupo de complementos no item ${groupIndex + 1} não pode estar vazio.` });
        }

        // console.log("grupoComplementos.sizeMin", grupoComplementos.sizeMin);

        // if (!grupoComplementos.sizeMin) {
        //   isValid = false;
        //   errors.push({ message: `Quantidade Mínima do grupo de complementos no item ${groupIndex + 1} não pode estar vazio.` });
        // }

        // if (!grupoComplementos.sizeMax) {
        //   isValid = false;
        //   errors.push({ message: `Quantidade Máxima do grupo de complementos no item ${groupIndex + 1} não pode estar vazio.` });
        // }

        // Validação dos itens dentro do grupo
        grupoComplementos.itens.forEach((item: any, itemIndex: any) => {
          if (!item.name) {
            isValid = false;
            errors.push({ message: `Nome do item no grupo de complementos ${groupIndex + 1}, item ${itemIndex + 1} não pode estar vazio.` });
          }

          if (!item.price) {
            isValid = false;
            errors.push({ message: `Preço do item no grupo de complementos ${groupIndex + 1}, item ${itemIndex + 1} não pode estar vazio.` });
          }
        });
      });
    }

    return { isValid, errors };
  };


  async function salvar() {

    // console.log("valores", valores);

    if (!validateItemForm()) {
      return; // Impede a mudança para a segunda etapa
    }
    const { isValid, errors } = validateOpcoes();

    if (!isValid && errors.length > 0) {
      console.log('Erros de validação:', errors);

      // Mapeia o array de erros e retorna um array de strings para o IonToast
      const errorMessages: any = errors.map((error) => `${error.message}`);
      setTextToast(errorMessages.join('<br>'));
      setShowToast(true);
      return;
    }

    await assinaUploadS3ArquivoESalvar(fileArquivos);












  }

  function readCategoria() {
    return listaCategoria.map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"categoria" + index} value={value.nome || value}>{value.nome || value}</IonSelectOption>
    )
    )
  };

  function readCatalogo() {
    // console.log("listaItemCatalogoProdutos", listaItemCatalogoProdutos);
    return listaItemCatalogoProdutos.filter((value: any) => {
      return value.tipo === "complementos";  // Corrigido: adicionado 'return'
    }).map((value: any, index: any, array: any) =>
    (
      <IonSelectOption key={"categoria" + index} value={value.id}>{value.nome || value}</IonSelectOption>
    )
    )
  };


  function onChangeQuantidadeEstoque(e: any) {

    e.persist();
    e.preventDefault();

    // Remove qualquer caractere que não seja número
    let v = e.target.value.replace(/\D/g, '');

    // Atualiza o valor apenas com números inteiros
    setQuantidadeEstoque(v);
  }


  function onChangeQuantidadeMinEstoque(e: any) {

    e.persist();
    e.preventDefault();

    // Remove qualquer caractere que não seja número
    let v = e.target.value.replace(/\D/g, '');

    // Atualiza o valor apenas com números inteiros
    setQuantidadeMinEstoque(v);
  }


  function onChangeValor(e: any) {

    e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    setValor(v);
  }

  function getButtons() {
    let resultado: any = [];
    if (acessos?.configuracoes == 'edicao') {

      // resultado.push({
      //   text: 'Aumento de preço',
      //   disabled: false,
      //   icon: arrowUp,
      //   handler: () => {

      //     const aumento = async () => {

      //       var token: string = "";
      //       var tkn = localStorage.getItem("token");
      //       if (typeof (tkn) === "string") { token = tkn; }


      //       let params = {
      //         valor: -15
      //       }

      //       try {

      //         setShowLoading(true);

      //         const resp = await fetch(aumentoCatalogoProdutosEmpresa.url.replaceAll("{id}", loja.replaceAll(/#/g, "%23")),
      //           {
      //             method: aumentoCatalogoProdutosEmpresa.method,
      //             headers: [
      //               ["token", token]
      //             ],
      //             body: JSON.stringify(params),
      //             credentials: 'include'
      //           });

      //         const response = await resp.json();

      //         console.log("response", response);
      //         if (response.message && response.message.Attributes) {

      //           Toast.show({
      //             text: "Aumento de preço com sucesso.",
      //             position: "center",
      //             duration: "long"
      //           });

      //           // dadosEmpresa.catalogoProdutos = response.message.Attributes?.catalogoProdutos;
      //           // setDadosEmpresa(dadosEmpresa);

      //         }
      //         else {
      //           Toast.show({
      //             text: response.message || "Ocorreu um erro ao realizar a importação.",
      //             position: "center",
      //             duration: "long"
      //           });
      //         }
      //         setShowLoading(false);

      //       } catch (error) {
      //         console.log("erro", error);
      //         Toast.show({
      //           text: "Ocorreu um erro ao realizar a importação.",
      //           position: "center",
      //           duration: "long"
      //         });
      //         setShowLoading(false);
      //       }
      //     }

      //     aumento();



      //   }
      // });


      resultado.push({
        text: 'Importar iFood ',
        disabled: false,
        icon: downloadOutline,
        handler: () => {

          mostartAlerta({
            backdropDismiss: false,
            header: 'Importar iFood',
            message: 'Todos os itens do catálogo serão <strong>excluídos</strong>. Deseja realmente continuar a importação?',
            buttons: [
              {
                text: 'Cancelar',
                role: 'cancel',
              },
              {
                text: 'Confirmar',
                cssClass: 'primary',
                handler: () => {
                  setShowIfood(true);
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss'),
          })


        }
      });

      // resultado.push({
      //   text: 'Importar',
      //   disabled: false,
      //   icon: downloadOutline,
      //   handler: () => {

      //     mostartAlerta({
      //       backdropDismiss: false,
      //       header: 'Importar novo catalogo',
      //       message: 'Todos os itens do catálogo serão <strong>excluídos</strong>. Deseja realmente continuar a importação?',
      //       buttons: [
      //         {
      //           text: 'Cancelar',
      //           role: 'cancel',
      //         },
      //         {
      //           text: 'Confirmar',
      //           cssClass: 'primary',
      //           handler: () => {
      //             setShowUploadArquivo(true);
      //           }
      //         }
      //       ],
      //       onDidDismiss: (e) => console.log('did dismiss'),
      //     })


      //   }
      // });

      resultado.push({
        text: 'Gerar QRCode',
        disabled: false,
        icon: qrCodeOutline,
        handler: async () => {



          generateQRCode();










        }
      });
    }

    resultado.push({
      text: 'Cancelar',
      disabled: false,
      icon: closeOutline,
      role: 'cancel',
      handler: () => {
        console.log('Cancel clicked');
      }
    });

    return resultado;
  }

  async function closeModalUploadArquivo() {
    setShowUploadArquivo(false);
  }

  async function closeModalUploadArquivoSucess(file: any, fileNameArquivo: any, fileTypeArquivo: any) {
    // console.log("file", file);
    // console.log("fileNameArquivo", fileNameArquivo);
    // console.log("fileTypeArquivo", fileTypeArquivo);


    let base64 = await getBase64(file);
    // Toast.show({
    //   text: "Aguarde a importação do arquivo.",
    //   position: "center",
    //   duration: "long"
    // });
    await UploadCatalogoAPI(base64, fileNameArquivo, fileTypeArquivo);

    setShowUploadArquivo(false);
  }


  async function closeModalIfood() {
    setShowIfood(false);
  }

  async function closeModalIfoodSuccess(dadosEmpresa: any) {

    setListaCategoria(dadosEmpresa.categoriaProdutos || []);
    setListaItemCatalogoProdutos(dadosEmpresa.catalogoProdutos || []);

    dadosEmpresa.categoriaProdutos = dadosEmpresa.categoriaProdutos;
    dadosEmpresa.catalogoProdutos = dadosEmpresa.catalogoProdutos;

    setDadosEmpresa(dadosEmpresa);

    setShowIfood(false);
  }


  async function getBase64(file: any) {


    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }



  async function UploadCatalogoAPI(file: any, fileNameArquivo: any, fileTypeArquivo: any) {


    const upload = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let params = {
        base64: file,
        nome: fileNameArquivo,
        tipo: fileTypeArquivo,
      }

      try {

        setShowLoading(true);

        const resp = await fetch(uploadCatalogoProdutosEmpresa.url.replaceAll("{id}", loja.replaceAll(/#/g, "%23")),
          {
            method: uploadCatalogoProdutosEmpresa.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(params),
            credentials: 'include'
          });

        const response = await resp.json();

        // console.log("response", response);
        if (response.message.Attributes) {

          Toast.show({
            text: "Arquivo foi importado com sucesso.",
            position: "center",
            duration: "long"
          });

          setListaItemCatalogoProdutos(response.message.Attributes?.catalogoProdutos || []);
          dadosEmpresa.catalogoProdutos = response.message.Attributes?.catalogoProdutos;
          setDadosEmpresa(dadosEmpresa);
        }
        else {
          Toast.show({
            text: response.message || "Ocorreu um erro ao realizar a importação. Verifique o formato e tente novamente.",
            position: "center",
            duration: "long"
          });
        }
        setShowLoading(false);

      } catch (error) {
        console.log("erro", error);
        Toast.show({
          text: "Ocorreu um erro ao realizar a importação. Verifique o formato e tente novamente.",
          position: "center",
          duration: "long"
        });
        setShowLoading(false);
      }
    }

    upload();

  };

  // Função para atualizar o valor do input no índice específico
  const onChangeValorArray = (e: any, index: number) => {
    // e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");

    const newValues = [...valores];
    newValues[index] = v;
    setValores(newValues);
  };

  // Metodo chamado quando é selecionado um arquivo para subida
  async function ChangeHandlerInputFile(e: any) {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match(imageMimeType)) {
        alert("Tipo da imagem inválido");
        return;
      }


      // if (fileArquivos && fileArquivos.size > 100000) {
      const fotoReduzida = await ReduzirFoto(file);
      console.log("Tamanho comprimido:", fotoReduzida.size);
      setFileArquivos(fotoReduzida);
      // }
      // else {
      //   setFileArquivos(file);
      // }




    }
    else {
      alert("Arquivo não selecionado");
      return;
    }
  }

  async function ReduzirFoto(file: File) {
    const options = {
      maxSizeMB: 0.05, // Reduzindo o tamanho máximo para 0.05 MB (50 KB)
      maxWidthOrHeight: 400, // Reduzindo a dimensão máxima para 400px
      useWebWorker: true,
      initialQuality: 0.5 // Reduzindo a qualidade inicial para 30%
    }

    return await imageCompression(file, options);
  }

  function atualizarTamanhoImagem() {
    var imagemLogo: any;
    imagemLogo = document.getElementById('imagem');
    var img = new Image();
    img.onload = function () {
      var proporcao = img.width / img.height;
      var novaLargura = 250 * proporcao;
      imagemLogo.style.width = novaLargura + 'px';
    };
    img.src = imagemLogo.src;

  }


  // Função para atualizar o valor do input no índice específico
  const onChangeSizeMinGrupoComplementosArray = (e: any, index: number) => {
    e.preventDefault();

    let v = e.target.value;

    // Remove qualquer coisa que não seja um dígito
    v = v.replace(/\D/g, '');


    const newValues = [...grupoComplementos];
    newValues[index] = { ...newValues[index], sizeMin: v }; // Mantém os outros valores do objeto
    setGrupoComplementos(newValues);
  };
  // Função para atualizar o valor do input no índice específico
  const onChangeSizeMaxGrupoComplementosArray = (e: any, index: number) => {
    e.preventDefault();

    let v = e.target.value;

    // Remove qualquer coisa que não seja um dígito
    v = v.replace(/\D/g, '');


    const newValues = [...grupoComplementos];
    newValues[index] = { ...newValues[index], sizeMax: v }; // Mantém os outros valores do objeto
    setGrupoComplementos(newValues);
  };



  const addGrupoComplementos = () => {

    setGrupoComplementos((prevGrupos: any) => {
      // Verifica se prevGrupos é um array; se não for, inicializa como um array vazio
      const gruposAtuais = Array.isArray(prevGrupos) ? prevGrupos : [];

      // Adiciona o novo grupo ao array
      return [
        ...gruposAtuais,
        {
          name: '',
          // id: uuidv4().replace(/-/g, ""),
          sizeMin: 0,
          sizeMax: 1,
          obrigatoriedade: 'opcional',
          itens: [{ name: '', price: 0 }]
        }
      ];
    });

    setTimeout(() => {
      if (contentNomeGrupoComplementoRef.current) {
        contentNomeGrupoComplementoRef.current.setFocus();
      }
    }, 200);
  };

  const updateGrupoComplementos = (index: number, field: string, value: any) => {
    const newGrupoComplementos: any = [...grupoComplementos];
    newGrupoComplementos[index][field] = value;
    setGrupoComplementos(newGrupoComplementos);
  };

  const removeGrupoComplementos = (index: number) => {
    const newGrupoComplementos = grupoComplementos.filter((_: any, i: any) => i !== index);
    setGrupoComplementos(newGrupoComplementos);
  };


  // Função para atualizar o valor do input no índice específico

  const onChangeValorItemArray = (e: any, groupIndex: number, itemIndex: number) => {
    e.preventDefault();

    let v = e.target.value;
    v = v.replace(/\D/g, ''); // Remove tudo que não é número
    v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2'); // Formata como decimal

    const newGrupoComplementos = [...grupoComplementos];
    newGrupoComplementos[groupIndex].itens[itemIndex] = {
      ...newGrupoComplementos[groupIndex].itens[itemIndex],
      price: v
    };

    setGrupoComplementos(newGrupoComplementos);
  };


  const addItem = (groupIndex: number) => {
    const newGrupoComplementos = [...grupoComplementos];
    newGrupoComplementos[groupIndex].itens.push({ name: '', price: 0 });
    setGrupoComplementos(newGrupoComplementos);
  };


  const selectedItem = (groupIndex: number, id: any, name: any, imageName: any, imageType: any, imageUrl: any) => {
    const newGrupoComplementos = [...grupoComplementos];
    newGrupoComplementos[groupIndex].itens.push({ id: id, name: name, price: 0, imageName: imageName, imageType: imageType, imageUrl: imageUrl });
    setGrupoComplementos(newGrupoComplementos);
  };



  // const selectItem = (groupIndex: number) => {
  //   const newGrupoComplementos = [...grupoComplementos];
  //   newGrupoComplementos[groupIndex].itens.push({ name: '', price: 0, id:  });
  //   setGrupoComplementos(newGrupoComplementos);
  // };


  const updateItem = (groupIndex: number, itemIndex: number, field: string, value: any) => {
    const newGrupoComplementos = [...grupoComplementos];
    newGrupoComplementos[groupIndex].itens[itemIndex][field] = value;
    setGrupoComplementos(newGrupoComplementos);
  };

  const removeItem = (groupIndex: number, itemIndex: number) => {
    const newGrupoComplementos = [...grupoComplementos];
    newGrupoComplementos[groupIndex].itens = newGrupoComplementos[groupIndex].itens.filter((_: any, i: any) => i !== itemIndex);
    setGrupoComplementos(newGrupoComplementos);
  };


  const scrollToTop = () => {
    contentRef.current && contentRef.current.scrollToTop();
  };

  const validateItemForm = () => {
    // Faça a validação aqui, por exemplo, verificar se todos os campos obrigatórios foram preenchidos
    let isValid = true;


    if (objetoCategoria && objetoCategoria.tipo == "pizzas") {

      if (!todosCamposPreenchidos()) {
        setTextToast("Todos os campos de Valores são obrigatórios.");
        setShowToast(true);
        isValid = false;
        setIsItemValid(isValid);
        return isValid;
      }
    }
    else {
      if (!valor || valor.trim() == "") {
        setTextToast("Campo Valor é obrigatório.");
        setShowToast(true);
        isValid = false;
        setIsItemValid(isValid);
        return isValid;
      }

    }


    if (controlarEstoque && (!quantidadeEstoque)) {
      setTextToast("Campo Estoque Atual é obrigatório.");
      setShowToast(true);
      isValid = false;
      setIsItemValid(isValid);
      return isValid;
    }

    if (controlarEstoque && (!quantidadeMinEstoque)) {
      setTextToast("Campo Estoque Mínimo é obrigatório.");
      setShowToast(true);
      isValid = false;
      setIsItemValid(isValid);
      return isValid;
    }



    if (!nome || nome.trim() === "") {
      setTextToast("O campo Nome é obrigatório.");
      setShowToast(true);
      isValid = false;
      setIsItemValid(isValid);
      return isValid;
    }

    // if (!codigo || codigo.trim() === "") {
    //     setTextToast("O campo Código é obrigatório.");
    //     setShowToast(true);
    //     return false;
    // }

    if (!categoria || categoria.trim() === "") {
      setTextToast("O campo Categoria é obrigatório.");
      setShowToast(true);
      isValid = false;
      setIsItemValid(isValid);
      return isValid;
    }

    if (!unidadeMedida || unidadeMedida.trim() === "") {
      setTextToast("O campo Unidade de Medida é obrigatório.");
      setShowToast(true);
      isValid = false;
      setIsItemValid(isValid);
      return isValid;
    }



    if (fileArquivos && fileArquivos.size > 5000000) {


      Toast.show({
        text: "Somente arquivos até 5 MB.",
        position: "center",
        duration: "long"
      });
      isValid = false;
    }
    setIsItemValid(isValid);
    return isValid;

  };

  const handleEtapaChange = (value: any) => {
    if (value === '2' && !validateItemForm()) {
      return; // Impede a mudança para a segunda etapa
    }
    if (value === '1') {
      setIsItemValid(false);
    }
    setEtapa(value);
    scrollToTop(); // Função para rolar a tela para o topo
  };


  const generateQRCode = async () => {
    try {
      // Gera o QR code como base64
      const qrData = await QRCode.toDataURL(`https://xegueimobile.com.br/catalogo.html?parceiro=${loja.replaceAll(/#/g, "%23")}`);
      setQRCodeData(qrData);
      setQRCode(`https://xegueimobile.com.br/catalogo.html?parceiro=${loja.replaceAll(/#/g, "%23")}`);
      setShowModalQrCode(true);
    } catch (err) {
      console.error('Erro ao gerar o QR Code', err);
    }
  };

  const handlePrint = () => {


    const contentHtml = `
    <html>
      <head>
        <style>
          body {
            font-family: Arial, sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            margin: 0;
          }
          .container-qrcode {
            text-align: center;
          }
          .header-qrcode h1 {
            color: #000;
            font-size: 24px;
            margin: 0;
          }
          .qr-code {
            margin: 20px 0;
          }
          .qr-code img {
            width: 250px;
            height: 250px;
          }
        </style>
      </head>
      <body>
        <div class="div-qrcode">
     
          <div class="qr-code">
            <img id="qrImage" src="${qrCodeData}" alt="QR Code">
          </div>
        </div>
      </body>
    </html>
  `;


    const content: HTMLElement = document.createElement('div');

    content.innerHTML = `${contentHtml}`;

    // if (!isPlatform('mobileweb') && isPlatform('mobile')) {
    //   // Para impressão nativa no mobile
    //   Printer.print(content, { name: 'QR Code', orientation: 'portrait' });
    // } else {



    try {
      // Gera o QR code como base64
      //const qrCodeData = await QRCode.toDataURL(`https://xegueimobile.com.br/catalogo.html?parceiro=${loja.replaceAll(/#/g, "%23")}`);

      // Abre uma nova janela para imprimir o QR code
      const printWindow: any = window.open('', '_blank');

      // Escreve o HTML na nova janela
      // printWindow.document.write(`<html><head><title>Print QR Code</title></head><body>`);
      // printWindow.document.write(`<img id="qrImage" src="${qrCodeData}" alt="QR Code" />`);
      // printWindow.document.write(`</body></html>`);

      printWindow.document.write(contentHtml);

      printWindow.document.close();

      // Aguarda o carregamento completo da imagem antes de imprimir
      const qrImage = printWindow.document.getElementById('qrImage') as HTMLImageElement;
      qrImage.onload = function () {
        printWindow.focus();  // Foca a janela
        printWindow.print();  // Chama a função de impressão
        printWindow.close();  // Fecha a janela de impressão após imprimir
      };

    } catch (err) {
      console.error('Erro ao gerar ou imprimir o QR Code', err);
    }

    // }
  };

  return (

    <IonPage className="ProdutosCatalogo">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack();/*.replace("/empresa/operacoes", { recarregar: false }); */ }}>
              <IonIcon slot="icon-only" icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Catálogo de produtos</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={openPopover}>
              <IonIcon slot="icon-only" icon={ellipsisVertical} />
            </IonButton>
          </IonButtons>

          <IonPopover
            isOpen={showPopover}
            event={popoverEvent}
            onDidDismiss={closePopover}
          >
            <IonList>
              {getButtons().map((button: any, index: number) => (
                <IonItem key={"popover" + index} lines="none" disabled={button.disabled} button onClick={() => {
                  if (button.handler) button.handler();
                  closePopover();
                }}>
                  <IonIcon icon={button.icon} slot="start" />
                  <IonLabel>{button.text}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </IonPopover>


        </IonToolbar>
        <IonToolbar>
          <IonList >

            <IonGrid style={{ padding: "0px", margin: "0px" }}>
              <IonRow style={{ alignItems: 'center' }}>

                <IonCol size="12" sizeMd="8" style={{ display: 'flex', justifyContent: 'flex-start', padding: "0px", margin: "0px" }}>
                  <IonSearchbar
                    placeholder="Buscar item"
                    value={busca}
                    onIonInput={(e) => setBusca(e.target.value!)}
                    debounce={500}
                    style={{ width: '100%' }}
                  />
                </IonCol>

                <IonCol size="12" sizeMd="4" style={{ display: 'flex', justifyContent: 'flex-end', padding: "0px", margin: "0px" }}>
                  <IonSelect
                    value={categoriaSelecionada}
                    placeholder="Selecione uma categoria"
                    onIonChange={(e) => setCategoriaSelecionada(e.detail.value)}
                    style={{ width: '100%' }}
                  >
                    <IonSelectOption key="categoriaNenhuma" value="">CATEGORIAS</IonSelectOption>
                    {listaCategoria.map((categoria: any, index: any) => (
                      <IonSelectOption key={index} value={categoria.nome}>
                        {categoria.nome}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
              </IonRow>
            </IonGrid>


          </IonList>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen={false}>
        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.configuracoes != 'edicao'}>
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => {
            setIndice("");
            setNome("");
            setId("");
            setDescricao("");

            setGrupoComplementos([]);

            setFileArquivos(null);
            setFileDataURL(null);
            // atualizarTamanhoImagem();

            setInternet(true);
            setControlarEstoque(false);
            setQuantidadeEstoque("");
            setQuantidadeMinEstoque("");
            setCodigo("");
            setCategoria("");
            setValor("");
            setObjetoCategoria("");
            setValores([]);
            setEtapa("1");
            setIsItemValid(false);
            setUnidadeMedida("unid");
            setShowDetalhes(true);
          }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>



        <IonList>

          {loadItemCatalogoProdutos()}



          {/* <IonFab horizontal="end" edge style={{ "top": "0px", "right": "-4px" }} hidden={acessos?.configuracoes != 'edicao'}>
            <IonFabButton size="small" color="tertiary" 
              <IonIcon color="light" icon={menu}></IonIcon>
            </IonFabButton>
          </IonFab> */}
        </IonList>


        {/* <IonButton slot="end" >Ordenar</IonButton> */}



        <IonModal className="fullscreen-modal" onDidPresent={() => {
          if (inputRef.current) {
            inputRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => {
          setShowDetalhes(false);
        }}>


          <>
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons slot="start">
                  <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowDetalhes(false)} />
                </IonButtons>
                <IonTitle>Novo item</IonTitle>
              </IonToolbar>
              <IonToolbar style={{ height: "48px" }}>
                <IonSegment value={etapa} onIonChange={(e) => handleEtapaChange(e.detail.value)}>
                  <IonSegmentButton style={{ height: "30px" }} value="1">
                    <IonLabel style={{ fontSize: "14px" }}>Item</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    style={{ height: "30px" }}
                    value="2"
                    className={!isItemValid ? "segment-button-disabled" : ""}
                  >
                    <IonLabel style={{ fontSize: "14px" }}>Complementos</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </IonToolbar>
            </IonHeader>
            <IonContent>

              {etapa === '1' && (
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonList lines="full">




                        {/* Nome */}
                        <IonItem>
                          <IonLabel position="stacked">Nome*</IonLabel>
                          <IonInput
                            placeholder="Digite a nome"
                            value={nome}
                            ref={(ref) => inputRef.current = ref}
                            autoCorrect='true' autoCapitalize='true'
                            required
                            onIonInput={(e: any) => setNome(e.target.value)}
                          />
                        </IonItem>

                        <IonItem>
                          <IonLabel position="stacked">Descrição</IonLabel>
                          <IonInput
                            placeholder="Digite a descrição"
                            value={descricao}
                            autoCorrect='true' autoCapitalize='true'
                            required
                            onIonInput={(e: any) => setDescricao(e.target.value)}
                          />
                        </IonItem>

                        {/* Codigo */}
                        <IonItem>
                          <IonLabel position="stacked">Código PDV</IonLabel>

                          <IonInput
                            placeholder="Digite a código"
                            value={codigo}

                            autoCorrect='true' autoCapitalize='true'
                            required
                            onIonInput={(e: any) => setCodigo(e.target.value)}
                          />

                        </IonItem>


                        <IonItem lines="none">

                          <IonLabel position="stacked" style={{ marginBottom: '10px' }}>Imagem</IonLabel>


                          <IonItem lines='none'>
                            <input onChange={ChangeHandlerInputFile} type="file" accept="image/*" />
                          </IonItem>
                          <img id="imagem" style={{ objectFit: 'cover', width: "180px", height: "180px", borderRadius: '15px' }} src={fileDataURL || imgDefaultImage}></img>

                        </IonItem>

                        {/* Categoria */}
                        <IonItem>
                          <IonLabel position="stacked">Categoria*</IonLabel>
                          <IonSelect

                            value={categoria}
                            placeholder="Selecione"
                            onIonChange={e => {
                              setCategoria(e.detail.value);



                              let found;

                              found = listaCategoria.find((obj: any) => {
                                return obj.nome === e.detail.value;
                              });

                              console.log("found", found);

                              setObjetoCategoria(found);


                              if (found.tipo == "pizzas") {
                                setValores(Array(found?.tamanhos.length).fill(''));
                                setValor("");
                              }
                              else {
                                setValores([]);
                              }


                            }}
                          >
                            {readCategoria()}
                          </IonSelect>
                        </IonItem>


                        {(objetoCategoria && objetoCategoria.tipo == "pizzas") ? (
                          <IonGrid>

                            {objetoCategoria.tamanhos.map((size: any, index: any) => (

                              <IonRow>
                                <IonCol key={"tam" + index}>
                                  <IonItem lines="none">
                                    <IonLabel position="stacked">Valor {size.name}*</IonLabel>

                                    <IonInput
                                      type="tel"
                                      maxlength={14}
                                      placeholder="Digite o valor"
                                      value={valores[index] || ''}
                                      onIonChange={(e: any) => onChangeValorArray(e, index)}
                                    />
                                  </IonItem>

                                </IonCol>
                              </IonRow>
                            ))}

                          </IonGrid>
                        ) : (

                          <IonItem>
                            <IonLabel position="stacked">Valor*</IonLabel>
                            <IonInput type="tel" maxlength={14} placeholder="Digite o valor" value={valor}
                              onKeyUp={onChangeValor} />
                          </IonItem>

                        )}

                        <IonItem>
                          <IonLabel position="stacked">Unidade de medida*</IonLabel>
                          <IonSelect
                            value={unidadeMedida}
                            placeholder="Selecione"
                            onIonChange={e => { setUnidadeMedida(e.detail.value); }}
                          >
                            <IonSelectOption key="kg" value="kg">Quilograma (kg)</IonSelectOption>
                            <IonSelectOption key="m" value="m">Metro (m)</IonSelectOption>
                            <IonSelectOption key="m2" value="m2">Metro (m²)</IonSelectOption>
                            <IonSelectOption key="unid" value="unid">Unidade (unid)</IonSelectOption>
                          </IonSelect>
                        </IonItem>

                        <IonItem lines="none">

                          <IonLabel>Exibir no catálogo digital? <IonIcon
                            color="danger"
                            icon={star}


                          /></IonLabel>

                          <IonToggle slot="end"
                            checked={internet}
                            onIonChange={(e: any) => setInternet(e.detail.checked)}
                          />
                        </IonItem>

                        <IonItem lines="none">

                          <IonLabel>Controlar estoque?</IonLabel>

                          <IonToggle slot="end"
                            checked={controlarEstoque}
                            onIonChange={(e: any) => {
                              setControlarEstoque(e.detail.checked);
                              if (!e.detail.checked) {
                                setQuantidadeEstoque("");
                                setQuantidadeMinEstoque("");
                              }
                            }}
                          />
                        </IonItem>

                        <IonItem hidden={!controlarEstoque} >
                          <IonLabel position="stacked">Estoque Atual</IonLabel>

                          <IonInput type="tel" maxlength={14} placeholder="Digite a quantidade" value={quantidadeEstoque}
                            onKeyUp={onChangeQuantidadeEstoque} />

                        </IonItem>


                        <IonItem hidden={!controlarEstoque} >
                          <IonLabel position="stacked">Estoque Mínimo</IonLabel>

                          <IonInput type="tel" maxlength={14} placeholder="Digite a quantidade" value={quantidadeMinEstoque}
                            onKeyUp={onChangeQuantidadeMinEstoque} />

                        </IonItem>


                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}

              {etapa === '2' && (
                <IonGrid>
                  {/* <IonRow className="ion-justify-content-center">
                    <IonCol size="auto">
                      <div style={{ padding: '10px', textAlign: 'center' }}>
                        <p><b>Grupo de Complementos</b></p>
                      </div>
                    </IonCol>
                  </IonRow> */}
                  <IonRow>
                    <IonCol>
                      <IonButton fill="clear" color="primary" onClick={addGrupoComplementos}>
                        + Adicionar grupo de complementos
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {grupoComplementos?.map((grupo: any, groupIndex: any) => (
                    <IonCard key={groupIndex}>
                      <IonCardContent>
                        <IonGrid style={{ padding: "0px", margin: "0px" }}>
                          <IonRow>
                            <IonCol size="10" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel position="stacked"><b>Nome do grupo de complementos</b></IonLabel>
                                <IonInput ref={(ref) => contentNomeGrupoComplementoRef.current = ref}
                                  value={grupo.name}
                                  placeholder="ex: Acompanhamentos"
                                  onIonInput={(e: any) => updateGrupoComplementos(groupIndex, 'name', e.target.value)}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol size="2" style={{ padding: "0px", margin: "0px", textAlign: 'right' }}>
                              <IonButton fill="clear" color="danger" onClick={() => removeGrupoComplementos(groupIndex)}>
                                <IonIcon icon={trashOutline} />
                              </IonButton>
                            </IonCol>
                          </IonRow>

                          <IonRow>
                            <IonCol style={{ padding: "0px", margin: "0px" }}>

                              <IonItem lines="none">
                                <IonLabel position="stacked"><b>Obrigatoriedade</b></IonLabel>
                              </IonItem>

                              <IonItem lines="none">
                                Indique se a escolha de complementos é opcional ou obrigatória para completar o pedido.
                              </IonItem>
                              <IonList>
                                <IonRadioGroup
                                  value={grupo.obrigatoriedade}
                                  onIonChange={e => {
                                    updateGrupoComplementos(groupIndex, 'obrigatoriedade', e.detail.value);

                                    if (grupo.obrigatoriedade == "opcional") {
                                      let sizeMinNovo = 0;
                                      updateGrupoComplementos(groupIndex, 'sizeMin', sizeMinNovo);
                                    }
                                    else {
                                      let sizeMinNovo = 1;
                                      updateGrupoComplementos(groupIndex, 'sizeMin', sizeMinNovo);
                                    }
                                  }}
                                >
                                  <IonItem lines="none">
                                    <IonLabel>Opcional</IonLabel>
                                    <IonRadio slot="start" value="opcional" />
                                  </IonItem>

                                  <IonItem lines="none">
                                    <IonLabel>Obrigatório</IonLabel>
                                    <IonRadio slot="start" value="obrigatorio" />
                                  </IonItem>
                                </IonRadioGroup>
                              </IonList>
                            </IonCol>
                          </IonRow>

                          <IonRow>
                          <IonCol>
                            <div>
                              <IonLabel><b>Quantidade</b></IonLabel>
                              <p>Indique quantos complementos devem ser selecionados no pedido.</p>
                            </div>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size="4">
                            <IonLabel>Mínimo</IonLabel>
                          </IonCol>

                          <IonCol size="4">
                            <IonLabel>Máximo</IonLabel>
                          </IonCol>
                        </IonRow>

                        <IonRow>
                          <IonCol size="4">

                            <IonChip style={{ margin: 0, padding: '5px' }}>
                              <IonIcon
                                size='large'
                                color='danger'
                                icon={removeCircle}
                                onClick={() => {
                                  const sizeMinFormatado = parseFloat(grupo.sizeMin.toString().replace(/\./g, '').replace(/\,/g, '.'));
                                  if (sizeMinFormatado > 0 && grupo.obrigatoriedade == "opcional") {
                                    let sizeMinNovo = sizeMinFormatado - 1;
                                    updateGrupoComplementos(groupIndex, 'sizeMin', sizeMinNovo.toLocaleString('pt-br', {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    }));
                                  }
                                  else if (sizeMinFormatado > 1 && grupo.obrigatoriedade == "obrigatorio") {
                                    let sizeMinNovo = sizeMinFormatado - 1;
                                    updateGrupoComplementos(groupIndex, 'sizeMin', sizeMinNovo.toLocaleString('pt-br', {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    }));
                                  }


                                }}
                              />
                              <IonLabel style={{ fontSize: '20px' }}>
                                {grupo.sizeMin.toLocaleString('pt-br', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0
                                })}
                              </IonLabel>
                              <IonIcon
                                size='large'
                                color='success'
                                icon={addCircle}
                                onClick={() => {
                                  const sizeMinFormatado = parseFloat(grupo.sizeMin.toString().replace(/\./g, '').replace(/\,/g, '.'));

                                  const sizeMaxFormatado = parseFloat(grupo.sizeMax.toString().replace(/\./g, '').replace(/\,/g, '.'));
                                  if (sizeMaxFormatado > sizeMinFormatado) {

                                    let sizeMinNovo = sizeMinFormatado + 1;
                                    updateGrupoComplementos(groupIndex, 'sizeMin', sizeMinNovo.toLocaleString('pt-br', {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    }));
                                  }
                                }}
                              />
                            </IonChip>
                          </IonCol>

                          <IonCol size="4">

                            <IonChip style={{ margin: 0, padding: '5px' }}>
                              <IonIcon
                                size='large'
                                color='danger'
                                icon={removeCircle}
                                onClick={() => {
                                  const sizeMaxFormatado = parseFloat(grupo.sizeMax.toString().replace(/\./g, '').replace(/\,/g, '.'));
                                  if (sizeMaxFormatado > 1) {
                                    const sizeMinFormatado = parseFloat(grupo.sizeMin.toString().replace(/\./g, '').replace(/\,/g, '.'));

                                    if (sizeMaxFormatado > sizeMinFormatado) {
                                      let sizeMaxNovo = sizeMaxFormatado - 1;
                                      updateGrupoComplementos(groupIndex, 'sizeMax', sizeMaxNovo.toLocaleString('pt-br', {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                      }));
                                    }
                                  }
                                }}
                              />
                              <IonLabel style={{ fontSize: '20px' }}>
                                {grupo.sizeMax.toLocaleString('pt-br', {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0
                                })}
                              </IonLabel>
                              <IonIcon
                                size='large'
                                color='success'
                                icon={addCircle}
                                onClick={() => {
                                  const sizeMaxFormatado = parseFloat(grupo.sizeMax.toString().replace(/\./g, '').replace(/\,/g, '.'));
                                  let sizeMaxNovo = sizeMaxFormatado + 1;
                                  updateGrupoComplementos(groupIndex, 'sizeMax', sizeMaxNovo);
                                }}
                              />
                            </IonChip>
                          </IonCol>
                        </IonRow>


                          <IonRow className="ion-justify-content-center">
                            <IonCol size="auto" style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                <IonLabel><b>Itens do complemento</b></IonLabel>
                              </IonItem>
                            </IonCol>
                          </IonRow>
                          <IonRow>

                            <IonCol style={{ padding: "0px", margin: "0px" }}>
                              <IonItem lines="none">
                                Adicione itens sem vínculo ou selecione produtos cadastrados como complementos (ideal para controle de estoque). O preço será considerado como um adicional ao item principal.  Se não houver valor adicional, considere R$ 0,00.
                              </IonItem>

                              <IonItem lines="none">
                                <IonLabel position="stacked">Adicionar ou selecionar item</IonLabel>
                                <IonSelect
                                  value={itemCatalogoParaComplementos}
                                  placeholder="Selecione"
                                  onIonChange={(e) => {
                                    const selectedId = e.detail.value;

                                    // Se o valor selecionado for 'addItem', chama a função de adicionar item
                                    if (selectedId === 'addItem') {
                                      addItem(groupIndex);
                                    } else {
                                      // Encontra o item no catálogo
                                      const found = listaItemCatalogoProdutos.find((obj: any) => obj.id === selectedId);

                                      // Se encontrou, seleciona o item
                                      if (found) {
                                        selectedItem(groupIndex, found.id, found.nome, found.nomeImagem, found.tipoImagem, found.urlImagem);
                                      }

                                      setItemCatalogoParaComplementos("");
                                    }
                                  }}
                                >
                                  {/* Opção de adicionar um novo item */}
                                  <IonSelectOption value="addItem">Adicionar (sem vínculo)</IonSelectOption>

                                  {/* Renderiza os itens do catálogo */}
                                  {readCatalogo()}
                                </IonSelect>
                              </IonItem>
                            </IonCol>

                          </IonRow>
                          <IonRow>
                            <IonCol>
                              {grupo.itens?.map((item: any, itemIndex: any) => (
                                <IonCard key={itemIndex}>
                                  <IonCardContent>
                                    <IonGrid style={{ padding: "0px", margin: "0px" }}>
                                      <IonRow>
                                        <IonCol size="10" sizeMd="11" style={{ padding: "0px", margin: "0px" }}>
                                          <IonGrid style={{ padding: "0px", margin: "0px" }}>
                                            <IonRow>
                                              <IonCol sizeMd="8" size="12" style={{ padding: "0px", margin: "0px" }}>
                                                <IonItem lines="none">

                                                  <IonLabel position="stacked">Nome do item* <IonIcon icon={item.id ? linkOutline : unlinkOutline}></IonIcon></IonLabel>
                                                  <IonInput
                                                    value={item.name}
                                                    placeholder="Ex: Batata frita"
                                                    onIonInput={(e: any) => updateItem(groupIndex, itemIndex, 'name', e.target.value)}
                                                  />
                                                </IonItem>
                                              </IonCol>

                                              <IonCol sizeMd="4" size="12" style={{ padding: "0px", margin: "0px" }}>
                                                <IonItem lines="none">
                                                  <IonLabel position="stacked">Preço*</IonLabel>
                                                  <IonInput
                                                    type="tel"
                                                    maxlength={14}
                                                    placeholder="Digite o valor"
                                                    value={item.price || ''}
                                                    onIonChange={(e: any) => onChangeValorItemArray(e, groupIndex, itemIndex)}
                                                  />
                                                </IonItem>
                                              </IonCol>
                                            </IonRow>
                                          </IonGrid>
                                        </IonCol>

                                        <IonCol
                                          size="2"
                                          sizeMd="1"
                                          style={{
                                            padding: "0px", margin: "0px",
                                            textAlign: 'right',
                                            display: 'flex',
                                            alignItems: 'flex-start', // Alinhar ao topo
                                            justifyContent: 'flex-start',
                                          }}
                                        >
                                          <IonButton fill="clear" color="danger" onClick={() => removeItem(groupIndex, itemIndex)}>
                                            <IonIcon icon={trashOutline} />
                                          </IonButton>
                                        </IonCol>
                                      </IonRow>


                                    </IonGrid>
                                  </IonCardContent>
                                </IonCard>
                              ))}
                            </IonCol>
                          </IonRow>
                        </IonGrid>

                      </IonCardContent>
                    </IonCard>
                  ))}
                </IonGrid>


              )}
            </IonContent>
            <IonFooter className="ion-no-border" hidden={etapa != '1'}>
              <IonToolbar>
                <IonButton onClick={() => handleEtapaChange('2')} expand="block" type="submit">Avançar</IonButton>
              </IonToolbar>
            </IonFooter>

            <IonFooter className="ion-no-border" hidden={etapa != '2'}>
              <IonToolbar>
                <IonButton onClick={() => salvar()} expand="block" type="submit">Cadastrar</IonButton>
              </IonToolbar>
            </IonFooter>
          </>

        </IonModal>



        {/* Modal para visualizar o QR Code */}
        <IonModal isOpen={showModalQrCode} onDidDismiss={() => {
          setShowModalQrCode(false);
        }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons slot="start">
                <IonIcon slot="icon-only" icon={arrowBack} onClick={() => setShowModalQrCode(false)} />
              </IonButtons>
              <IonTitle>QRCode</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="div-qrcode">
              <div className="qr-code">
                {qrCodeData && <img src={qrCodeData} alt="QR Code" />}
              </div>
              <div className="copy-code-catalogo-prod">
                {/* Substituído por IonTextarea */}
                {qrCode}
                <IonButton fill="clear" onClick={() => {
                  navigator.clipboard.writeText(qrCode);
                  setTextToast("Endereço copiado com sucesso.");
                  setShowToast(true);
                }}>
                  <IonIcon color="primary" icon={copy} />
                </IonButton>
              </div>

            </div>
          </IonContent>

          <IonFooter className="ion-no-border" >
            <IonToolbar>
              <IonButton expand="block" size='large' hidden={!isPlatform('mobileweb') && isPlatform('mobile')} onClick={() => { handlePrint(); }}>Imprimir</IonButton>
            </IonToolbar>
          </IonFooter>

        </IonModal>

        <IonModal onDidPresent={() => {
          if (inputUploadArquivoRef.current) {
            inputUploadArquivoRef.current.setFocus();
          }
        }} isOpen={showUploadArquivo} onDidDismiss={() => { setShowUploadArquivo(false); }}>
          <UploadArquivo accept="text/csv" inputRef={inputUploadArquivoRef} closeAction={closeModalUploadArquivoSucess} closeActionSimple={closeModalUploadArquivo}></UploadArquivo>
        </IonModal>


        <IonModal isOpen={showIfood} onDidDismiss={() => { setShowIfood(false); }}>
          <UploadIfood closeAction={closeModalIfoodSuccess} closeActionSimple={closeModalIfood}></UploadIfood>
        </IonModal>

        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>

    </IonPage >
  );


};

export default ProdutosCatalogo;