// import '@capacitor-community/http';
import { Plugins } from '@capacitor/core';
import { IonContent, IonFab, IonFabButton, IonIcon, IonPage, IonToast, isPlatform } from '@ionic/react';
import { logoWhatsapp } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PromotionBanner from '../../../components/PromotionBanner';
import { logar, requestService } from '../../../Utils/Services';
import './LoginConvite.css';

const LoginConvite: React.FC = () => {
  const { Http } = Plugins;
  const history = useHistory();
  const [telefone, setTelefone] = useState("");
  const [senha, setSenha] = useState("");
  const [cpf, setCPF] = useState("");
  const [primeiroFoco, setPrimeiroFoco] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const [botaoAtivo, setBotaoAtivo] = useState(true);
  const [textBotao, setTextBotao] = useState("Próximo");
  const campoCPF = useRef<any>(null);
  const campoTelefone = useRef<any>(null);
  const campoSenha = useRef<any>(null);
  const TAB_KEY_CODE = 9;
  const ENTER_KEY_CODE = 13;
  const [convite, setConvite] = useState("");
  const [desconto, setDesconto] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const convite = urlParams.get('convite') || ""; // Obtém o valor do parâmetro 'convite'
    console.log("Valor do convite:", convite);
    setConvite(convite);

    setDesconto("0");
    if (convite && convite.substr(-2) === "20") {
      setDesconto("20");
    }
    else if (convite && (convite.substr(-2) === "50")) {
      setDesconto("50");
    }
    else if (convite && (convite.toLowerCase().substr(-2) === "ml")) {
      setDesconto("75");
    }
  }, []);

  function onChangeCPF(e: any) {

    setPrimeiroFoco(true);
    e.persist();
    e.preventDefault();

    if (e.keyCode === ENTER_KEY_CODE || e.keyCode === TAB_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      campoTelefone.current.setFocus();
    }

    if (e.target.value.length > 14) {
      e.target.value = e.target.value.substring(0, 14);
      setCPF(e.target.value.substring(0, 14));
      return;
    }

    let val = e.target.value;
    var cpfval = val.replace(/\D/g, "")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d)/, "$1.$2")
    cpfval = cpfval.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    setCPF(cpfval);
  }

  const logarEvent = () => {
    if (botaoAtivo) {
      setBotaoAtivo(false);
      setTextBotao("Aguarde...");

      let usuarioCPF = cpf.replace("-", "").replace(".", "").replace(".", "");

      const objectRequest = {
        telefone: "55" + telefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", ""),
        dispositivoIos: isPlatform('ios')
      }

      requestService(
        logar.url.replace("{cpf}", usuarioCPF),
        {
          method: logar.method,
          headers: [
            ["token", localStorage.getItem("token") || ""]
          ],
          body: JSON.stringify(objectRequest),
        },
        history,
        (response: any) => {
          if (response) {

            window.localStorage.setItem("cpf", usuarioCPF);
            window.localStorage.setItem("token", response.token);
            window.localStorage.setItem("perfil", response.perfil);

            window.localStorage.setItem("telefone", "55" + telefone.replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").replace(/\-/g, ""));

            if (response.perfil) {
              // window.localStorage.removeItem("lojaSelecionada");
              setCPF("");
              setSenha("");
              setTelefone("");
              setBotaoAtivo(true);
              setTextBotao("Entrar");

              history.push("/validar-otp");
            }
            else {
              history.replace("/empresa/inicio");
              //!(!isPlatform('mobileweb') && isPlatform('mobile')) ? history.replace("/empresa/painel") : history.replace("/empresa/inicio")
            }
          } else if (response.status === 400) {

          }
        },
        (error: any) => {
          setTextToast("Informações inválidas");
          setShowToast(true);
          setBotaoAtivo(true);
          setTextBotao("Entrar");
        }
      )
    }
  }

  function onKeyUpTelefone(e: any) {
    e.persist();
    e.preventDefault();


    if (e.keyCode === ENTER_KEY_CODE) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      logarEvent();
    }

    if (e.target.value.length > 15) {
      e.target.value = e.target.value.substring(0, 15);
      setTelefone(e.target.value.substring(0, 15));
      return;
    }

    let val = e.target.value;
    var telVal = val.replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})(\d+?)$/, "$1");
    setTelefone(telVal);
  }

  const handleButtonClick = () => {
    history.push("/tipoconta" + (convite ? "?convite=" + convite : ""));
  };


  return (
    <IonPage>
      <IonContent className={"LoginConvite"}>
        {/* <div id="blocoTopo"></div>
        <img src={imgTopo} alt="Logo" />
        <IonLabel className="titulo">Acesse aqui o Xeguei</IonLabel>
        <div className="input">
          <span>CPF</span>
          <IonInput type="tel" maxlength={14} placeholder="___.___.___-__" required value={cpf} onKeyUp={onChangeCPF} onIonInput={(e: any) => setCPF(e.target.value)} />
        </div>
        <div className="input">
          <span>Telefone</span>
          <IonInput ref={(ref) => campoTelefone.current = ref} type="tel" maxlength={15} placeholder="(__) _____-____" required value={telefone} onKeyUp={onKeyUpTelefone} onIonInput={(e: any) => setTelefone(e.target.value)} />
        </div>
        <IonButton disabled={!botaoAtivo} onClick={() => { logarEvent(); }}>{textBotao}</IonButton>

        <div className="divisoria">
          <span></span>

        </div> */}

        <IonFab vertical="bottom" horizontal="end" slot="fixed">

          <IonFabButton color="success" class="custom-fab-button" onClick={() => {

            window.open("https://api.whatsapp.com/send?phone=5511963927646&text=Olá,%20recebi%20o%20convite%20" + convite + ".%20Gostaria%20de%20obter%20mais%20informações%20sobre%20o%20sistema%20XEGUEI%20que%20vocês%20oferecem.%20Poderiam%20me%20ajudar?", "_blank");


          }} >
            <IonIcon size="large" color="light" icon={logoWhatsapp}></IonIcon>
          </IonFabButton>




        </IonFab>

        <div>
          <PromotionBanner
            title="Super Promoção"
            subTitle={`${desconto}%`}
            subTitle2={`Em todas mensalidades!`}
            description="Aproveite nossa oferta especial por tempo limitado!"
            buttonText="Testar grátis por 30 dias"
            buttonAction={handleButtonClick}
          />
        </div>

        {/* <IonButton expand='block' fill="solid" color="tertiary" onClick={() => { history.push("/tipoconta" + (convite ? "?convite=" + convite : "")); }}>Testar grátis por 30 dias</IonButton> */}
        {/* <div className="input">
          <IonLabel> *promoção válida somente para novas contratações</IonLabel>
        </div> */}
        {/* <Link to="/ajuda"><IonLabel>Precisa de ajuda?</IonLabel></Link> */}
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />
      </IonContent>
    </IonPage>
  );
};

export default LoginConvite;
