import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { db } from '../firebase-config';

type MessageContextProps = {
  children: ReactNode;
};

type MessageContextType = {

  esconderMenu: any;
  setEsconderMenu: (newState: any) => void;

  mensagens: any;
  setMensagens: (newState: any) => void;
  notificacoes: any;
  setNotificacoes: (newState: any) => void;

  loja: any;
  setLoja: (newState: any) => void;
  pkUsuario: any;
  setPkUsuario: (newState: any) => void;

  filtroCampoSelecionadoVenda: any;
  setFiltroCampoSelecionadoVenda: (newState: any) => void;
  filtroMostrarDataVenda: any;
  setFiltroMostrarDataVenda: (newState: any) => void;
  filtroPesquisaVenda: any;
  setFiltroPesquisaVenda: (newState: any) => void;
  filtroStatusVenda: any;
  setFiltroStatusVenda: (newState: any) => void;
  recarregarVenda: any;
  setRecarregarVenda: (newState: any) => void;

  filtroCampoSelecionadoProjeto: any;
  setFiltroCampoSelecionadoProjeto: (newState: any) => void;
  filtroMostrarDataProjeto: any;
  setFiltroMostrarDataProjeto: (newState: any) => void;
  filtroPesquisaProjeto: any;
  setFiltroPesquisaProjeto: (newState: any) => void;
  filtroStatusProjeto: any;
  setFiltroStatusProjeto: (newState: any) => void;
  recarregarProjeto: any;
  setRecarregarProjeto: (newState: any) => void;

  filtroCampoSelecionadoOperacao: any;
  setFiltroCampoSelecionadoOperacao: (newState: any) => void;
  filtroMostrarDataOperacao: any;
  setFiltroMostrarDataOperacao: (newState: any) => void;
  filtroPesquisaOperacao: any;
  setFiltroPesquisaOperacao: (newState: any) => void;
  filtroStatusOperacao: any;
  setFiltroStatusOperacao: (newState: any) => void;
  recarregarOperacao: any;
  setRecarregarOperacao: (newState: any) => void;

  filtroCampoSelecionadoFinanceiro: any;
  setFiltroCampoSelecionadoFinanceiro: (newState: any) => void;
  filtroMostrarDataFinanceiro: any;
  setFiltroMostrarDataFinanceiro: (newState: any) => void;
  filtroPesquisaFinanceiro: any;
  setFiltroPesquisaFinanceiro: (newState: any) => void;
  filtroStatusFinanceiro: any;
  setFiltroStatusFinanceiro: (newState: any) => void;
  recarregarFinanceiro: any;
  setRecarregarFinanceiro: (newState: any) => void;

  recarregarAgendamento: any;
  setRecarregarAgendamento: (newState: any) => void;
  anoAgendamento: any;
  setAnoAgendamento: (newState: any) => void;
  mesAgendamento: any;
  setMesAgendamento: (newState: any) => void;

  nomeMenu: any;
  setNomeMenu: (newState: any) => void;

  recarregarCatalogo: any;
  setRecarregarCatalogo: (newState: any) => void;

  dadosEmpresa: any;
  setDadosEmpresa: (newState: any) => void;

  idPagamento: any;
  setIdPagamento: (newState: any) => void;

  isPaymentCompleted: any;
  setIsPaymentCompleted: (newState: any) => void;

  showPagamentoMaquininha: any;
  setShowPagamentoMaquininha: (newState: any) => void;

  idPagamentoMaquininha: any;
  setIdPagamentoMaquininha: (newState: any) => void;

  // qrCodePagamentoMaquininha: any;
  // setQrCodePagamentoMaquininha: (newState: any) => void;

  idPaymentSuccess: any;
  setIdPaymentSuccess: (newState: any) => void;

  idPaymentMaquininhaSuccess: any;
  setIdPaymentMaquininhaSuccess: (newState: any) => void;

  // paymentMaquininhaCancel: any;
  // setPaymentMaquininhaCancel: (newState: any) => void;
};

export const MessageContext = createContext<MessageContextType>({} as MessageContextType);

export const MessageContextProvider = ({ children }: MessageContextProps) => {

  const [esconderMenu, setEsconderMenu] = useState<any>(false);

  const [mensagens, setMensagens] = useState<any>([]);
  const [notificacoes, setNotificacoes] = useState<any>([]);
  const [loja, setLoja] = useState<any>();
  const [pkUsuario, setPkUsuario] = useState<any>();

  const [filtroCampoSelecionadoVenda, setFiltroCampoSelecionadoVenda] = useState<any>();
  const [filtroMostrarDataVenda, setFiltroMostrarDataVenda] = useState<any>();
  const [filtroPesquisaVenda, setFiltroPesquisaVenda] = useState<any>();
  const [filtroStatusVenda, setFiltroStatusVenda] = useState<any>();
  const [recarregarVenda, setRecarregarVenda] = useState<any>(false);

  const [filtroCampoSelecionadoProjeto, setFiltroCampoSelecionadoProjeto] = useState<any>();
  const [filtroMostrarDataProjeto, setFiltroMostrarDataProjeto] = useState<any>();
  const [filtroPesquisaProjeto, setFiltroPesquisaProjeto] = useState<any>();
  const [filtroStatusProjeto, setFiltroStatusProjeto] = useState<any>();
  const [recarregarProjeto, setRecarregarProjeto] = useState<any>(false);

  const [filtroCampoSelecionadoOperacao, setFiltroCampoSelecionadoOperacao] = useState<any>();
  const [filtroMostrarDataOperacao, setFiltroMostrarDataOperacao] = useState<any>();
  const [filtroPesquisaOperacao, setFiltroPesquisaOperacao] = useState<any>();
  const [filtroStatusOperacao, setFiltroStatusOperacao] = useState<any>();
  const [recarregarOperacao, setRecarregarOperacao] = useState<any>(false);

  const [filtroCampoSelecionadoFinanceiro, setFiltroCampoSelecionadoFinanceiro] = useState<any>();
  const [filtroMostrarDataFinanceiro, setFiltroMostrarDataFinanceiro] = useState<any>();
  const [filtroPesquisaFinanceiro, setFiltroPesquisaFinanceiro] = useState<any>();
  const [filtroStatusFinanceiro, setFiltroStatusFinanceiro] = useState<any>();
  const [recarregarFinanceiro, setRecarregarFinanceiro] = useState<any>(false);

  const [anoAgendamento, setAnoAgendamento] = useState<any>();
  const [mesAgendamento, setMesAgendamento] = useState<any>();
  const [recarregarAgendamento, setRecarregarAgendamento] = useState<any>(false);

  const [nomeMenu, setNomeMenu] = useState<any>();

  const [recarregarCatalogo, setRecarregarCatalogo] = useState<any>(false);
  const [dadosEmpresa, setDadosEmpresa] = useState<any>(false);
  const [idPagamento, setIdPagamento] = useState<any>(false);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState<any>(false);

  const [showPagamentoMaquininha, setShowPagamentoMaquininha] = useState<any>(false);
  const [idPagamentoMaquininha, setIdPagamentoMaquininha] = useState<any>();
  // const [qrCodePagamentoMaquininha, setQrCodePagamentoMaquininha] = useState<any>();

  const [idPaymentSuccess, setIdPaymentSuccess] = useState<any>();
  const [idPaymentMaquininhaSuccess, setIdPaymentMaquininhaSuccess] = useState<any>(false);
  // const [paymentMaquininhaCancel, setPaymentMaquininhaCancel] = useState<any>(false);

  //const [mensagens, setMensagens] = useState([{ mensagem: "Loading...", id: "initial" }]);
  useEffect(
    () => {
      console.log("loja_context", loja);

      if (loja) {

        let empresaid = loja;
        console.log("empresaid_context", empresaid);
        let consulta = query(collection(db, "mensagens"), where("empresaid", "==", empresaid));

        let unsubscribe = onSnapshot(consulta, (snapshot) => {
          console.log("recebeu mensagem whatsapp", snapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })));
          setMensagens(snapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        });

        return () => {
          unsubscribe();
        }

      }
    },
    [loja]
  );

  useEffect(
    () => {
      console.log("cliente_context", pkUsuario);

      if (pkUsuario) {

        let consultaNotificacao = query(collection(db, "notificacoes"), where("pk", "==", pkUsuario));

        let unsubscribeNotificacao = onSnapshot(consultaNotificacao, (snapshot) => {
          console.log("recebeu notificacao", snapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })));
          setNotificacoes(snapshot.docs.map((doc: any) => ({ ...doc.data(), id: doc.id })))
        });

        return () => {
          unsubscribeNotificacao();
        }
      }
    },
    [pkUsuario]
  );

  return (
    <MessageContext.Provider value={{
      esconderMenu,
      setEsconderMenu,

      mensagens,
      setMensagens,
      notificacoes,
      setNotificacoes,
      loja,
      setLoja,
      pkUsuario,
      setPkUsuario,

      filtroCampoSelecionadoVenda,
      setFiltroCampoSelecionadoVenda,
      filtroMostrarDataVenda,
      setFiltroMostrarDataVenda,
      filtroPesquisaVenda,
      setFiltroPesquisaVenda,
      filtroStatusVenda,
      setFiltroStatusVenda,
      recarregarVenda,
      setRecarregarVenda,

      filtroCampoSelecionadoProjeto,
      setFiltroCampoSelecionadoProjeto,
      filtroMostrarDataProjeto,
      setFiltroMostrarDataProjeto,
      filtroPesquisaProjeto,
      setFiltroPesquisaProjeto,
      filtroStatusProjeto,
      setFiltroStatusProjeto,
      recarregarProjeto,
      setRecarregarProjeto,

      filtroCampoSelecionadoOperacao,
      setFiltroCampoSelecionadoOperacao,
      filtroMostrarDataOperacao,
      setFiltroMostrarDataOperacao,
      filtroPesquisaOperacao,
      setFiltroPesquisaOperacao,
      filtroStatusOperacao,
      setFiltroStatusOperacao,
      recarregarOperacao,
      setRecarregarOperacao,

      filtroCampoSelecionadoFinanceiro,
      setFiltroCampoSelecionadoFinanceiro,
      filtroMostrarDataFinanceiro,
      setFiltroMostrarDataFinanceiro,
      filtroPesquisaFinanceiro,
      setFiltroPesquisaFinanceiro,
      filtroStatusFinanceiro,
      setFiltroStatusFinanceiro,
      recarregarFinanceiro,
      setRecarregarFinanceiro,

      anoAgendamento,
      setAnoAgendamento,
      mesAgendamento,
      setMesAgendamento,
      recarregarAgendamento,
      setRecarregarAgendamento,
      nomeMenu,
      setNomeMenu,

      recarregarCatalogo,
      setRecarregarCatalogo,


      dadosEmpresa,
      setDadosEmpresa,

      idPagamento,
      setIdPagamento,

      isPaymentCompleted,
      setIsPaymentCompleted,



      showPagamentoMaquininha,
      setShowPagamentoMaquininha,

      idPagamentoMaquininha,
      setIdPagamentoMaquininha,

      // qrCodePagamentoMaquininha,
      // setQrCodePagamentoMaquininha,

      idPaymentSuccess,
      setIdPaymentSuccess,

      idPaymentMaquininhaSuccess,
      setIdPaymentMaquininhaSuccess,

      // paymentMaquininhaCancel,
      // setPaymentMaquininhaCancel

    }}
    >
      {children}
    </MessageContext.Provider>
  );
};
